import {
  REGISTER_COMPANY_CONFIRMATION,
  REGISTER_COMPANY_FAILED,
  GET_COMPANY_CONFIRMATION,
  GET_COMPANY_FAILED,
  UPDATE_COMPANY_FAILED,
  UPDATE_COMPANY_CONFIRMATION,
  GET_COMPANY_INDUSTRIES_COMPLETED,
  GET_COMPANY_COUNTRIES_COMPLETED,
  ADD_COMPANY_MEMBER_FAILED,
  ADD_COMPANY_MEMBER_COMPLETED,
  GET_COMPANY_INDUSTRIES_FAILED,
  GET_COMPANY_COUNTRIES_FAILED,
  EDIT_COMPANY_MEMBER_STATUS_FAILED,
  EDIT_COMPANY_MEMBER_STATUS_COMPLETED,
  EDIT_COMPANY_MEMBER_ROLE_FAILED,
  EDIT_COMPANY_MEMBER_ROLE_COMPLETED,
  ADD_COMPANY_MEDIA_COMPLETED,
  ADD_COMPANY_MEDIA_FAILED,
  GET_COMPANY_MEDIA_COMPLETED,
  GET_COMPANY_MEDIA_FAILED,
  DELETE_COMPANY_MEDIA_COMPLETED,
  ADD_COMPANY_POSITION_FAILED,
  ADD_COMPANY_POSITION_COMPLETED,
  GET_COMPANY_POSITION_FAILED,
  GET_COMPANY_POSITION_COMPLETED,
  GET_COMPANY_POSITIONS_COMPLETED,
  GET_COMPANY_POSITIONS_FAILED,
  DELETE_COMPANY_POSITION_COMPLETED,
  DELETE_COMPANY_POSITION_FAILED,
  ADD_COMPANY_POSITION_QUESTION_COMPLETED,
  ADD_COMPANY_POSITION_QUESTION_FAILED,
  DELETE_COMPANY_MEDIA_FAILED,
  UPDATE_COMPANY_POSITION_FAILED,
  UPDATE_COMPANY_POSITION_COMPLETED,
  GET_COMPANY_SUBSCRIPTION_FAILED,
  GET_COMPANY_SUBSCRIPTION_COMPLETED,
  GET_COMPANY_CANDIDATES_COMPLETED,
  GET_COMPANY_CANDIDATES_FAILED,
  UPDATE_COMPANY_POSITION_QUESTION_FAILED,
  UPDATE_COMPANY_POSITION_QUESTION_COMPLETED,
  UPDATE_COMPANY_POSITION_QUESTION_SEQ_COMPLETED,
  UPDATE_COMPANY_POSITION_QUESTION_SEQ_FAILED,
  DELETE_COMPANY_POSITION_QUESTION_COMPLETED,
  DELETE_COMPANY_POSITION_QUESTION_FAILED,
  GET_COMPANY_POSITION_QUESTIONS_FAILED,
  GET_COMPANY_POSITION_QUESTIONS_COMPLETED,
  ADD_COMPANY_POSITION_TEAM_MEMBER_COMPLETED,
  ADD_COMPANY_POSITION_TEAM_MEMBER_FAILED,
  REMOVE_COMPANY_POSITION_TEAM_MEMBER_FAILED,
  REMOVE_COMPANY_POSITION_TEAM_MEMBER_COMPLETED,
  ADD_COMPANY_POSITION_REMINDER_COMPLETED,
  ADD_COMPANY_POSITION_REMINDER_FAILED,
  REMOVE_COMPANY_POSITION_REMINDER_COMPLETED,
  REMOVE_COMPANY_POSITION_REMINDER_FAILED,
  COMPANY_INVITE_CANDIDATE_COMPLETED,
  COMPANY_INVITE_CANDIDATE_FAILED,
  DELETE_COMPANY_INTERVIEW_FAILED,
  DELETE_COMPANY_INTERVIEW_COMPLETED,
  ADD_COMPANY_INTERVIEW_TAG_FAILED,
  ADD_COMPANY_INTERVIEW_TAG_COMPLETED,
  GET_COMPANY_TAGS_COMPLETED,
  GET_COMPANY_TAGS_FAILED,
  ADD_COMPANY_TAG_FAILED,
  ADD_COMPANY_TAG_COMPLETED,
  RESET_COMPANY_INTERVIEW_COMPLETED,
  RESET_COMPANY_INTERVIEW_FAILED,
  SENT_COMPANY_INTERVIEW_REMINDER_COMPLETED,
  SENT_COMPANY_INTERVIEW_REMINDER_FAILED,
  ADD_COMPANY_INTERVIEW_TEAM_MEMBER_COMPLETED,
  ADD_COMPANY_INTERVIEW_TEAM_MEMBER_FAILED,
  REMOVED_COMPANY_INTERVIEW_TEAM_MEMBER_COMPLETED,
  REMOVE_COMPANY_INTERVIEW_TEAM_MEMBER_COMPLETED,
  REMOVE_COMPANY_INTERVIEW_TEAM_MEMBER_FAILED,
  GET_COMPANY_CANDIDATES_EVALUATIONS_COMPLETED,
  GET_COMPANY_CANDIDATES_EVALUATIONS_FAILED,
  GET_COMPANY_CANDIDATE_FILTER_DATA_FAILED,
  GET_COMPANY_CANDIDATE_FILTER_DATA_COMPLETED,
  DELETE_COMPANY_TAG_COMPLETED,
  DELETE_COMPANY_TAG_FAILED,
  GET_COMPANY_CANDIDATE_EVALUATION_DETAIL_COMPLETED,
  GET_COMPANY_CANDIDATE_EVALUATION_DETAIL_FAILED,
  DELETE_COMPANY_INTERVIEW_TAG_FAILED,
  DELETE_COMPANY_INTERVIEW_TAG_COMPLETED,
  MARK_COMPANY_CANDIDATE_EVALUATION_COMPLETED,
  MARK_COMPANY_CANDIDATE_EVALUATION_FAILED,
  ADD_COMPANY_CANDIDATE_NOTE_FAILED,
  ADD_COMPANY_CANDIDATE_NOTE_COMPLETED,
  DELETE_COMPANY_CANDIDATE_NOTE_COMPLETED,
  DELETE_COMPANY_CANDIDATE_NOTE_FAILED,
  GET_COMPANY_DASHBOARD_DATA_COMPLETED,
  GET_COMPANY_DASHBOARD_DATA_FAILED,
  GET_COMPANY_DASHBOARD_DATA_REQUEST,
  UPDATE_COMPANY_POSITION_REQUEST,
  COMPANY_POSITION_DUPLICATION_COMPLETED,
  COMPANY_POSITION_DUPLICATION_FAILED,
  GET_COMPANY_PAYMENTS_DATA_COMPLETED,
  GET_COMPANY_PAYMENTS_DATA_FAILED,
  GET_COMPANY_SUBSCRIPTION_PLANS_DATA_COMPLETED,
  GET_COMPANY_SUBSCRIPTION_PLANS_DATA_FAILED,
  GET_COMPANY_DASHBOARD_POSITIONS_DATA_COMPLETED,
  GET_COMPANY_DASHBOARD_POSITIONS_DATA_FAILED,
  GET_COMPANY_DASHBOARD_POSITIONS_DATA_REQUEST,
  GET_COMPANY_DASHBOARD_EVALUATIONGRAPH_COMPLETED,
  GET_COMPANY_DASHBOARD_EVALUATIONGRAPH_FAILED,
  GET_COMPANY_DASHBOARD_EVALUATIONGRAPH_REQUEST,
  GET_COMPANY_DASHBOARD_INTERVIEWGRAPH_COMPLETED,
  GET_COMPANY_DASHBOARD_INTERVIEWGRAPH_FAILED,
  GET_COMPANY_DASHBOARD_INTERVIEWGRAPH_REQUEST,
  RESET_PORTAL_MESSAGE,
} from "../actions/constants/Company"

const initialState = {
  company: undefined,
  dashboard: undefined,
  countries: undefined,
  industries: undefined,
  media: undefined,
  position: undefined,
  candidates: undefined,
  candidatesFilterData: undefined,
  evaluations: undefined,
  payments: undefined,
  subscriptionPlans: undefined,
  currentCandidate: undefined,
  currentEvaluation: undefined,
  currentPosition: undefined,
  subscription: undefined,
  errorMessage: "",
  successMessage: "",
  editMessage: "",
  deleteMessage: "",
  showLoading: false,
  dashboardLoading: true,
  updateCompanyLoading: false,
  dashboardPositions: undefined,
  dashboardPositionsLoading: true,
  dashboardEvaluationGraph: undefined,
  dashboardEvaluationGraphLoading: true,
  dashboardInterviewGraph: undefined,
  dashboardInterviewGraphLoading: true,
}

export function CompanyReducer(state = initialState, action) {
  const { type, payload } = action
  switch (type) {
    case REGISTER_COMPANY_CONFIRMATION:
      return {
        ...state,
        company: payload,
        errorMessage: "",
        successMessage: "Company Registered Successfully",
        showLoading: false,
      }
    case GET_COMPANY_CONFIRMATION:
      return {
        ...state,
        company: payload,
        errorMessage: "",
        successMessage: "Get Company Completed",
        showLoading: false,
      }
    case UPDATE_COMPANY_POSITION_REQUEST:
      return {
        ...state,
        updateCompanyLoading: true,
      }
    case UPDATE_COMPANY_CONFIRMATION:
      const c = Object.assign({}, state.company, payload)
      return {
        ...state,
        company: c,
        errorMessage: "",
        updateMessage: "Updated Company Data Successfully",
        showLoading: false,
        updateCompanyLoading: false,
      }
    case UPDATE_COMPANY_FAILED:
      return {
        ...state,
        errorMessage: payload,
        updateMessage: "",
        showLoading: false,
        updateCompanyLoading: false,
      }
    case GET_COMPANY_FAILED:
      return {
        ...state,
        errorMessage: payload,
        successMessage: "",
        showLoading: false,
      }
    case REGISTER_COMPANY_FAILED:
      return {
        ...state,
        company: undefined,
        errorMessage: payload,
        successMessage: "",
        showLoading: false,
      }
    case GET_COMPANY_COUNTRIES_COMPLETED:
      return {
        ...state,
        countries: payload,
        errorMessage: "",
        addedMessage: "",
        successMessage: "Get Company Countries Completed",
        showLoading: false,
      }
    case GET_COMPANY_INDUSTRIES_COMPLETED:
      return {
        ...state,
        industries: payload,
        errorMessage: "",
        successMessage: "Get Company Industries Completed",
        showLoading: false,
      }
    case GET_COMPANY_PAYMENTS_DATA_COMPLETED:
      return {
        ...state,
        payments: payload,
        errorMessage: "",
        updatedMessage: "",
        editMessage: "",
        successMessage: "Get Company Payments Completed",
        showLoading: false,
      }
    case GET_COMPANY_PAYMENTS_DATA_FAILED:
      return {
        ...state,
        errorMessage: payload,
        successMessage: "",
        updatedMessage: "",
        editMessage: "",
        showLoading: false,
      }
    case GET_COMPANY_SUBSCRIPTION_PLANS_DATA_COMPLETED:
      return {
        ...state,
        subscriptionPlans: payload,
        errorMessage: "",
        updatedMessage: "",
        editMessage: "",
        successMessage: "Get Company Subscription Plans Completed",
        showLoading: false,
      }
    case GET_COMPANY_SUBSCRIPTION_PLANS_DATA_FAILED:
      return {
        ...state,
        errorMessage: payload,
        successMessage: "",
        updatedMessage: "",
        editMessage: "",
        showLoading: false,
      }
    case GET_COMPANY_COUNTRIES_FAILED:
      return {
        ...state,
        errorMessage: payload,
        successMessage: "",
        showLoading: false,
      }
    case GET_COMPANY_INDUSTRIES_FAILED:
      return {
        ...state,
        errorMessage: payload,
        successMessage: "",
        showLoading: false,
      }
    case ADD_COMPANY_MEMBER_COMPLETED:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
        editMessage: "",
        addedMessage: "Company Member Added Successfully",
        showLoading: false,
      }
    case ADD_COMPANY_MEMBER_FAILED:
      return {
        ...state,
        errorMessage: payload,
        successMessage: "",
        addedMessage: "",
        editMessage: "",
        showLoading: false,
      }
    case COMPANY_POSITION_DUPLICATION_COMPLETED:
      return {
        ...state,
        errorMessage: "",
        successMessage: "",
        editMessage: "",
        duplicatedMessage: "Company Position Duplicated Successfully",
        showLoading: false,
      }
    case COMPANY_POSITION_DUPLICATION_FAILED:
      return {
        ...state,
        errorMessage: payload,
        successMessage: "",
        addedMessage: "",
        editMessage: "",
        showLoading: false,
      }
    case EDIT_COMPANY_MEMBER_STATUS_COMPLETED:
      return {
        ...state,
        errorMessage: "",
        editMessage: "Company Member Status Changed Successfully",
        successMessage: "",
        showLoading: false,
      }
    case EDIT_COMPANY_MEMBER_STATUS_FAILED:
      return {
        ...state,
        errorMessage: payload,
        editMessage: "",
        successMessage: "",
        showLoading: false,
      }
    case EDIT_COMPANY_MEMBER_ROLE_FAILED:
      return {
        ...state,
        errorMessage: payload,
        editMessage: "",
        showLoading: false,
      }
    case EDIT_COMPANY_MEMBER_ROLE_COMPLETED:
      return {
        ...state,
        errorMessage: "",
        editMessage: "Company Member Role Updated Successfully",
        showLoading: false,
      }
    case ADD_COMPANY_MEDIA_COMPLETED:
      return {
        ...state,
        errorMessage: "",
        addedMessage: "Company Media Added Successfully",
        showLoading: false,
      }
    case ADD_COMPANY_MEDIA_FAILED:
      return {
        ...state,
        errorMessage: payload,
        addedMessage: "",
        showLoading: false,
      }
    case GET_COMPANY_MEDIA_FAILED:
      return {
        ...state,
        errorMessage: payload,
        editMessage: "",
        successMessage: "",
        showLoading: false,
      }
    case GET_COMPANY_MEDIA_COMPLETED:
      return {
        ...state,
        media: payload,
        errorMessage: "",
        editMessage: "",
        successMessage: "Get All Company Media Successful",
        showLoading: false,
      }
    case DELETE_COMPANY_MEDIA_COMPLETED:
      return {
        ...state,
        errorMessage: "",
        editMessage: "",
        deletedMessage: "Media Deleted Successfully",
        showLoading: false,
      }

    case DELETE_COMPANY_MEDIA_FAILED:
      return {
        ...state,
        errorMessage: payload,
        editMessage: "",
        deletedMessage: "",
        showLoading: false,
      }

    case ADD_COMPANY_POSITION_COMPLETED:
      const p = state.position ? state.position : { data: [] }
      p.data.push(payload)

      return {
        ...state,
        position: p,
        currentPosition: payload,
        errorMessage: "",
        editMessage: "",
        successMessage: "Position Added Successfully",
        showLoading: false,
      }

    case GET_COMPANY_POSITION_COMPLETED:
      return {
        ...state,
        currentPosition: payload,
        errorMessage: "",
        editMessage: "",
        successMessage: "Get Company Position Successfully",
        showLoading: false,
      }

    case GET_COMPANY_POSITION_FAILED:
      return {
        ...state,
        errorMessage: payload,
        editMessage: "",
        successMessage: "",
        showLoading: false,
      }

    case ADD_COMPANY_POSITION_REMINDER_COMPLETED:
      return {
        ...state,
        errorMessage: "",
        editMessage: "",
        successMessage: "",
        updatedMessage: "Position Reminder Added",
        showLoading: true,
      }

    case ADD_COMPANY_POSITION_REMINDER_FAILED:
      return {
        ...state,
        errorMessage: payload,
        updatedMessage: "",
        editMessage: "",
        successMessage: "",
        showLoading: true,
      }

    case REMOVE_COMPANY_POSITION_REMINDER_COMPLETED:
      return {
        ...state,
        errorMessage: "",
        editMessage: "",
        successMessage: "",
        updatedMessage: "Position Reminder Removed",
        showLoading: true,
      }

    case REMOVE_COMPANY_POSITION_REMINDER_FAILED:
      return {
        ...state,
        errorMessage: payload,
        updatedMessage: "",
        editMessage: "",
        successMessage: "",
        showLoading: true,
      }

    case COMPANY_INVITE_CANDIDATE_COMPLETED:
      return {
        ...state,
        errorMessage: "",
        editMessage: "",
        updatedMessage: "",
        addedMessage: "Interview invitation sent successfully",
        successMessage: "Position Candidate Invitation Sent",
        showLoading: true,
      }

    case COMPANY_INVITE_CANDIDATE_FAILED:
      return {
        ...state,
        errorMessage: payload,
        updatedMessage: "",
        editMessage: "",
        successMessage: "",
        showLoading: true,
      }

    case DELETE_COMPANY_INTERVIEW_COMPLETED:
      return {
        ...state,
        errorMessage: "",
        editMessage: "",
        updatedMessage: "",
        successMessage: "",
        deletedMessage: "Delete Candidate Successful",
        showLoading: true,
      }

    case DELETE_COMPANY_INTERVIEW_FAILED:
      return {
        ...state,
        errorMessage: payload,
        updatedMessage: "",
        editMessage: "",
        successMessage: "",
        showLoading: false,
      }

    case RESET_COMPANY_INTERVIEW_COMPLETED:
      return {
        ...state,
        errorMessage: "",
        editMessage: "",
        updatedMessage: "Reset Candidate Successful",
        successMessage: "",
        showLoading: true,
      }

    case RESET_COMPANY_INTERVIEW_FAILED:
      return {
        ...state,
        errorMessage: payload,
        updatedMessage: "",
        editMessage: "",
        successMessage: "",
        showLoading: false,
      }

    case SENT_COMPANY_INTERVIEW_REMINDER_COMPLETED:
      return {
        ...state,
        errorMessage: "",
        editMessage: "",
        updatedMessage: "Send Candidate Reminder Successful",
        successMessage: "",
        showLoading: true,
      }

    case SENT_COMPANY_INTERVIEW_REMINDER_FAILED:
      return {
        ...state,
        errorMessage: payload,
        updatedMessage: "",
        editMessage: "",
        successMessage: "",
        showLoading: false,
      }

    case ADD_COMPANY_INTERVIEW_TEAM_MEMBER_COMPLETED:
      return {
        ...state,
        errorMessage: "",
        addedMessage: "Add Company Candidate Team Member Successful",
        editMessage: "",
        updatedMessage: "",
        deletedMessage: "",
        successMessage: "",
        showLoading: true,
      }

    case ADD_COMPANY_INTERVIEW_TEAM_MEMBER_FAILED:
      return {
        ...state,
        errorMessage: payload,
        updatedMessage: "",
        addedMessage: "",
        editMessage: "",
        deletedMessage: "",
        successMessage: "",
        showLoading: false,
      }

    case DELETE_COMPANY_INTERVIEW_TAG_COMPLETED:
      return {
        ...state,
        errorMessage: "",
        deletedMessage: "Remove Company Candidate Tag Successful",
        editMessage: "",
        updatedMessage: "",
        successMessage: "",
        showLoading: true,
      }

    case DELETE_COMPANY_INTERVIEW_TAG_FAILED:
      return {
        ...state,
        errorMessage: payload,
        updatedMessage: "",
        deletedMessage: "",
        editMessage: "",
        successMessage: "",
        showLoading: false,
      }

    case REMOVED_COMPANY_INTERVIEW_TEAM_MEMBER_COMPLETED:
      return {
        ...state,
        errorMessage: "",
        editMessage: "",
        updatedMessage: "",
        addedMessage: "Remove Company Candidate Team Member Successful",
        showLoading: false,
      }

    case REMOVE_COMPANY_INTERVIEW_TEAM_MEMBER_COMPLETED:
      return {
        ...state,
        errorMessage: "",
        editMessage: "",
        updatedMessage: "",
        successMessage: "Remove Company Candidate Team Member Successful",
        showLoading: false,
      }

    case REMOVE_COMPANY_INTERVIEW_TEAM_MEMBER_FAILED:
      return {
        ...state,
        errorMessage: payload,
        updatedMessage: "",
        editMessage: "",
        successMessage: "",
        showLoading: false,
      }

    case GET_COMPANY_POSITIONS_COMPLETED:
      return {
        ...state,
        position: payload,
        errorMessage: "",
        editMessage: "",
        updatedMessage: "",
        addedMessage: "",
        // deletedMessage: "",
        successMessage: "Get Company Positions Successfully",
        showLoading: false,
      }

    case GET_COMPANY_POSITIONS_FAILED:
      return {
        ...state,
        errorMessage: payload,
        editMessage: "",
        successMessage: "",
        updatedMessage: "",
        addedMessage: "",
        deletedMessage: "",
        showLoading: false,
      }

    case ADD_COMPANY_POSITION_FAILED:
      return {
        ...state,
        errorMessage: payload,
        successMessage: "",
        editMessage: "",
        deletedMessage: "",
        showLoading: false,
      }
    case DELETE_COMPANY_POSITION_FAILED:
      return {
        ...state,
        errorMessage: payload,
        editMessage: "",
        successMessage: "",
        deletedMessage: "",
        showLoading: false,
      }
    case DELETE_COMPANY_POSITION_COMPLETED:
      return {
        ...state,
        errorMessage: "",
        editMessage: "",
        successMessage: "",
        deletedMessage: "Company Position Deleted Successfully",
        showLoading: false,
      }

    case UPDATE_COMPANY_POSITION_COMPLETED:
      return {
        ...state,
        currentPosition: payload,
        errorMessage: "",
        editMessage: "",
        successMessage: "",
        updatedMessage: "Company Position Updated Successfully",
        addedMessage: "",
        deletedMessage: "",
        showLoading: true,
      }

    case UPDATE_COMPANY_POSITION_FAILED:
      return {
        ...state,
        errorMessage: payload,
        editMessage: "",
        successMessage: "",
        updatedMessage: "",
        addedMessage: "",
        deletedMessage: "",
        showLoading: true,
      }

    /** Company Position Questions*/
    case ADD_COMPANY_POSITION_QUESTION_COMPLETED:
      return {
        ...state,
        errorMessage: "",
        editMessage: "",
        successMessage: "",
        addedMessage: "Position Question Added successfully",
        deletedMessage: "",
        showLoading: false,
      }
    case ADD_COMPANY_POSITION_QUESTION_FAILED:
      return {
        ...state,
        errorMessage: payload,
        editMessage: "",
        addedMessage: "",
        successMessage: "",
        deletedMessage: "",
        showLoading: false,
      }

    case GET_COMPANY_POSITION_QUESTIONS_COMPLETED:
      return {
        ...state,
        currentPosition: { ...state.currentPosition, Questions: payload },
        // errorMessage: "",
        // editMessage: "",
        // addedMessage: "",
        successMessage: "Get Position Questions Completed",
        // deletedMessage: "",
        showLoading: false,
      }

    case GET_COMPANY_POSITION_QUESTIONS_FAILED:
      return {
        ...state,
        errorMessage: payload,
        editMessage: "",
        successMessage: "",
        addedMessage: "",
        deletedMessage: "",
        showLoading: false,
      }

    case UPDATE_COMPANY_POSITION_QUESTION_COMPLETED:
      return {
        ...state,
        errorMessage: "",
        editMessage: "",
        successMessage: "",
        addedMessage: "Position Question Updated successfully",
        deletedMessage: "",
        showLoading: false,
      }
    case UPDATE_COMPANY_POSITION_QUESTION_FAILED:
      return {
        ...state,
        errorMessage: payload,
        editMessage: "",
        successMessage: "",
        addedMessage: "",
        deletedMessage: "",
        showLoading: false,
      }
    case UPDATE_COMPANY_POSITION_QUESTION_SEQ_COMPLETED:
      return {
        ...state,
        errorMessage: "",
        editMessage: "",
        successMessage: "",
        addedMessage: "Position Question Seq Updated Successfully",
        deletedMessage: "",
        showLoading: false,
      }
    case UPDATE_COMPANY_POSITION_QUESTION_SEQ_FAILED:
      return {
        ...state,
        errorMessage: payload,
        editMessage: "",
        successMessage: "",
        addedMessage: "",
        deletedMessage: "",
        showLoading: false,
      }
    case DELETE_COMPANY_POSITION_QUESTION_COMPLETED:
      return {
        ...state,
        errorMessage: "",
        editMessage: "",
        successMessage: "",
        addedMessage: "",
        deletedMessage: "Position Question Deleted Successfully",
        showLoading: false,
      }
    case DELETE_COMPANY_POSITION_QUESTION_FAILED:
      return {
        ...state,
        errorMessage: payload,
        editMessage: "",
        successMessage: "",
        addedMessage: "",
        deletedMessage: "",
        showLoading: false,
      }
    /** Subscription */
    case GET_COMPANY_SUBSCRIPTION_COMPLETED:
      return {
        ...state,
        subscription: payload,
        errorMessage: "",
        successMessage: "Get Company Subscription Successfully",
        editMessage: "",
        showLoading: false,
      }
    case GET_COMPANY_SUBSCRIPTION_FAILED:
      return {
        ...state,
        errorMessage: payload,
        successMessage: "",
        editMessage: "",
        showLoading: false,
      }

    /** Candidates */

    case GET_COMPANY_CANDIDATES_COMPLETED:
      return {
        ...state,
        candidates: payload,
        successMessage: "Get Company Candidates Completed",
        errorMessage: "",
        editMessage: "",
        showLoading: false,
      }
    case GET_COMPANY_CANDIDATES_FAILED:
      return {
        ...state,
        successMessage: "",
        errorMessage: payload,
        editMessage: "",
        showLoading: false,
      }

    case GET_COMPANY_CANDIDATES_EVALUATIONS_COMPLETED:
      return {
        ...state,
        evaluations: payload,
        successMessage: "Get Company Candidates Evaluations Completed",
        errorMessage: "",
        editMessage: "",
        showLoading: false,
      }
    case GET_COMPANY_CANDIDATES_EVALUATIONS_FAILED:
      return {
        ...state,
        successMessage: "",
        errorMessage: payload,
        editMessage: "",
        showLoading: false,
      }

    case MARK_COMPANY_CANDIDATE_EVALUATION_COMPLETED:
      return {
        ...state,
        evaluations: payload,
        successMessage: "",
        updatedMessage: "Mark Company Candidate Evaluation Completed",
        errorMessage: "",
        deletedMessage: "",
        showLoading: true,
      }
    case MARK_COMPANY_CANDIDATE_EVALUATION_FAILED:
      return {
        ...state,
        successMessage: "",
        errorMessage: payload,
        updatedMessage: "",
        showLoading: false,
      }

    case ADD_COMPANY_CANDIDATE_NOTE_COMPLETED:
      return {
        ...state,
        evaluations: payload,
        successMessage: "",
        updatedMessage: "Add Company Candidate Note Completed",
        errorMessage: "",
        deletedMessage: "",
        showLoading: true,
      }
    case ADD_COMPANY_CANDIDATE_NOTE_FAILED:
      return {
        ...state,
        successMessage: "",
        errorMessage: payload,
        editMessage: "",
        updatedMessage: "",
        showLoading: false,
      }
    case GET_COMPANY_DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        dashboardLoading: true,
      }
    case GET_COMPANY_DASHBOARD_DATA_COMPLETED:
      return {
        ...state,
        dashboard: payload,
        successMessage: "",
        updatedMessage: "",
        errorMessage: "",
        deletedMessage: "",
        showLoading: true,
        dashboardLoading: false,
      }
    case GET_COMPANY_DASHBOARD_DATA_FAILED:
      return {
        ...state,
        successMessage: "",
        errorMessage: payload,
        editMessage: "",
        updatedMessage: "",
        showLoading: false,
        dashboardLoading: false,
      }
    case GET_COMPANY_DASHBOARD_POSITIONS_DATA_REQUEST:
      return {
        ...state,
        dashboardPositionsLoading: true,
      }
    case GET_COMPANY_DASHBOARD_POSITIONS_DATA_COMPLETED:
      return {
        ...state,
        dashboardPositions: payload,
        dashboardPositionsLoading: false,
      }
    case GET_COMPANY_DASHBOARD_POSITIONS_DATA_FAILED:
      return {
        ...state,
        dashboardPositions: payload,
        dashboardPositionsLoading: false,
      }
    case GET_COMPANY_DASHBOARD_INTERVIEWGRAPH_REQUEST:
      return {
        ...state,
        dashboardInterviewGraphLoading: true,
      }
    case GET_COMPANY_DASHBOARD_INTERVIEWGRAPH_COMPLETED:
      return {
        ...state,
        dashboardInterviewGraph: payload,
        dashboardInterviewGraphLoading: false,
      }
    case GET_COMPANY_DASHBOARD_INTERVIEWGRAPH_FAILED:
      return {
        ...state,
        dashboardInterviewGraph: payload,
        dashboardInterviewGraphLoading: false,
      }
    case GET_COMPANY_DASHBOARD_EVALUATIONGRAPH_REQUEST:
      return {
        ...state,
        dashboardEvaluationGraphLoading: true,
      }
    case GET_COMPANY_DASHBOARD_EVALUATIONGRAPH_COMPLETED:
      return {
        ...state,
        dashboardEvaluationGraph: payload,
        dashboardEvaluationGraphLoading: false,
      }
    case GET_COMPANY_DASHBOARD_EVALUATIONGRAPH_FAILED:
      return {
        ...state,
        dashboardEvaluationGraph: payload,
        dashboardEvaluationGraphLoading: false,
      }

    case DELETE_COMPANY_CANDIDATE_NOTE_COMPLETED:
      return {
        ...state,
        evaluations: payload,
        deletedMessage: "Delete Company Candidate Note Completed",
        successMessage: "",
        errorMessage: "",
        editMessage: "",
        updatedMessage: "",
        showLoading: true,
      }
    case DELETE_COMPANY_CANDIDATE_NOTE_FAILED:
      return {
        ...state,
        successMessage: "",
        errorMessage: payload,
        editMessage: "",
        updatedMessage: "",
        showLoading: false,
      }

    case GET_COMPANY_CANDIDATE_EVALUATION_DETAIL_COMPLETED:
      return {
        ...state,
        currentEvaluation: payload,
        successMessage: "Get Company Candidate Evaluation Detail Completed",
        errorMessage: "",
        showLoading: false,
      }
    case GET_COMPANY_CANDIDATE_EVALUATION_DETAIL_FAILED:
      return {
        ...state,
        successMessage: "",
        errorMessage: payload,
        editMessage: "",
        deletedMessage: "",
        showLoading: false,
      }

    case ADD_COMPANY_INTERVIEW_TAG_COMPLETED:
      return {
        ...state,
        // candidates: payload,
        successMessage: "Add Company Interview Tag Completed",
        errorMessage: "",
        editMessage: "",
        deletedMessage: "",
        showLoading: true,
      }

    case ADD_COMPANY_INTERVIEW_TAG_FAILED:
      return {
        ...state,
        successMessage: "",
        errorMessage: payload,
        editMessage: "",
        deletedMessage: "",
        showLoading: false,
      }
    case GET_COMPANY_CANDIDATE_FILTER_DATA_COMPLETED:
      return {
        ...state,
        candidatesFilterData: payload,
        // candidates: payload,
        successMessage: "Get Company Candidate Filter Data Completed",
        errorMessage: "",
        editMessage: "",
        showLoading: false,
      }

    case GET_COMPANY_CANDIDATE_FILTER_DATA_FAILED:
      return {
        ...state,
        successMessage: "",
        errorMessage: payload,
        editMessage: "",
        deletedMessage: "",
        showLoading: false,
      }

    case ADD_COMPANY_TAG_COMPLETED:
      // const company_tags = state.company.tags ? state.company.tags : (state.company.tags = [])
      // p.data.push(payload)

      return {
        ...state,
        // candidates: payload,
        addedMessage: "Add Company Tag Completed",
        successMessage: "",
        errorMessage: "",
        editMessage: "",
        deletedMessage: "",
        showLoading: true,
      }
    case ADD_COMPANY_TAG_FAILED:
      return {
        ...state,
        successMessage: "",
        addedMessage: "",
        errorMessage: payload,
        editMessage: "",
        deletedMessage: "",
        showLoading: false,
      }

    case DELETE_COMPANY_TAG_COMPLETED:
      // const company_tags = state.company.tags ? state.company.tags : (state.company.tags = [])
      // p.data.push(payload)

      return {
        ...state,
        // candidates: payload,
        addedMessage: "",
        successMessage: "",
        errorMessage: "",
        deletedMessage: "Delete Company Tag Completed",
        editMessage: "",
        showLoading: true,
      }
    case DELETE_COMPANY_TAG_FAILED:
      return {
        ...state,
        successMessage: "",
        errorMessage: payload,
        editMessage: "",
        addedMessage: "",
        deletedMessage: "",
        showLoading: false,
      }

    case GET_COMPANY_TAGS_COMPLETED:
      return {
        ...state,
        candidates: { ...state.candidates, tags: payload },
        successMessage: "Get Company Interview Tag Completed",
        errorMessage: "",
        editMessage: "",
        deletedMessage: "",
        showLoading: false,
      }
    case GET_COMPANY_TAGS_FAILED:
      return {
        ...state,
        successMessage: "",
        errorMessage: payload,
        editMessage: "",
        deletedMessage: "",
        showLoading: false,
      }

    /** Company Position Team Members */

    case ADD_COMPANY_POSITION_TEAM_MEMBER_COMPLETED:
      return {
        ...state,
        updatedMessage: "Team member add to position",
        successMessage: "",
        errorMessage: "",
        editMessage: "",
        deletedMessage: "",
        showLoading: true,
      }
    case ADD_COMPANY_POSITION_TEAM_MEMBER_FAILED:
      return {
        ...state,
        successMessage: "",
        updatedMessage: "",
        errorMessage: payload,
        editMessage: "",
        deletedMessage: "",
        showLoading: true,
      }

    case REMOVE_COMPANY_POSITION_TEAM_MEMBER_COMPLETED:
      return {
        ...state,
        updatedMessage: "Team member removed from position",
        successMessage: "",
        errorMessage: "",
        editMessage: "",
        deletedMessage: "",
        showLoading: true,
      }
    case REMOVE_COMPANY_POSITION_TEAM_MEMBER_FAILED:
      return {
        ...state,
        errorMessage: payload,
        successMessage: "",
        updatedMessage: "",
        editMessage: "",
        deletedMessage: "",
        showLoading: true,
      }
    case RESET_PORTAL_MESSAGE:
      return {
        ...state,
        addedMessage:"",
        successMessage: "",
        updatedMessage: "",
        editMessage: "",
        deletedMessage: "",
        duplicatedMessage: "",
      }
    default:
      return state
  }
}
