import React from "react"

import { Row, Col, Card, CardBody, Spinner } from "reactstrap"
import { Link } from "react-router-dom"
import moment from 'moment'
import { renderDevice } from "../Common/RenderText"

const EvaluationStats = ({ evaluation, loading }) => {
  const handleTimeFormat = (time) => {
    var sec_num = parseInt(time, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
    seconds = seconds + ' Sec'
    if(minutes) {
      minutes = minutes + ' Min'
      return minutes + ' ' + seconds;
    } else {
      return seconds
    }
  }

  return (
    <Card className="overflow-hidden evaluation-stats"  style={{height:'auto'}}>
      <CardBody className="">
        <Row className="align-items-start">
          <Col md={6}>
            <Row className="align-items-start align-items-start">
              <Col md={4}>
                <div className="text-center">
                  <span className="d-block"><i className="bx bx-user-plus bx-sm mb-2"></i></span>
                  <h6>Invited</h6>
                  <span className="d-block text-subtitle">{moment().format('MMM DD, YYYY')}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className="text-center">
                  <span className="d-block"><i className="bx bx-comment-minus bx-sm mb-2"></i></span>
                  <h6>Completed </h6>
                  <span className="d-block text-subtitle">{evaluation?.completion_date? moment(evaluation?.completion_date).format('MMM DD, YYYY hh:mm'): 'N/A'}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className="text-center">
                  <span className="d-block"><i className="bx bx-calendar-alt bx-sm mb-2"></i></span>
                  <h6>Last updated</h6>
                  <span className="d-block text-subtitle">{moment(evaluation?.updatedAt).format('MMM DD, YYYY')}</span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row className="align-items-start">
              <Col md={4}>
                <div className="text-center">
                  <span className="d-block"><i className="bx bx-comment bx-sm mb-2"></i></span>
                  <h6>Questions</h6>
                  <span className="d-block text-subtitle">{evaluation?.progress}/{evaluation?.Position?.Question.length}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className="text-center">
                  <span className="d-block"><i className="bx bxs-time bx-sm mb-2"></i></span>
                  <h6>Total Time</h6>
                  <span className="d-block text-subtitle">{handleTimeFormat(evaluation?.avg_response_time)}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className="text-center">
                  <span className="d-block"><i className="bx bx-timer bx-sm mb-2"></i></span>
                  <h6>Average Time</h6>
                  <span className="d-block text-subtitle">{handleTimeFormat(evaluation?.avg_response_time)}</span>
                </div>
              </Col>
              {/* <Col md={3}>
                <div className="text-center">
                  <span className="d-block"><i className="bx bxs-devices bx-sm mb-2"></i></span>
                  <h6>Device</h6>
                  <span className="d-block text-subtitle">{renderDevice(evaluation?.device?.deviceType)}</span>
                </div>
              </Col> */}
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default EvaluationStats