import React, { Fragment, useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Modal } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";

import SendInviteModal from "./SendInvite";
import avatar from "../../../images/default.svg";
import PageTitle from "../../layouts/PageTitle";
import Alert from "./../Common/Alert";
import {
  editCompanyMemberRoleAction,
  editCompanyMemberStatusAction,
  getCompanyAction,
  getCompanySubscriptionAction,
} from "../../../store/actions/CompanyActions";
import { connect } from "react-redux";
import {
  getCompany,
  getCompanyMembers,
  getEditRoleMessage,
  getCompanySubscription,
} from "../../../store/selectors/CompanySelector";
import ProfileModal from "./ProfileModal";
import EditRole from "./EditRole";
import PhoneNumber from "../Common/phoneNumber";
import DeletePrompt from "../Common/DeletePrompt";
import Switch from "../Common/Switch";
import moment from "moment";
import "./style.css";

const Members = (props) => {
  const [inviteModal, setInviteModal] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const [editRoleModal, setEditRoleModal] = useState(false);
  const [member, setMember] = useState({});
  const [deactivateModal, setDeactivateModal] = useState({
    show: false,
    func: undefined,
  });
  const [deleteModal, setDeleteModal] = useState({
    show: false,
    func: undefined,
  });
  const [loader, showloader] = useState(false);
  const [teamSearch, setTeamSearch] = useState("");
  const [alert, showAlert] = useState(false);
  const firstRender = useRef(true);

  const { company, companyMembers, responseMessage, getCompanySubscription } =
    props;

  if (companyMembers?.length > 0) {
    const i = companyMembers
      ?.map((x) =>
        x?.AddedMemberUser?.Roles?.some((b) => b.name === "employer")
      )
      .indexOf(true);
    if (i !== -1) {
      const temp = companyMembers[i];
      companyMembers[i] = companyMembers[0];
      companyMembers[0] = temp;
    }
  }
  // console.log(responseMessage);
  useEffect(() => {
    if (firstRender) {
      showloader(true);
      // companyMembers?.indexOf(x => ));
      props.loadCompany(() => showloader(false));
      props.getCompanySubscriptionAction();
      firstRender.current = false;
    }
    return () => (firstRender.current = false);
  }, []);

  useEffect(() => {
    const loader = () => {
      if (responseMessage?.success && alert) {
        props.loadCompany();
      }
    };
    return loader();
  }, [responseMessage?.success, alert]);

  const RenderRole = ({ roles }) => {
    return roles.map((e, i) =>
      e.name === "owner" ? (
        <span className={`badge badge-success ${i > 0 && "ml-1"}`} key={i}>
          Admin
        </span>
      ) : e.name === "manager" ? (
        <span className={`badge badge-secondary ${i > 0 && "ml-1"}`} key={i}>
          Manager
        </span>
      ) : (
        e.name === "recruiter" && (
          <span className={`badge badge-warning ${i > 0 && "ml-1"}`} key={i}>
            Recruiter
          </span>
        )
      )
    );
  };

  return (
    <Fragment>
      {/*<PageTitle activeMenu="Team Members" motherMenu="Setting" />*/}

      {responseMessage?.success && (
        <Alert
          alert={alert}
          type={responseMessage?.success && "success"}
          variant={responseMessage?.success && "success"}
          msg={responseMessage?.success}
          dismissAlert={() => showAlert(false)}
        />
      )}
      {responseMessage?.error && (
        <Alert
          alert={alert}
          type={responseMessage?.error && "error"}
          variant={responseMessage?.error && "danger"}
          msg={responseMessage?.error}
          dismissAlert={() => showAlert(false)}
        />
      )}
      <Row>
        <Col md={4}>
          <h5 className="card-title">Team Members</h5>
          <input
            type="text"
            value={teamSearch}
            onChange={(e) => setTeamSearch(e.target.value)}
            className="form-control"
            placeholder="Search Member"
          />
        </Col>
      </Row>
      <hr></hr>
      <div className="row team-members">
        {loader ? (
          <Col style={{ textAlignLast: "center" }}>
            <Spinner size="sm" className="m-auto text-center" color="success" />
          </Col>
        ) : (
          companyMembers.length > 0 &&
          companyMembers.map(
            (e, i) =>
              e?.AddedMemberUser?.first_name
                ?.toLowerCase()
                ?.includes(teamSearch.toLowerCase()) && (
                <Col lg={4} key={i}>
                  <div className="card text-dark">
                    <div className={"card-body"}>
                      <Row className="justify-content-end">
                        <Col lg={3} className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              href="#"
                              className="card-drop"
                              tag="i"
                            >
                              <i className="mdi mdi-dots-horizontal font-size-18" />
                            </DropdownToggle>
                            <DropdownMenu right>
                              <DropdownItem
                                onClick={() => {
                                  setMember(e);
                                  setProfileModal(true);
                                }}
                              >
                                View Profile
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  setMember(e);
                                  setEditRoleModal(true);
                                }}
                              >
                                Edit Role
                              </DropdownItem>
                              {/* <DropdownItem className="red" onClick={() => changeMemberStatus()}>Delete Member </DropdownItem> */}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col sm={4}>
                          <div
                            className="avatar"
                            style={{ maxWidth: "fit-content" }}
                          >
                            <img
                              src={e?.AddedMemberUser?.image || avatar}
                              alt=""
                              width={"120"}
                              className="img-thumbnail rounded-circle"
                            />
                          </div>
                        </Col>
                        <Col sm={8}>
                          <h5 className="text-capitalize">
                            {e?.AddedMemberUser?.first_name}{" "}
                            {e?.AddedMemberUser?.last_name}{" "}
                          </h5>
                          <RenderRole roles={e?.AddedMemberUser?.Roles} />
                          <span className="d-block mt-1">
                            {e?.AddedMemberUser?.email}
                          </span>
                          <span className="d-block mt-1">
                            {" "}
                            <PhoneNumber number={e?.AddedMemberUser?.phone} />
                          </span>
                        </Col>
                        <Col sm="12 mt-3">
                          <div>
                            <div className="d-lg-flex justify-content-between">
                              Status{" "}
                              <div className="is-active-switch">
                                <Switch
                                  isOn={e?.status}
                                  forId={`team-member-status-${e?.id}`}
                                  handleToggle={() => {
                                    setMember(e);
                                    setDeactivateModal({
                                      show: true,
                                      func: () => {
                                        const data = {
                                          company_member_id: e?.id,
                                          status: e?.status ? 0 : 1,
                                        };
                                        console.log(data);
                                        props.editMemberStatus(
                                          data,
                                          props,
                                          () => showAlert(true)
                                        );
                                        setMember(undefined);
                                        setDeactivateModal(undefined);
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        {/* <Col sm={3}>
                      <Link to="#" onClick={() =>{setMember(e); setProfileModal(true)}} className="btn float-right" style={{ padding: 0 }}>View
                        Profile</Link>
                    </Col> */}
                      </Row>
                      <hr className="mt-0" />
                      <Row style={{ alignItems: "center" }}>
                        <Col sm={6} className="text-center">
                          <span className="d-block">
                            <i className="bx bx-calendar-alt bx-xs mr-1"></i>
                            Joined on
                          </span>
                          <span className="d-block">
                            {moment(e?.AddedMemberUser?.createdAt).format(
                              "MMM DD, YYYY"
                            )}
                          </span>
                        </Col>
                        <Col sm={6} className="text-center">
                          <span className="d-block">
                            <i className="bx bx-calendar-alt bx-xs mr-1"></i>
                            Positions
                          </span>
                          <span className="d-block">All</span>
                        </Col>
                        <Col lg={4} className="d-none">
                          <Row className="align-items-center">
                            <span className="float-right">Status</span>
                            <Col lg={6}>
                              <div
                                className="custom-control ml-0 float-right custom-switch custom-switch-lg"
                                dir="rtl"
                              >
                                <Switch
                                  isOn={e?.status}
                                  forId={`team-member-status-${e?.id}`}
                                  handleToggle={() => {
                                    setMember(e);
                                    setDeactivateModal({
                                      show: true,
                                      func: () => {
                                        const data = {
                                          company_member_id: e?.id,
                                          status: e?.status ? 0 : 1,
                                        };
                                        console.log(data);
                                        props.editMemberStatus(
                                          data,
                                          props,
                                          () => showAlert(true)
                                        );
                                        setMember(undefined);
                                        setDeactivateModal(undefined);
                                      },
                                    });
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              )
          )
        )}
        <Modal
          centered={true}
          size="md"
          show={profileModal}
          onHide={() => {
            setProfileModal(!profileModal);
            setMember(undefined);
          }}
        >
          <ProfileModal
            member={member}
            toggle={() => {
              setProfileModal(!profileModal);
              setMember(undefined);
            }}
          />
        </Modal>

        <Modal
          centered={true}
          size="md"
          show={editRoleModal}
          onHide={() => {
            setEditRoleModal(!editRoleModal);
            setMember(undefined);
          }}
        >
          <EditRole
            alert={() => showAlert(true)}
            RenderRole={
              member ? (
                <RenderRole roles={member?.AddedMemberUser?.Roles} />
              ) : (
                ""
              )
            }
            member={member}
            toggle={() => {
              setEditRoleModal(!editRoleModal);
              setMember(undefined);
            }}
          />
        </Modal>
        <Modal
          centered={true}
          show={deactivateModal?.show}
          onHide={() => {
            setDeactivateModal(undefined);
          }}
        >
          <DeletePrompt
            title={"Are you sure you want to deactivate?"}
            btnText={member?.status ? "Deactivate" : "Activate"}
            func={() => deactivateModal?.func()}
            toggle={() => {
              setMember(undefined);
              setDeactivateModal(undefined);
            }}
          />
        </Modal>

        <Col lg={4}>
          <div className="card add-member-card" style={{ cursor: "pointer" }}>
            <div className="p-2 text-subtitle">
              <div
                className={"card-body"}
                style={{ cursor: "inherit" }}
                onClick={() => setInviteModal(true)}
              >
                <Row className="justify-content-center mb-4">
                  <Col xl={12} className="text-center">
                    <div className="mb-2">
                      {/* <i className="fa fa-user-plus fa-4x"></i> */}
                      <i class="bx bxs-user-plus fa-4x"></i>
                    </div>
                    <h4 className="mb-3">Invite Team Member</h4>
                    <span>
                      Maximum {getCompanySubscription?.limit?.users?.allowed}{" "}
                      members allowed on your current plan (
                      {getCompanySubscription?.name})
                    </span>
                    {getCompanySubscription?.limit?.users?.allowed ===
                      getCompanySubscription?.limit?.users?.used && (
                      <div className="text-danger">
                        You have reached the limit
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
              <Row className="justify-content-end">
                <Col lg={12} className="text-right">
                  <span
                    className={`mr-2 ${
                      getCompanySubscription?.limit?.users?.used /
                        getCompanySubscription?.limit?.users?.allowed ===
                        1 && "text-danger"
                    } `}
                  >
                    {getCompanySubscription?.limit?.users?.used}/
                    {getCompanySubscription?.limit?.users?.allowed}
                  </span>
                  <a
                    className="text-primary"
                    target="_blank"
                    href="https://digitalhire.com/upgrade/"
                  >
                    Upgrade
                  </a>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </div>
      <Modal
        centered={true}
        size="md"
        show={inviteModal}
        onHide={() => {
          setInviteModal(!inviteModal);
        }}
      >
        <SendInviteModal
          toggle={() => {
            setInviteModal(!inviteModal);
          }}
          showAlert={() => showAlert(true)}
          company={company}
        />
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    company: getCompany(state),
    companyMembers: getCompanyMembers(state),
    responseMessage: getEditRoleMessage(state),
    getCompanySubscription: getCompanySubscription(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadCompany: (cb) => dispatch(getCompanyAction(cb)),
    editMemberStatus: (data, props, cb) =>
      dispatch(editCompanyMemberStatusAction(data, props.history, cb)),
    editMemberRole: (data, props, cb) =>
      dispatch(editCompanyMemberRoleAction(data, props.history)),
    getCompanySubscriptionAction: () =>
      dispatch(getCompanySubscriptionAction()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Members)
);
