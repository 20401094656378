import React, { useEffect } from "react";
import { Card, CardBody, Col, Media, ModalBody, Row } from "reactstrap";
import logoDark from "./../../../images/logo-dark.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import AlertCommon from 'components/Common/commonAlert'

const EmailInterviewModal = ({ evaluation, toggle }) => {
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if(success){
  //     setTimeout(() => { toggle() }, 2000)
  //   }
  // }, [success])

  return (
    <ModalBody className="p-4 text-dark">
      <Row className="justify-content-end">
        <Col lg={3}>
          <button
            className="float-right btn btn-link-secondary"
            onClick={() => toggle()}
          >
            close
          </button>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col lg={7}>
          <img src={logoDark} className="img-fluid" />
        </Col>
        <Col lg={12}>
          <h4 className="text-subtitle mt-3 mb-2">Email this Interview</h4>
          <p>
            Create and share a secure link with the recipient. They will only be
            able access the interview you are about to share.
          </p>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          {/* {success ? ( */}
          {/* <Col lg={12}><AlertCommon body={success} color="success"/></Col> */}
          {/* ) : null} */}
          <form>
            <div className="form-group">
              <label>
                Name{" "}
                <span className="text-muted">
                  <small>Required</small>
                </span>
              </label>
              <input
                name="name"
                className="form-control"
                placeholder="Name"
                type="text"
              />
            </div>
            <div className="form-group">
              <label>
                Email{" "}
                <span className="text-muted">
                  <small>Required</small>
                </span>
              </label>
              <input
                name="email"
                className="form-control"
                placeholder="user@example.com"
                type="text"
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-block d-inline-block"
            >
              Share
            </button>
          </form>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col lg={12}>
          By 'Sharing this Interview', you agree to our{" "}
          <Link to="#" className="text-primary">
            Terms and Services{" "}
          </Link>
          and that you have read our{" "}
          <Link to={"#"} className="text-primary">
            Privacy Policy
          </Link>
          .
        </Col>
      </Row>
    </ModalBody>
  );
};

export default EmailInterviewModal;
