import { lazy, Suspense, useEffect } from "react"

/// Components
import Index from "./jsx"
import { connect, Provider, useDispatch } from "react-redux"
import { Route, Switch, withRouter } from "react-router-dom"
// action
import { checkAutoLogin } from "./services/AuthService"
import { isAuthenticated, isVerified, hasCompanyProfile, getUserPermissions } from "./store/selectors/AuthSelectors"
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css"
import "./css/main.css"
import { store } from "./store/store"
import { getUserPermissionsAction, getUserProfileAction } from "./store/actions/AuthActions"
import { getCompanyAction, getCompanySubscriptionAction } from "./store/actions/CompanyActions"
import { getCompanyProfile } from "./store/selectors/CompanySelector"

import GoogleButton from "./jsx/components/Common/GoogleButton"
const SignUp = lazy(() => import("./jsx/pages/Registration"))
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"))
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500)
  })
})

function App(props) {
  const dispatch = useDispatch()
  const { getUserPermissions } = props
  useEffect(() => {
    checkAutoLogin(dispatch, props.history)
    if (props.isAuthenticated && props.isVerified && props.hasCompanyProfile) {
      // store.dispatch(getUserProfileAction())
      // // TODO:: Added Company Subscription Plan
      // store.dispatch(getCompanySubscriptionAction())
    }
  }, [dispatch, props.history])

  useEffect(() => {
    if (props.isAuthenticated && props.isVerified && props.hasCompanyProfile) {
      store.dispatch(getUserProfileAction())
      store.dispatch(getUserPermissionsAction())
      // store.dispatch(getCompanySubscriptionAction())
      store.dispatch(getCompanyAction())
      store.dispatch(getCompanySubscriptionAction())
    }
  }, [props?.isAuthenticated, props?.isVerified, props?.hasCompanyProfile])

  let routes = (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/register" component={SignUp} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/oauth/:token" component={GoogleButton} />
    </Switch>
  )

  if (props.isAuthenticated && props.isVerified && props.hasCompanyProfile) {
    return (
      <Provider store={store}>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <Index permissions={getUserPermissions} />
        </Suspense>
      </Provider>
    )
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routes}
        </Suspense>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    isVerified: isVerified(state),
    hasCompanyProfile: hasCompanyProfile(state),
    getUserPermissions: getUserPermissions(state),
  }
}

export default withRouter(connect(mapStateToProps)(App))
