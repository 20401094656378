import React, {Fragment, useState, useEffect, useRef} from "react";
import {Card, CardBody, CardTitle, Col, Progress, Row} from "reactstrap";
import {Spinner} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import {connect, useSelector} from "react-redux";
import Select from "react-select";

import Gradient from "./LinearGradientPicker";
import TeamMember from "./TeamMember";
import CompanyLogo from "./CompanyLogo";
import Alert from "./../Common/Alert";
import PageTitle from "../../layouts/PageTitle";
import {
    getCompany,
    getCompanyCountries,
    getCompanyIndustries,
    getCompanyMembers,
    getCompanyMessage,
    getCompanyLoading,
} from "../../../store/selectors/CompanySelector";
import {renderIndustries, renderCountries} from "../Common/commonFunctions";
import {
    getCompanyAction,
    updateCompanyAction,
} from "../../../store/actions/CompanyActions";
import "./company.css";
import Loader from "../Loader";

const Company = (props) => {
    const {company, responseMessage, companyMembers, countries, industries} =
        props;
    let errorsObj = {
        indus: "",
        cname: "",
        cEmail: "",
        addOne: "",
        city: "",
        cState: "",
        country: "",
        postal: "",
    };
    const [errors, setErrors] = useState(errorsObj);
    const [themeCode, setThemeCode] = useState("");
    const [cname, setCname] = useState("");
    const [indus, setIndus] = useState("");
    const [cEmail, setCemail] = useState("");
    // const [phone, setPhone] = useState()
    const [site, setSite] = useState("");
    const [addOne, setAddOne] = useState("");
    const [addTwo, setAddTwo] = useState("");
    const [city, setCity] = useState("");
    const [cState, setCState] = useState("");
    const [country, setCountry] = useState("");
    const [postal, setPostal] = useState("");

    const [facebook, setFaceBook] = useState("");
    const [tiktok, setTiktok] = useState("");
    const [linkedIn, setLinkedIn] = useState("");
    const [twitter, setTwitter] = useState("");
    const [youTube, setYouTube] = useState("");
    const [insta, setInsta] = useState("");
    const [loader, showLoader] = useState(false);
    const [alert, showAlert] = useState(false);
    const [currentSocial, setCurrentSocial] = useState("");
    const [socialInputs, setSocialInputs] = useState({});

    console.log(indus);
    useEffect(() => {
        if (Object.keys(company).length > 0) {
            setThemeCode(company.theme_code);
            setCname(company.name);
            setIndus({value: company.industry, label: company.industry});
            setCemail(company.email);
            // setPhone(company.address);
            setSite(company.CompanyProfile?.website);
            setAddOne(company.address);
            setAddTwo(company.address2);
            setCity(company.city);
            setCState(company.state);
            setCountry(company.country);
            setPostal(company.postal_code);
            setSocialInputs({
                facebook: company.CompanyProfile?.facebook !== "" ? company.CompanyProfile?.facebook : undefined,
                linkedin: company.CompanyProfile?.linkedin !== "" ? company.CompanyProfile?.linkedin : undefined,
                tiktok: company.CompanyProfile?.tiktok !== "" ? company.CompanyProfile?.tiktok : undefined,
                twitter: company.CompanyProfile?.twitter !== "" ? company.CompanyProfile?.twitter : undefined,
                youtube: company.CompanyProfile?.youtube !== "" ? company.CompanyProfile?.youtube : undefined,
                instagram: company.CompanyProfile?.instagram !== "" ? company.CompanyProfile?.instagram : undefined
            })
            // setFaceBook(company.CompanyProfile?.facebook);
            // setLinkedIn(company.CompanyProfile?.linkedin);
            // setTwitter(company.CompanyProfile?.twitter);
            // setYouTube(company.CompanyProfile?.youtube);
            // setInsta(company.CompanyProfile?.instagram);
            // setTiktok(company.CompanyProfile?.tiktok);
        }
    }, [company]);

    useEffect(() => {
        showLoader(true);
        props.loadCompany(() => showLoader(false));
    }, []);

    function onSubmit(e) {
        e.preventDefault();
        // Will uncomment letter this code when we have the backend
        // const formData = new formData( e.target )
        // const socialInputs = Object.fromEntries(formData.entries())
        let error = false;
        const errorObj = {...errorsObj};
        if (indus.value === "") {
            errorObj.indus = "Industry is Required";
            error = true;
        }
        if (cname === "") {
            errorObj.cname = "Company Name is Required";
            error = true;
        }
        if (cEmail === "") {
            errorObj.cEmail = "Company Email is Required";
            error = true;
        }
        if (addOne === "") {
            errorObj.addOne = "Address is Required";
            error = true;
        }
        if (city === "") {
            errorObj.city = "City is Required";
            error = true;
        }
        if (cState === "") {
            errorObj.cState = "State is Required";
            error = true;
        }
        if (country === "") {
            errorObj.country = "Country is Required";
            error = true;
        }
        if (postal === "") {
            errorObj.postal = "Postal Code is Required";
            error = true;
        }
        setErrors(errorObj);
        if (error) {
            return;
        }
        const data = {
            company_data: {
                name: cname,
                industry: indus.value,
                // logo: "Update Logo",
                // email: cEmail,
                address: addOne,
                address2: addTwo,
                city: city,
                state: cState,
                country: country,
                postal_code: postal,
                theme_code: themeCode,
            },
            company_profile_data: {
                description: "",
                website: site,
                ...socialInputs
            },
        };

        props.updateCompany(data, props, () => showAlert(true));
    }

    const addSocialInput = (e) => {
        // setCurrentSocial(e.target.value)
        setSocialInputs({...socialInputs, [e.target.value]: e.target.value});
    };
    const addSocialData = (e) => {
        // setCurrentSocial(e.target.value)
        setSocialInputs({...socialInputs, [e.target.name]: e.target.value});
    };
    return (
        <Fragment>
            {/*<PageTitle activeMenu="Company Profile" motherMenu="Setting" />*/}
            <Alert
                alert={alert}
                type={
                    responseMessage?.success
                        ? "success"
                        : responseMessage?.error && "error"
                }
                variant={
                    responseMessage?.success
                        ? "success"
                        : responseMessage?.error && "danger"
                }
                msg={responseMessage?.success || responseMessage?.error}
                dismissAlert={() => showAlert(false)}
            />
            <div className="row">
                <div className="col-xl-5 col-lg-5 col-md-8 col-sm-12">
                    <div className="row">
                        <CompanyLogo
                            showAlert={() => showAlert(true)}
                            logo={company.logo}
                            loadCompany={() => props.loadCompany()}
                        />
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title">Theme Branding</h5>
                                </div>
                                <div className="card-body">
                                    {/* <div className="row"> */}

                                    <span className="text-subtitle">
                    Choose your theme color to customise interview screen.
                  </span>

                                    <div className="row align-items-center">
                                        <Col>
                                            {/* <Gradient /> */}
                                            <input
                                                className="colorInput"
                                                type="color"
                                                id="example-color-input"
                                                value={themeCode}
                                                onChange={(e) => setThemeCode(e.target.value)}
                                            ></input>
                                        </Col>
                                        <Col lg={5}>
                                            <input
                                                type=""
                                                placeholder="#556ee6"
                                                value={themeCode}
                                                className="form-control"
                                                onChange={(e) => setThemeCode(e.target.value)}
                                            />
                                        </Col>
                                        <Col lg={5}>
                                            <small className="text-muted">
                                                Select or Enter HEX CODE
                                            </small>
                                        </Col>
                                    </div>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                        <TeamMember
                            data={companyMembers}
                            loader={loader}
                            history={props.history}
                        />
                    </div>
                </div>
                <div className="col-xl-7">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Company Profile</h5>
                        </div>
                        <div className="card-body">
                            <div className="profile-statistics">
                                <div className="row">
                                    <form className="col-12" onSubmit={onSubmit}>
                                        <div className="row">
                                            <div className="col-lg-6 mb-2">
                                                <div className="form-group">
                                                    <label className="text-label">
                                                        Company Name{" "}
                                                        <span className="text-muted">
                              <small>Required</small>
                            </span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={company.name}
                                                        onChange={(e) => setCname(e.target.value)}
                                                    />
                                                    {errors.cname && (
                                                        <div className="animated fadeInUp text-danger fs-12">
                                                            {errors.cname}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-2">
                                                <div className="form-group">
                                                    <label className="text-label">
                                                        Industry{" "}
                                                        <span className="text-muted">
                              <small>Required</small>
                            </span>
                                                    </label>
                                                    <Select
                                                        placeholder={"Select industry"}
                                                        value={indus}
                                                        onChange={setIndus}
                                                        options={renderIndustries(industries)}
                                                    />
                                                    {/* <select value={indus} className="form-control" onChange={(e) => setIndus(e.target.value)}>
                            {industries.length > 0 ? (
                              renderIndustries(industries)
                            ) : (
                              <option value="" disabled>
                                No data Found
                              </option>
                            )}
                          </select> */}
                                                    {errors.indus && (
                                                        <div className="animated fadeInUp text-danger fs-12">
                                                            {errors.indus}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-2">
                                                <div className="form-group">
                                                    <label className="text-label">
                                                        Company Email
                                                        <span className="text-muted">
                              <small> Required</small>
                            </span>
                                                    </label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        value={cEmail}
                                                        disabled
                                                    />
                                                    {errors.cEmail && (
                                                        <div className="animated fadeInUp text-danger fs-12">
                                                            {errors.cEmail}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            {/* <div className="col-lg-6 mb-2">
                        <div className="form-group">
                          <label className="text-label">Phone <span
                            className="text-muted"><small>Required</small></span></label>
                          <PhoneInput
                            country={"us"}
                            value={phone}
                            id="val-phone1"
                            onChange={phone => setPhone(phone)}
                            placeholder="541-754-3010"
                            inputStyle={{ width: "99%", height: "-webkit-fill-available", border: "hidden" }}
                            containerClass={"form-control"}
                            containerStyle={{ padding: 0 }}
                            buttonStyle={{ borderRadius: " 10px 0 0 10px", border: "0" }}
                          />
                        </div>
                      </div> */}
                                            <div className="col-lg-6 mb-2">
                                                <div className="form-group">
                                                    <label className="text-label">
                                                        Address{" "}
                                                        <span className="text-muted">
                              <small>Required</small>
                            </span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={addOne}
                                                        onChange={(e) => setAddOne(e.target.value)}
                                                    />
                                                    {errors.addOne && (
                                                        <div className="animated fadeInUp text-danger fs-12">
                                                            {errors.addOne}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-2">
                                                <div className="form-group">
                                                    <label className="text-label">Address Line 2</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={addTwo}
                                                        onChange={(e) => setAddTwo(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-2">
                                                <div className="form-group">
                                                    <label className="text-label">
                                                        City{" "}
                                                        <span className="text-muted">
                              <small>Required</small>
                            </span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={city}
                                                        onChange={(e) => setCity(e.target.value)}
                                                    />
                                                    {errors.city && (
                                                        <div className="animated fadeInUp text-danger fs-12">
                                                            {errors.city}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-2">
                                                <div className="form-group">
                                                    <label className="text-label">
                                                        State{" "}
                                                        <span className="text-muted">
                              <small>Required</small>
                            </span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={cState}
                                                        onChange={(e) => setCState(e.target.value)}
                                                    />
                                                    {errors.cState && (
                                                        <div className="animated fadeInUp text-danger fs-12">
                                                            {errors.cState}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-2">
                                                <div className="form-group">
                                                    <label className="text-label">
                                                        Postal Code{" "}
                                                        <span className="text-muted">
                              <small>Required</small>
                            </span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={postal}
                                                        onChange={(e) => setPostal(e.target.value)}
                                                    />
                                                    {errors.postal && (
                                                        <div className="animated fadeInUp text-danger fs-12">
                                                            {errors.postal}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-2">
                                                <div className="form-group">
                                                    <label className="text-label">
                                                        Country{" "}
                                                        <span className="text-muted">
                              <small>Required</small>
                            </span>
                                                    </label>
                                                    <select
                                                        value={country}
                                                        className="form-control"
                                                        onChange={(e) => setCountry(e.target.value)}
                                                    >
                                                        {countries.length > 0 ? (
                                                            renderCountries(countries)
                                                        ) : (
                                                            <option value="" disabled>
                                                                No data Found
                                                            </option>
                                                        )}
                                                    </select>
                                                    {errors.country && (
                                                        <div className="animated fadeInUp text-danger fs-12">
                                                            {errors.country}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-2">
                                                <div className="form-group">
                                                    <label className="text-label">Website</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={site}
                                                        onChange={(e) => setSite(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <h5>Social Accounts</h5>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <select
                                                            name="socials"
                                                            className="form-control"
                                                            onChange={addSocialInput}
                                                        >
                                                            <option selected disabled>
                                                                List
                                                            </option>
                                                            {/*<>*/}
                                                            {/*    {Object.keys(socialInputs)?.map((social, index) => {*/}
                                                            {/*        return (*/}
                                                            {/*            <option*/}
                                                            {/*                value={social}>{social}</option>*/}
                                                            {/*        )*/}
                                                            {/*    })}*/}
                                                            {/*</>*/}
                                                            <option value="linkedin">Linkedin</option>
                                                            {/*<option value="skype">Skype</option>*/}
                                                            <option value="facebook">Facebook</option>
                                                            <option value="youtube">Instagram</option>
                                                            <option value="twitter">Twitter</option>
                                                            <option value="Tiktok">Tiktok</option>
                                                            <option value="youtube">Youtube</option>
                                                            {/*<option value="pinterest">Pinterest</option>*/}
                                                            {/*<option value="dribbble">Dribbble</option>*/}
                                                        </select>
                                                    </div>
                                                </div>
                                                {/*<div className="col-sm-6">*/}
                                                {/*    <button*/}
                                                {/*        className="btn btn-secondary"*/}
                                                {/*        type="button"*/}
                                                {/*        onClick={() => setSocialInputs([])}*/}
                                                {/*    >*/}
                                                {/*        Clear Fields*/}
                                                {/*    </button>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                        <Row>
                                            {Object.keys(socialInputs)?.map((social, index) => {

                                                if (socialInputs[social] !== undefined) {
                                                    console.log(socialInputs)
                                                    return (
                                                        <Col lg={6} className="mb-2">
                                                            <div className="form-group" key={index}>
                                                                <div className="input-group mb-3">
                                                                    <div className="input-group-prepend">
                                  <span
                                      className="input-group-text"
                                      id="basic-addon1"
                                  >
                                      {
                                          social === "tiktok" ?
                                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"
                                                   style={{fill: "#485056", height: "14px"}}
                                              >
                                                  <path
                                                      d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/>
                                              </svg>
                                              : <i className={"ti-" + social}></i>
                                      }

                                  </span>
                                                                    </div>
                                                                    <input
                                                                        name={social}
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder={social}
                                                                        aria-label={social}
                                                                        value={socialInputs[social]}
                                                                        onChange={addSocialData}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    );
                                                }
                                            })}
                                        </Row>

                                        {/* <Col lg={6}>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">
                              <i className="fa fa-facebook-square"></i>
                            </span>
                          </div>
                          <input onChange={e => setFaceBook(e.target.value)}
                                 value={facaBook}
                                 type="text"
                                 className="form-control"
                                 placeholder="facebook.com/<username>"
                                 aria-label="facebook.com/<username>"
                          />
                        </div>
                      </Col> */}
                                        {/*//               <Col lg={6} className="mb-2">*/}
                                        {/*//                   <div className="form-group">*/}
                                        {/*//                       <div className="input-group mb-3">*/}
                                        {/*//                           <div className="input-group-prepend">*/}
                                        {/*<span*/}
                                        {/*    className="input-group-text"*/}
                                        {/*    id="basic-addon1"*/}
                                        {/*>*/}
                                        {/*  <i className="fa fa-linkedin"></i>*/}
                                        {/*</span>*/}
                                        {/*                          </div>*/}
                                        {/*                          <input*/}
                                        {/*                              onChange={(e) => setLinkedIn(e.target.value)}*/}
                                        {/*                              value={linkedIn}*/}
                                        {/*                              type="text"*/}
                                        {/*                              className="form-control"*/}
                                        {/*                              placeholder="linkedin.com/company/<username>"*/}
                                        {/*                              aria-label="linkedin.com/company/<username>"*/}
                                        {/*                          />*/}
                                        {/*                      </div>*/}
                                        {/*                  </div>*/}
                                        {/*              </Col>*/}
                                        {/*              <Col lg={6} className="mb-3">*/}
                                        {/*                  <div className="form-group">*/}
                                        {/*                      <div className="input-group mb-3">*/}
                                        {/*                          <div className="input-group-prepend">*/}
                                        {/*<span*/}
                                        {/*    className="input-group-text"*/}
                                        {/*    id="basic-addon1"*/}
                                        {/*>*/}
                                        {/*  <i className="ti-twitter-alt"></i>*/}
                                        {/*</span>*/}
                                        {/*                          </div>*/}
                                        {/*                          <input*/}
                                        {/*                              onChange={(e) => setTwitter(e.target.value)}*/}
                                        {/*                              value={twitter}*/}
                                        {/*                              type="text"*/}
                                        {/*                              className="form-control"*/}
                                        {/*                              placeholder="twitter.com/<username>"*/}
                                        {/*                              aria-label="twitter.com/<username>"*/}
                                        {/*                          />*/}
                                        {/*                      </div>*/}
                                        {/*                  </div>*/}
                                        {/*              </Col>*/}
                                        {/*              <Col lg={6} className="mb-2">*/}
                                        {/*                  <div className="form-group">*/}
                                        {/*                      <div className="input-group mb-3">*/}
                                        {/*                          <div className="input-group-prepend">*/}
                                        {/*<span*/}
                                        {/*    className="input-group-text"*/}
                                        {/*    id="basic-addon1"*/}
                                        {/*>*/}
                                        {/*  <i className="ti-youtube"></i>*/}
                                        {/*</span>*/}
                                        {/*                          </div>*/}
                                        {/*                          <input*/}
                                        {/*                              onChange={(e) => setYouTube(e.target.value)}*/}
                                        {/*                              value={youTube}*/}
                                        {/*                              type="text"*/}
                                        {/*                              className="form-control"*/}
                                        {/*                              placeholder="youtube.com/channel/<username>"*/}
                                        {/*                              aria-label="youtube.com/channel/<username>"*/}
                                        {/*                          />*/}
                                        {/*                      </div>*/}
                                        {/*                  </div>*/}
                                        {/*              </Col>*/}
                                        {/*              <Col lg={6} className="mb-2">*/}
                                        {/*                  <div className="form-group">*/}
                                        {/*                      <div className="input-group mb-3">*/}
                                        {/*                          <div className="input-group-prepend">*/}
                                        {/*<span*/}
                                        {/*    className="input-group-text"*/}
                                        {/*    id="basic-addon1"*/}
                                        {/*>*/}
                                        {/*  <i className="ti-instagram "></i>*/}
                                        {/*</span>*/}
                                        {/*                          </div>*/}
                                        {/*                          <input*/}
                                        {/*                              onChange={(e) => setInsta(e.target.value)}*/}
                                        {/*                              value={insta}*/}
                                        {/*                              type="text"*/}
                                        {/*                              className="form-control"*/}
                                        {/*                              placeholder="instagram.com/<username>"*/}
                                        {/*                              aria-label="instagram.com/<username>"*/}
                                        {/*                          />*/}
                                        {/*                      </div>*/}
                                        {/*                  </div>*/}
                                        {/*              </Col>*/}


                                        {/*              <Col lg={6} className="mb-2">*/}
                                        {/*                  <div className="form-group">*/}
                                        {/*                      <div className="input-group mb-3">*/}
                                        {/*                          <div className="input-group-prepend">*/}
                                        {/*<span*/}
                                        {/*    className="input-group-text"*/}
                                        {/*    id="basic-addon1"*/}
                                        {/*>*/}
                                        {/*  <i className="ti-facebook "></i>*/}
                                        {/*</span>*/}
                                        {/*                          </div>*/}
                                        {/*                          <input*/}
                                        {/*                              onChange={(e) => setFaceBook(e.target.value)}*/}
                                        {/*                              value={facebook}*/}
                                        {/*                              type="text"*/}
                                        {/*                              className="form-control"*/}
                                        {/*                              placeholder="facebook.com/<username>"*/}
                                        {/*                              aria-label="facebook.com/<username>"*/}
                                        {/*                          />*/}
                                        {/*                      </div>*/}
                                        {/*                  </div>*/}
                                        {/*              </Col>*/}
                                        {/*              <Col lg={6} className="mb-2">*/}
                                        {/*                  <div className="form-group">*/}
                                        {/*                      <div className="input-group mb-3">*/}
                                        {/*                          <div className="input-group-prepend">*/}
                                        {/*<span*/}
                                        {/*    className="input-group-text"*/}
                                        {/*    id="basic-addon1"*/}
                                        {/*>*/}
                                        {/*  <i className="ti-tiktok "></i>*/}
                                        {/*</span>*/}
                                        {/*                          </div>*/}
                                        {/*                          <input*/}
                                        {/*                              onChange={(e) => setTiktok(e.target.value)}*/}
                                        {/*                              value={tiktok}*/}
                                        {/*                              type="text"*/}
                                        {/*                              className="form-control"*/}
                                        {/*                              placeholder="tiktok.com/<username>"*/}
                                        {/*                              aria-label="tiktok.com/<username>"*/}
                                        {/*                          />*/}
                                        {/*                      </div>*/}
                                        {/*                  </div>*/}
                                        {/*              </Col>*/}

                                        <button
                                            disabled={alert}
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            {/* {alert ? <Spinner variant="mutted" animation="border" /> : "Save changes"} */}
                                            {props?.updateCompanyLoading ? (
                                                <Loader spinnerClass="text-white"/>
                                            ) : (
                                                "Save Changes"
                                            )}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        company: getCompany(state),
        companyMembers: getCompanyMembers(state),
        responseMessage: getCompanyMessage(state),
        countries: getCompanyCountries(state),
        industries: getCompanyIndustries(state),
        updateCompanyLoading: getCompanyLoading(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadCompany: (cb) => dispatch(getCompanyAction(cb)),
        updateCompany: (data, props, cb) =>
            dispatch(updateCompanyAction(data, props.history, cb)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Company)
);
