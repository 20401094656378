import React, {useState, Fragment} from "react"
import {withRouter, Link} from "react-router-dom"
import {connect} from "react-redux"
import {Badge, DropdownItem, DropdownMenu, DropdownToggle, Label, Progress, UncontrolledDropdown} from "reactstrap"
import AddNewTag from "./AddNewTag"
import moment from "moment"
import DeletePrompt from "../Common/DeletePrompt"
import {renderResponse, renderStatus} from "../Common/RenderText"

import {Modal, Row, Col, Table, Button, OverlayTrigger, Tooltip} from "react-bootstrap"
import "./candidate.css"
import pic1 from "./../../../images/default.svg"
import goldBadge from "./../../../images/gold-badge.svg"
import silverBadge from "./../../../images/silver-badge.svg"
import {
    deleteCompanyCandidateAction,
    deleteCompanyTagAction,
    resetCompanyCandidateAction,
    sendCompanyCandidateReminderAction,
    removeCompanyCandidateTagAction, markCompanyCandidateEvaluationAction,
} from "../../../store/actions/CompanyActions"
import TeamMember from "./TeamMember";
import TagsInput from "./TagsInput";

const CandidateList = (props) => {
    const {
        canData,
        deleteCandidates,
        removeCompanyCandidateTag,
        resetCandidates,
        sendReminder,
        showAlert,
        subData,
        markCompanyCandidateEvaluation
    } =
        props
    // console.log("canData", canData)
    const [progressModal, setProgressModal] = useState({
        show: false,
        func: null,
    })

    const [deleteModal, setDeleteModal] = useState({
        show: false,
        func: null,
    })

    const [tagModal, setTagModal] = useState(null)
    const [teamMemberModal, setTeamMemberModal] = useState(null)
    const [interviewLimitModel, setInterviewLimitModel] = useState({show: false, func: undefined})
    const renderRating = (myResponse) => {
        if (myResponse === "maybe" || myResponse === "pending" || !myResponse) return "light"
        if (myResponse === "no") return "danger"
        if (myResponse === "yes") return "primary"
    }

    const toggleTag = (tag, interviewID, cb) => {
        // console.log(tag);
        removeCompanyCandidateTag(
            {
                tag_id: tag,
                interview_id: interviewID,
            },
            props,
            (bol) => {
                cb && cb(bol)
                bol && showAlert()
            }
        )
    }

    const renderTooltip = (props, text) => {
        return (
            <Tooltip style={{width: "300px"}} {...props}>
                {text}
            </Tooltip>
        )
    }

    const renderInterviewTags = (tags, interviewID, cb) => {
        let result = ["Add tags"]
        if (tags && tags.length > 0) {
            result = []
            tags.map((tag, i) => {
                result.push(
                    <Badge
                        key={`${tag?.id}-${i}`}
                        style={{color: tag?.color, backgroundColor: `${tag?.color}20`}}
                        className="mr-1"
                    >
                        {tag?.name}
                        <span
                            onClick={() => toggleTag(tag?.Interviews_Tag?.tag_id, interviewID, (cb = null))}
                            className="badge font-size-10 ml-1"
                            style={{cursor: "pointer"}}
                        >
              X
            </span>
                    </Badge>
                )
            })
        }
        return result
    }

    return (
        <Fragment>
            {canData.map((item) => (
                <tbody key={item.id}>
                <tr>
                    <td>
                        <img
                            className={`rounded-circle`}
                            src={item?.Candidate?.image || pic1}
                            alt="Dp"
                            style={{width: "2.5rem", height: "2.5rem"}}
                        />
                    </td>
                    <td>
                        {item?.favorite ? (
                            <img
                                className="rounded-circle"
                                src={goldBadge}
                                alt="Gold badge"
                                style={{width: "2rem", height: "2rem", cursor: "pointer"}}
                                onClick={() => {
                                    const data = {
                                        interview_id: item.id,
                                        interview_data: {
                                            favorite: false
                                        }
                                    }
                                    markCompanyCandidateEvaluation(data)
                                }}
                            />
                        ) : (
                            <img
                                className="rounded-circle"
                                src={silverBadge}
                                alt="Silver Badge"
                                style={{
                                    width: "2rem", height: "2rem",
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    const data = {
                                        interview_id: item.id,
                                        interview_data: {
                                            favorite: true
                                        }
                                    }
                                    markCompanyCandidateEvaluation(data)
                                }}
                            />
                        )}
                    </td>
                    <td>
                        <div style={{width: "8rem", overflow: "hidden"}} className="text-truncate font-size-14">
                            <Link
                                to={
                                    item?.progress === 0 || item?.createdAt >= subData?.limit_reached_at
                                        ? "#"
                                        : `/evaluation/${item?.id}`
                                }
                                onClick={() => {
                                    if (item?.createdAt >= subData?.limit_reached_at) {
                                        setInterviewLimitModel({show: true})
                                    }
                                }}
                                style={{cursor: `${item?.progress === 0 ? "default" : "pointer"}`}}
                                className={
                                    item?.progress === 0
                                        ? "text-dark"
                                        : item?.createdAt >= subData?.limit_reached_at
                                            ? "text-danger"
                                            : "text-primary"
                                }
                            >
                                {item?.Candidate?.first_name + " " + item?.Candidate?.last_name}
                            </Link>
                            <span className="d-block text-muted text-truncate">
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={props => renderTooltip(props, `${item?.Position?.title}`)}>

                                        <small title={item?.Position?.title}
                                               style={{cursor: "pointer"}}>{item?.Position?.title}</small>
                                    </OverlayTrigger>

                            </span>
                        </div>
                    </td>
                    <td>{item?.is_reviewed === true ? "Evaluated" : renderStatus(item?.status)}</td>
                    <td>{item?.completion_date ? moment(item?.completion_date).format("MMM DD, YYYY") : "N/A"}</td>
                    <td className="">
                        <span>
                            {item?.progress}/{item?.question_count}
                        </span>
                        <Progress className="mb-1" color="primary"
                                  value={(item?.progress / item?.question_count) * 100}/>
                    </td>
                    <td>{item?.start_date ? moment(item?.start_date).format("MMM DD, YYYY") : "N/A"}</td>
                    <td>
                        <div className="mb-1">
                            <span className={`badge badge-${renderRating(item?.my_response)}`}>
                                <i className="mdi mdi-star mr-1"/> {item?.ratings ? Number(item?.ratings).toFixed(1) : 0}
                            </span>
                        </div>
                        <span
                            className="d-block">{item?.my_response ? renderResponse(item?.my_response) : "Pending"}
                        </span>
                    </td>

                    <td className="align-items-center">
                        {
                            tagModal && tagModal.id === item.id ?
                                <>

                                    <TagsInput
                                        tags={tagModal?.Tag}
                                        interview={tagModal}
                                        members={tagModal?.CompanyMembers}
                                        renderInterviewTags={renderInterviewTags}
                                        onHide={() => {
                                            setTagModal(null)
                                        }}
                                    />

                                </> : <>
                                    {renderInterviewTags(item?.Tag, item.id, null)}
                                    {item?.Tag.length < 4 ? <span style={{position: "relative", left: "3px", top: "3px"}}>
                            <i
                                style={{cursor: "pointer"}}
                                onClick={() => setTagModal(item)}
                                className="fa fa-plus-square font-size-18 text-primary"
                            />
                            </span> : null}

                                </>
                        }


                    </td>
                    <td className="">
                        <UncontrolledDropdown>
                            <DropdownToggle href="#" className="card-drop" tag="i">
                                <i className="mdi mdi-dots-horizontal font-size-18"/>
                            </DropdownToggle>
                            <DropdownMenu right>
                                {item?.progress > 0 && (
                                    <DropdownItem>
                                        <Link className="text-primary" to={`/evaluation/${item?.id}`}>
                                            Evaluate
                                        </Link>
                                    </DropdownItem>
                                )}
                                <DropdownItem
                                    onClick={() => {
                                        sendReminder({interview_id: item?.id}, props, () => {
                                            showAlert()
                                        })
                                    }}
                                    className="text-dark"
                                >
                                    Send Reminder
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => {
                                        setProgressModal({
                                            show: true,
                                            func: () => {
                                                resetCandidates({interview_id: item?.id}, props, () => {
                                                    showAlert()
                                                })
                                                setProgressModal(null)
                                            },
                                        })
                                    }}
                                    className="text-dark"
                                >
                                    Reset Progress
                                </DropdownItem>

                                <DropdownItem
                                    className="text-dark"
                                    onClick={() => {
                                        setTeamMemberModal(item)
                                    }}
                                >
                                    Team Member
                                </DropdownItem>
                                <DropdownItem
                                    className="text-danger"
                                    onClick={() => {
                                        setDeleteModal({
                                            show: true,
                                            func: () => {
                                                deleteCandidates({interview_id: item?.id}, props, () => {
                                                    showAlert()
                                                })
                                                setDeleteModal(null)
                                            },
                                        })
                                    }}
                                >
                                    Delete
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </td>
                </tr>
                </tbody>
            ))}
            {/*<Modal*/}
            {/*    centered={true}*/}
            {/*    size="md"*/}
            {/*    show={tagModal ? true : false}*/}
            {/*    onHide={() => {*/}
            {/*        setTagModal(null)*/}
            {/*    }}*/}
            {/*>*/}

            {/*    <AddNewTag*/}
            {/*        tags={tagModal?.Tag}*/}
            {/*        interview={tagModal}*/}
            {/*        members={tagModal?.CompanyMembers}*/}
            {/*        renderInterviewTags={renderInterviewTags}*/}
            {/*        onHide={() => {*/}
            {/*            setTagModal(null)*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</Modal>*/}
            <Modal
                centered={true}
                size="md"
                show={teamMemberModal ? true : false}
                onHide={() => {
                    setTeamMemberModal(null)
                }}
            >
                <TeamMember
                    tags={teamMemberModal?.Tag}
                    interview={teamMemberModal}
                    members={teamMemberModal?.CompanyMembers}
                    renderInterviewTags={renderInterviewTags}
                    onHide={() => {
                        setTeamMemberModal(null)
                    }}
                />
            </Modal>
            <Modal
                centered={true}
                show={progressModal?.show}
                onHide={() => {
                    setProgressModal(null)
                }}
            >
                <DeletePrompt
                    title={"Are you sure you want to reset this interview?"}
                    btnText={"Reset"}
                    func={() => progressModal?.func()}
                    toggle={() => {
                        setProgressModal(null)
                    }}
                />
            </Modal>
            <Modal
                centered={true}
                show={deleteModal?.show}
                onHide={() => {
                    setDeleteModal(null)
                }}
            >
                <DeletePrompt
                    title={"Are you sure you want to delete this interview?"}
                    func={() => deleteModal?.func()}
                    toggle={() => {
                        setDeleteModal(null)
                    }}
                />
            </Modal>
            <Modal
                centered={true}
                show={interviewLimitModel?.show}
                onHide={() => {
                    setInterviewLimitModel(null)
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Subscription Interview Limit Reached</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Dear Customer your interview limit has be reached please resubscribe or upgrade your
                        subscription </p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setInterviewLimitModel({show: false})}>
                        Close
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => {
                            props.history.push("/subscriptions")
                        }}
                    >
                        Upgrade
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

// const mapStateToProps = state => {
//   return {
//     // getCandidates: getCompanyCandidates(state),
//     getSubscription: getCompanySubscription(state)
//   }
// }

const mapDispatchToProps = (dispatch) => {
    return {
        deleteCandidates: (data, props, cb) => dispatch(deleteCompanyCandidateAction(data, props.history, cb)),
        resetCandidates: (data, props, cb) => dispatch(resetCompanyCandidateAction(data, props.history, cb)),
        sendReminder: (data, props, cb) => dispatch(sendCompanyCandidateReminderAction(data, props.history, cb)),
        deleteCompanyTag: (data, props, cb) => dispatch(deleteCompanyTagAction(data, props.history, cb)),
        removeCompanyCandidateTag: (data, props, cb) => dispatch(removeCompanyCandidateTagAction(data, props.history, cb)),
        markCompanyCandidateEvaluation: (data, props, cb) =>
            dispatch(markCompanyCandidateEvaluationAction(data, null, cb)),

    }
}
export default withRouter(connect(null, mapDispatchToProps)(CandidateList))
