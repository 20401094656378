import React from 'react'
import { Badge, Card, CardBody, Col, Row } from 'reactstrap'

const QuestionsDetails = ({ position }) => {
  const renderTypeColor = (type) => {
    if (type === 'video') return { backgroundColor: '#00BA5220', color: '#00BA52' }
    if (type === 'essay') return { backgroundColor: '#237BFF20', color: '#237BFF'}
    if (type === 'shortText') return { backgroundColor: '#5743D920', color: '#5743D9' }
  }

  const renderQuestionType = (type) => {
    if (type === 'video') return 'Video'
    if (type === 'essay') return 'Essay'
    if (type === 'shortText') return 'Short Text'
  }
  const renderQuestions = () => {
    if (position && position?.Questions?.length && position?.Questions.length > 0) {
      return position?.Questions?.map((ques, key) => {
        return (
          <Card className="mb-3" style={{height:'auto'}} key={key}>
            <CardBody>
              <Row className="align-items-center">
                <Col lg={9}>
                  <div>
                    <div>
                      <h5 className="d-inline-block mr-2">Question {key+1}:</h5>
                      <span>{ques.title}?</span>
                    </div>
                    <div>
                      <span className="mr-3">Time: {ques.time_allowed} Min</span>
                      <span>Attempts: {ques?.attempts_allowed}</span>
                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <Badge className="float-right" style={renderTypeColor(ques.type)}>{renderQuestionType(ques.type)}</Badge>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )
      })
    }
  }

  return (
    <div className='custom-scroll custom-scroll-bg' style={{ overflowY: "scroll", height: "450px"}}>
      {renderQuestions()}
    </div>
  )
}

export default QuestionsDetails