import React, {useEffect, useState, useRef} from "react"
import {Row, Col, Card, CardBody, Progress, Spinner, Label} from "reactstrap"
import {Table, OverlayTrigger, Tooltip} from "react-bootstrap"
import "./candidate.css"
import PageTitle from "../../layouts/PageTitle"
import CandidateList from "./CandidateList"
import Alert from "./../Common/Alert"
import CandidatesFilters from "./CandidatesFilters"
import PaginationComponent from "./../Common/PaginationComponent"
import {withRouter} from "react-router-dom"
import {connect} from "react-redux"
import {
    getCompanyCandidates,
    getMeiaAddedMessage,
    getCompanySubscription,
    getCompanyCandidateFilterDataSelector
} from "../../../store/selectors/CompanySelector"
import {
    getCompanyCandidateFilterDataAction,
    getCompanyCandidatesAction,
    getCompanySubscriptionAction,
    getCompanyTagsAction
} from "../../../store/actions/CompanyActions"
import moment from "moment"

import "../Common/rangeInput.css"

const Candidate = (props) => {
    const {
        getCompanySubscription,
        getCompanyCandidateFilterData,
        getCandidates,
        getSubscription,
        getCompanyTags,
        getMessage,
        getFilterData
    } = props
    const [alert, showAlert] = useState(false)
    const [loader, setloader] = useState(false)
    const [query, setQuery] = useState(props?.history?.location?.state ? {status: 'invited'} : {})
    const [sort, setSort] = useState(["createdAt", "DESC"])
    const isMounted = useRef(false)
    const [trackSort, setTrackSort] = useState(0)

    useEffect(() => {
        onSubmit()
        getCompanyCandidateFilterData()
        getCompanySubscription()
        // getCompanyTags({},props);
    }, [])

    useEffect(() => {
        if (getMessage?.showLoading) {
            onSubmit()
            getCompanySubscription()
            // getCompanyTags({},props);
            getCompanyCandidateFilterData()
        }
    }, [getMessage?.showLoading])

    useEffect(() => {
        if (Object.keys(query).length > 0) {
            onSubmit()
            isMounted.current = true
        } else if (isMounted.current) {
            onSubmit()
            isMounted.current = false
        }
        onSubmit()
    }, [query, sort])

    const onSubmit = (page = 1) => {

        // console.log("Sorted Array", sort)

        const data = {
            filter_options: {
                ...query,
                sort_array: [sort]
            },
            pagination: {
                page: page,
                size: 10
            }
        }
        if (!getCandidates) {
            setloader(true)
        }
        props.loadCompanyCandidates(data, props, () => setloader(false))
    }
    const renderTooltip = (props, text) => {
        return (
            <Tooltip style={{width: "300px"}} {...props}>
                {text}
            </Tooltip>
        )
    }

    return (
        <>
            <div className="candidate-page">
                {/* <PageTitle activeMenu="List" motherMenu="Candidates"/> */}
                {getSubscription?.limit?.interviews?.used > getSubscription?.limit?.interviews?.allowed ? <Alert
                    alert={true}
                    type={"warning"}
                    variant={"danger"}
                    msg={`Subscription limit reached. You have ${getSubscription?.limit?.interviews?.used - getSubscription?.limit?.interviews?.allowed} extra interviews.`}
                    dismissAlert={() => showAlert(false)}
                /> : ""}

                <Alert
                    alert={alert}
                    type={getMessage?.updated || getMessage?.delete ? "success" : getMessage?.error && "error"}
                    variant={getMessage?.updated || getMessage?.delete ? "success" : getMessage?.error && "danger"}
                    msg={getMessage?.updated || getMessage?.delete || getMessage?.error}
                    dismissAlert={() => showAlert(false)}
                />
                <Row className="">

                    <div className="col-8 d-none">
                        <h3>CANDIDATES</h3>
                    </div>
                    <div className="col-4 d-none">
                        <Card>
                            <CardBody className="py-2">
                                <Row className="align-items-center">
                                    {
                                        <>
                                            <Col lg={8}>
                                                {/* <div className="mb-2">Monthly Limit: Completed Candidates</div> */}
                                                <div className="mb-2 d-flex justify-content-between">
                                                    Monthly Limit{" "}
                                                    <h5 className="text-subtitle">
                                                        {getSubscription?.limit?.interviews?.used}/{getSubscription?.limit?.interviews?.allowed}
                                                    </h5>
                                                </div>
                                                <div className="mb-2">
                                                    <Progress
                                                        className="mb-2"
                                                        color="primary"
                                                        value={
                                                            getSubscription
                                                                ? (Number(getSubscription?.limit?.interviews?.used) /
                                                                    Number(getSubscription?.limit?.interviews?.allowed)) *
                                                                100
                                                                : 0
                                                        }
                                                    />
                                                </div>
                                                <div className="text-subtitle">
                                                    {/* <span><a target="_blank" href="https://digitalhire.com/upgrade/">Upgrade</a></span> */}
                                                    {/* <span className="float-right">{moment().format('MMM DD, YYYY')}</span> */}
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <span>
                                                <a target="_blank" className="btn btn-primary"
                                                   href="https://digitalhire.com/upgrade/">
                                                    Upgrade
                                                </a>
                                                </span>
                                                {/* <h5 className="text-subtitle">{getSubscription?.limit?.interviews?.used}/{getSubscription?.limit?.interviews?.allowed}</h5> */}
                                            </Col>
                                        </>
                                    }
                                </Row>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-12">
                        <CandidatesFilters 
                            fiterOptions={getFilterData} 
                            query={query} 
                            setQuery={setQuery}
                            setFilterData={props?.history?.location}
                        />

                    </div>
                </Row>

                <div className="card">

                    <div className="card-body">

                        <Table className="custom-table text-dark">
                            <thead>
                            <tr>
                                <th style={{width: "50px"}}></th>
                                <th style={{width: "50px"}}></th>
                                <th
                                    style={{overflow: "hidden", cursor: "pointer"}}
                                    onClick={() => {
                                        setSort((prev) => {
                                            return prev[0] === "first_name" && prev[1] === "ASC"
                                                ? ["first_name", "DESC"]
                                                : ["first_name", "ASC"]
                                        })
                                        setTrackSort(1)
                                    }}
                                >
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={props => renderTooltip(props, `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`)}>

                                        <Label style={{marginBottom: 0}}> Name </Label>
                                    </OverlayTrigger>
                                    {/* <span className="ml-1"> */}
                                    {trackSort === 1 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        <i className="bx bxs-sort-alt"/>
                                    )}
                                    {/* </span> */}
                                </th>


                                <th
                                    style={{overflow: "hidden", cursor: "pointer"}}
                                    onClick={() => {
                                        setSort((prev) => {
                                            return prev[0] === "status" && prev[1] === "ASC" ? ["status", "DESC"] : ["status", "ASC"]
                                        })
                                        setTrackSort(2)
                                    }}
                                >
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={props => renderTooltip(props, `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`)}>

                                        <Label style={{marginBottom: 0}}> Status </Label>
                                    </OverlayTrigger>
                                    {trackSort === 2 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        <i className="bx bxs-sort-alt"/>
                                    )}
                                </th>

                                <th
                                    style={{overflow: "hidden", cursor: "pointer"}}
                                    onClick={() => {
                                        setSort((prev) => {
                                            return prev[0] === "completion_date" && prev[1] === "ASC"
                                                ? ["completion_date", "DESC"]
                                                : ["completion_date", "ASC"]
                                        })
                                        setTrackSort(5)
                                    }}
                                >
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={props => renderTooltip(props, `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`)}>

                                        <Label style={{marginBottom: 0}}> Completion Date{" "} </Label>
                                    </OverlayTrigger>

                                    {trackSort === 5 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        <i className="bx bxs-sort-alt"/>
                                    )}
                                </th>
                                <th
                                    style={{overflow: "hidden", cursor: "pointer"}}
                                    onClick={() => {
                                        setSort((prev) => {
                                            return prev[0] === "progress" && prev[1] === "ASC" ? ["progress", "DESC"] : ["progress", "ASC"]
                                        })
                                        setTrackSort(4)
                                    }}
                                >
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={props => renderTooltip(props, `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`)}>

                                        <Label style={{marginBottom: 0}}> Interview Progress{" "} </Label>
                                    </OverlayTrigger>
                                    {trackSort === 4 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        <i className="bx bxs-sort-alt"/>
                                    )}
                                </th>


                                <th
                                    style={{overflow: "hidden", cursor: "pointer"}}
                                    onClick={() => {
                                        setSort((prev) => {
                                            return prev[0] === "createdAt" && prev[1] === "ASC"
                                                ? ["createdAt", "DESC"]
                                                : ["createdAt", "ASC"]
                                        })
                                        setTrackSort(3)
                                    }}
                                >
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={props => renderTooltip(props, `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`)}>

                                        <Label style={{marginBottom: 0}}> Date Added{" "} </Label>
                                    </OverlayTrigger>

                                    {trackSort === 3 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        <i className="bx bxs-sort-alt"/>
                                    )}
                                </th>
                                <th
                                    style={{overflow: "hidden", cursor: "pointer"}}
                                    onClick={() => {
                                        setSort((prev) => {
                                            return prev[0] === "ratings" && prev[1] === "ASC" ? ["ratings", "DESC"] : ["ratings", "ASC"]
                                        })
                                        setTrackSort(6)
                                    }}
                                >
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={props => renderTooltip(props, `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`)}>

                                        <Label style={{marginBottom: 0}}> Rating{" "} </Label>
                                    </OverlayTrigger>
                                    {trackSort === 6 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        <i className="bx bxs-sort-alt"/>
                                    )}
                                </th>
                                <th
                                    // style={{overflow: "hidden", cursor: "pointer"}}
                                    // onClick={() => {
                                    //     setSort((prev) => {
                                    //         return prev[0] === "Tag" && prev[1] === "ASC" ? ["Tag", "DESC"] : ["Tag", "ASC"]
                                    //     })
                                    //     setTrackSort(7)
                                    // }}
                                >
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={props => renderTooltip(props, `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`)}>

                                        <Label style={{marginBottom: 0}}> Tags{" "} </Label>
                                    </OverlayTrigger>
                                    {/* {trackSort === 7 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        ""
                                    )} */}
                                </th>
                                <th>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={props => renderTooltip(props, `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`)}>

                                        <Label style={{marginBottom: 0}}> Action </Label>
                                    </OverlayTrigger>
                                </th>
                            </tr>
                            </thead>

                            {loader ? (

                                <tbody>
                                <tr>
                                    <td style={{textAlignLast: "center"}} colSpan={9}>
                                        <Spinner size="sm" className="m-auto text-center" color="success"/>
                                    </td>
                                </tr>
                                </tbody>
                            ) : getCandidates && getCandidates.totalItems > 0 ? (
                                <CandidateList canData={getCandidates?.data} subData={getSubscription}
                                               showAlert={() => showAlert(true)}/>
                            ) : (
                                <tbody>
                                <tr>
                                    <td style={{textAlignLast: "center"}} colSpan={9}>
                                        No Data To Display
                                    </td>
                                </tr>
                                </tbody>
                            )}
                        </Table>
                    </div>
                </div>
                <Row className="justify-content-center">
                    {/* <PaginationComponent pages={1113} page={0} setPage={(page) => submitFilter(page)} count={getPositions?.totalItems} /> */}
                    {!loader && (
                        <PaginationComponent
                            pages={getCandidates?.totalPages}
                            page={getCandidates?.currentPage}
                            setPage={(page) => onSubmit(page)}
                            count={getCandidates?.totalItems}
                        />
                    )}
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        getCandidates: getCompanyCandidates(state),
        getSubscription: getCompanySubscription(state),
        getMessage: getMeiaAddedMessage(state),
        getFilterData: getCompanyCandidateFilterDataSelector(state)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        loadCompanyCandidates: (data, props, cb) => dispatch(getCompanyCandidatesAction(data, props.history, cb)),
        getCompanyTags: (data, props, cb) => dispatch(getCompanyTagsAction(data, props.history, cb)),
        getCompanySubscription: () => dispatch(getCompanySubscriptionAction()),
        getCompanyCandidateFilterData: () => dispatch(getCompanyCandidateFilterDataAction())
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Candidate))
