import React from "react"

import { Row, Col, Card, CardBody, Spinner } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "./../../../images/default.svg"
import PhoneNumber from "../Common/phoneNumber"

const ProfileData = ({ setProfileModal, evaluation, loading }) => {
  return (
    <React.Fragment>
      <div className="profile-data">
        <Card className="overflow-hidden" style={{ height: "auto" }}>
          <div className="bg-secondary top-section">
            <div className="text-center text-white">
              <h5 className="text-white">
                {evaluation?.Candidate?.first_name} {evaluation?.Candidate?.last_name}
              </h5>
              {/*<p>{evaluation?.Candidate?.email}</p>*/}
              <img
                src={evaluation?.Candidate?.image || avatar1}
                alt="user image"
                className="img-thumbnail rounded-circle profile-image"
              />
            </div>
          </div>

          <CardBody className="">
            <Row className="align-items-center">
              <Col sm="">
                <div className="">
                  <span className="text-subtitle mb-2">
                    <i className="bx bxs-graduation"></i> &nbsp;
                    {evaluation?.Position?.title}
                  </span>
                  <span className="d-none mb-1">
                    <i className="bx bx-mail-send"></i>
                  </span>
                  <span className="d-block mb-1">
                    <i className="bx bx-phone-call"></i> &nbsp;
                    <PhoneNumber number={evaluation?.Candidate?.phone} />
                  </span>
                  <span className="d-block mb-1">
                    <i className="bx bxs-envelope"></i> &nbsp;
                    {evaluation?.Candidate?.email}
                  </span>
                  {/* <button
                    className="d-block btn btn-outline-secondary mt-2"
                    onClick={() => setProfileModal(true)}
                  >
                    View Profile
                  </button> */}
                  <a
                    className="d-block btn btn-outline-secondary mt-2"
                    href={evaluation?.Candidate?.CandidateProfile?.pdf_resume}
                    target={`_blank`}
                    // onClick={() => setProfileModal(true)}
                  >
                    PDF resume
                  </a>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default ProfileData
