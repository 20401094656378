import React, { useContext, useEffect } from "react"

/// React router dom
import { Switch, Route } from "react-router-dom"
import { useLocation } from "react-router";

/// Css
import "./index.css"
import "./chart.css"
import "./step.css"

/// Layout
import Nav from "./layouts/nav"
import Footer from "./layouts/Footer"
/// Dashboard
import Home from "./components/Dashboard/Home"
import Position from "./components/Position"
import PositionDetail from "./components/Position/PositionDetail"
import PositionCreate from "./components/Position/PositionCreate"
import PositionEdit from "./components/Position/PositionEdit"
import Media from "./components/Media"
import Candidate from "./components/Candidate"
import Evaluation from "./components/Evaluation"
import EvaluationDetails from "./components/Evaluation/EvaluationDetails"
import Account from "./components/Account"
import Company from "./components/Company"
import Member from "./components/Member"
import Payment from "./components/Payment"
import Subscription from "./components/Subscription"
import Checkout from "./components/Checkout"
import Report from "./components/Report"
//Redux
// import Todo from "./pages/Todo";

/// Pages
import Registration from "./pages/Registration"
import Login from "./pages/Login"
import ForgotPassword from "./pages/ForgotPassword"
import LockScreen from "./pages/LockScreen"
import Error400 from "./pages/Error400"
import Error403 from "./pages/Error403"
import Error404 from "./pages/Error404"
import Error500 from "./pages/Error500"
import Error503 from "./pages/Error503"
import { ThemeContext } from "../context/ThemeContext"

const Markup = (props) => {
  const { permissions } = props
  const { menuToggle } = useContext(ThemeContext)
  
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "home", component: Home },
    { url: "position", component: Position },
    { url: "position/add", component: PositionCreate },
    { url: "position/edit/:id", component: PositionEdit },
    { url: "position/detail/:id", component: PositionDetail },
    { url: "media", component: Media },
    { url: "candidate", component: Candidate },
    { url: "evaluation", component: Evaluation },
    { url: "evaluation/:id", component: EvaluationDetails },
    { url: "account", component: Account },
    { url: "company-profile", component: Company },
    { url: "team-members", component: Member },
    // {... permissions && permissions?.includes("view_payments") ? [{url: "payment", component: Payment}] : []},
    // {... permissions && permissions?.includes("view_subscription") ? [{url: "subscriptions", component: Subscription}] : []},
    // {... permissions && permissions?.includes("view_payments") ? [{url: "checkout/:subscription_id", component: Checkout}] : []},

    { url: "payment", component: Payment },
    // { url: "subscriptions", component: Subscription },
    // { url: "checkout/:subscription_id", component: Checkout },
    { url: "report", component: Report },

    /// pages
    { url: "page-register", component: Registration },
    { url: "page-lock-screen", component: LockScreen },
    { url: "page-login", component: Login },
    { url: "page-forgot-password", component: ForgotPassword },
    { url: "page-error-400", component: Error400 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },
  ]
  let path = window.location.pathname
  path = path.split("/")
  path = path[path.length - 1]

  let pagePath = path.split("-").includes("page")
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${menuToggle ? "menu-toggle" : ""}`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div className={`${!pagePath ? "container-fluid" : ""}`} style={{ minHeight: window.screen.height - 60 }}>
            <Switch>
              {routes.map((data, i) => (
                <Route key={i} exact path={`/${data.url}`} component={data.component} />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
    </>
  )
}

export default Markup
