import {formatError} from "../../services/FormatError"
import {
    registerCompanyService,
    getCompanyService,
    updateCompanyService,
    getCompanyCountriesService,
    getCompanyIndustriesService,
    addCompanyMemberService,
    editCompanyMemberStatusService,
    editCompanyMemberRoleService,
    addCompanyMediaService,
    getCompanyMediaService,
    deleteCompanyMediaService,
    addCompanyPositionService,
    addCompanyPositionQuestionService,
    getCompanyPositionService,
    getCompanyPositionsService,
    deleteCompanyPositionService,
    updateCompanyPositionService,
    getCompanySubscriptionService,
    getCompanyCandidatesService,
    updateCompanyPositionQuestionService,
    updateCompanyPositionQuestionSeqService,
    deleteCompanyPositionQuestionService,
    getCompanyPositionQuestionsService,
    addCompanyPositionTeamMemberService,
    removeCompanyPositionTeamMemberService,
    addCompanyPositionReminderService,
    removeCompanyPositionReminderService,
    companyInviteCandidateService,
    addCompanyInterviewTagService,
    deleteCompanyCandidateService,
    resetCompanyCandidateInterviewService,
    addCompanyTagService,
    resetCompanyCandidateService,
    sendCompanyCandidateReminderService,
    getCompanyTagsService,
    addCompanyCandidateTeamMemberService,
    removeCompanyCandidateTeamMemberService,
    getCompanyCandidatesEvaluationsService,
    getCompanyCandidateFilterDataService,
    deleteCompanyTagService,
    getCompanyCandidateEvaluationDetailService,
    removeCompanyInterviewTagService,
    markCompanyCandidateEvaluationService,
    addCompanyCandidateNoteService,
    deleteCompanyCandidateNoteService,
    getCompanyDashboardService,
    companyPositionDuplicationService,
    getCompanyPaymentsService,
    getCompanySubscriptionPlansService,
    getCompanyPositionsList,
    getCompanyDashboardInterviewGraph,
    getCompanyDashboardEvaluationGraph,
    markInterviewHireService,
} from "../../services/CompanyService"
import {
    REGISTER_COMPANY_CONFIRMATION,
    REGISTER_COMPANY_FAILED,
    GET_COMPANY_FAILED,
    GET_COMPANY_CONFIRMATION,
    UPDATE_COMPANY_CONFIRMATION,
    UPDATE_COMPANY_FAILED,
    ADD_COMPANY_MEMBER_COMPLETED,
    ADD_COMPANY_MEMBER_FAILED,
    GET_COMPANY_COUNTRIES_COMPLETED,
    GET_COMPANY_INDUSTRIES_COMPLETED,
    GET_COMPANY_COUNTRIES_FAILED,
    GET_COMPANY_INDUSTRIES_FAILED,
    EDIT_COMPANY_MEMBER_STATUS_COMPLETED,
    EDIT_COMPANY_MEMBER_STATUS_FAILED,
    EDIT_COMPANY_MEMBER_ROLE_COMPLETED,
    EDIT_COMPANY_MEMBER_ROLE_FAILED,
    ADD_COMPANY_MEDIA_COMPLETED,
    ADD_COMPANY_MEDIA_FAILED,
    GET_COMPANY_MEDIA_FAILED,
    GET_COMPANY_MEDIA_COMPLETED,
    DELETE_COMPANY_MEDIA_FAILED,
    DELETE_COMPANY_MEDIA_COMPLETED,
    ADD_COMPANY_POSITION_FAILED,
    ADD_COMPANY_POSITION_COMPLETED,
    ADD_COMPANY_POSITION_QUESTION_FAILED,
    ADD_COMPANY_POSITION_QUESTION_COMPLETED,
    GET_COMPANY_POSITION_COMPLETED,
    GET_COMPANY_POSITION_FAILED,
    GET_COMPANY_POSITIONS_FAILED,
    GET_COMPANY_POSITIONS_COMPLETED,
    DELETE_COMPANY_POSITION_COMPLETED,
    DELETE_COMPANY_POSITION_FAILED,
    UPDATE_COMPANY_POSITION_COMPLETED,
    UPDATE_COMPANY_POSITION_FAILED,
    GET_COMPANY_SUBSCRIPTION_FAILED,
    GET_COMPANY_SUBSCRIPTION_COMPLETED,
    GET_COMPANY_CANDIDATES_FAILED,
    GET_COMPANY_CANDIDATES_COMPLETED,
    UPDATE_COMPANY_POSITION_QUESTION_FAILED,
    UPDATE_COMPANY_POSITION_QUESTION_COMPLETED,
    UPDATE_COMPANY_POSITION_QUESTION_SEQ_FAILED,
    UPDATE_COMPANY_POSITION_QUESTION_SEQ_COMPLETED,
    DELETE_COMPANY_POSITION_QUESTION_FAILED,
    DELETE_COMPANY_POSITION_QUESTION_COMPLETED,
    GET_COMPANY_POSITION_QUESTIONS_FAILED,
    GET_COMPANY_POSITION_QUESTIONS_COMPLETED,
    ADD_COMPANY_POSITION_TEAM_MEMBER_FAILED,
    ADD_COMPANY_POSITION_TEAM_MEMBER_COMPLETED,
    REMOVE_COMPANY_POSITION_TEAM_MEMBER_COMPLETED,
    REMOVE_COMPANY_POSITION_TEAM_MEMBER_FAILED,
    ADD_COMPANY_POSITION_REMINDER_FAILED,
    ADD_COMPANY_POSITION_REMINDER_COMPLETED,
    REMOVE_COMPANY_POSITION_REMINDER_FAILED,
    REMOVE_COMPANY_POSITION_REMINDER_COMPLETED,
    COMPANY_INVITE_CANDIDATE_COMPLETED,
    COMPANY_INVITE_CANDIDATE_FAILED,
    ADD_COMPANY_TAG_COMPLETED,
    ADD_COMPANY_TAG_FAILED,
    ADD_COMPANY_INTERVIEW_TAG_COMPLETED,
    ADD_COMPANY_INTERVIEW_TAG_FAILED,
    DELETE_COMPANY_INTERVIEW_FAILED,
    DELETE_COMPANY_INTERVIEW_COMPLETED,
    RESET_COMPANY_INTERVIEW_COMPLETED,
    RESET_COMPANY_INTERVIEW_FAILED,
    SENT_COMPANY_INTERVIEW_REMINDER_COMPLETED,
    SENT_COMPANY_INTERVIEW_REMINDER_FAILED,
    GET_COMPANY_TAGS_COMPLETED,
    GET_COMPANY_TAGS_FAILED,
    ADD_COMPANY_INTERVIEW_TEAM_MEMBER_FAILED,
    ADD_COMPANY_INTERVIEW_TEAM_MEMBER_COMPLETED,
    REMOVE_COMPANY_INTERVIEW_TEAM_MEMBER_COMPLETED,
    REMOVED_COMPANY_INTERVIEW_TEAM_MEMBER_COMPLETED,
    GET_COMPANY_CANDIDATES_EVALUATIONS_COMPLETED,
    GET_COMPANY_CANDIDATES_EVALUATIONS_FAILED,
    GET_COMPANY_CANDIDATE_FILTER_DATA_COMPLETED,
    GET_COMPANY_CANDIDATE_FILTER_DATA_FAILED,
    DELETE_COMPANY_TAG_FAILED,
    DELETE_COMPANY_TAG_COMPLETED,
    GET_COMPANY_CANDIDATE_EVALUATION_DETAIL_COMPLETED,
    GET_COMPANY_CANDIDATE_EVALUATION_DETAIL_FAILED,
    DELETE_COMPANY_INTERVIEW_TAG_FAILED,
    DELETE_COMPANY_INTERVIEW_TAG_COMPLETED,
    MARK_COMPANY_CANDIDATE_EVALUATION_COMPLETED,
    MARK_COMPANY_CANDIDATE_EVALUATION_FAILED,
    ADD_COMPANY_CANDIDATE_NOTE_COMPLETED,
    ADD_COMPANY_CANDIDATE_NOTE_FAILED,
    DELETE_COMPANY_CANDIDATE_NOTE_COMPLETED,
    DELETE_COMPANY_CANDIDATE_NOTE_FAILED,
    GET_COMPANY_DASHBOARD_DATA_FAILED,
    GET_COMPANY_DASHBOARD_DATA_COMPLETED,
    GET_COMPANY_DASHBOARD_DATA_REQUEST,
    UPDATE_COMPANY_POSITION_REQUEST,
    COMPANY_POSITION_DUPLICATION_COMPLETED,
    COMPANY_POSITION_DUPLICATION_FAILED,
    GET_COMPANY_PAYMENTS_DATA_COMPLETED,
    GET_COMPANY_PAYMENTS_DATA_FAILED,
    GET_COMPANY_SUBSCRIPTION_PLANS_DATA_COMPLETED,
    GET_COMPANY_SUBSCRIPTION_PLANS_DATA_FAILED,
    GET_COMPANY_DASHBOARD_POSITIONS_DATA_COMPLETED,
    GET_COMPANY_DASHBOARD_POSITIONS_DATA_REQUEST,
    GET_COMPANY_DASHBOARD_POSITIONS_DATA_FAILED,
    GET_COMPANY_DASHBOARD_INTERVIEWGRAPH_COMPLETED,
    GET_COMPANY_DASHBOARD_INTERVIEWGRAPH_FAILED,
    GET_COMPANY_DASHBOARD_INTERVIEWGRAPH_REQUEST,
    GET_COMPANY_DASHBOARD_EVALUATIONGRAPH_COMPLETED,
    GET_COMPANY_DASHBOARD_EVALUATIONGRAPH_FAILED,
    GET_COMPANY_DASHBOARD_EVALUATIONGRAPH_REQUEST,
    RESET_PORTAL_MESSAGE,
} from "./constants/Company"
import {companyProfileConfirmationAction} from "./AuthActions"
import {saveTokenInLocalStorage} from "../../services/AuthService"

export function registerCompanyAction(data, history, cb = null) {
    return (dispatch) => {
        registerCompanyService(data)
            .then((response) => {
                console.log("Company Registered")
                data = response.data.data
                console.log(data)
                dispatch(registerCompanyCompletedAction(data))
                dispatch(companyProfileConfirmationAction())
                const d = JSON.parse(localStorage.getItem("userDetails"))
                d.company_profile = true
                saveTokenInLocalStorage(d)
                if (cb) cb()
                history.push("/home")
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(registerCompanyFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function getCompanyDashboardAction(cb = null) {
    return (dispatch) => {
        dispatch(getCompanyDashboardRequestAction())
        getCompanyDashboardService()
            .then((response) => {
                console.log("Company Dashboard")
                const data = response.data.data.Dashboard
                console.log(data)
                dispatch(getCompanyDashboardCompletedAction(data))
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error?.response) {
                    errorMessage = formatError(error?.response?.data)
                } else {
                    errorMessage = error?.message
                }
                dispatch(getCompanyDashboardFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function getCompanyDashboardPositionsAction(data, history, cb = null) {
    return (dispatch) => {
        dispatch(getCompanyDashboardPositionsRequestAction())
        getCompanyPositionsList(data)
            .then((response) => {
                const data = response.data.Positions
                console.log(data, "DASH POSITINS")
                dispatch(getCompanyDashboardPositionsCompletedAction(data))
                // if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(getCompanyDashboardPositionsFailedAction(errorMessage))
                // if (cb) cb()
            })
    }
}

export function getCompanyDashboardInterviewAction(data = {}, cb = null) {
    return (dispatch) => {
        dispatch(getCompanyDashboardInterviewRequestAction())
        getCompanyDashboardInterviewGraph(data)
            .then((response) => {
                const data = response.data.InterviewGraph
                console.log(data, "DASH Interview")
                dispatch(getCompanyDashboardInterviewCompletedAction(data))
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(getCompanyDashboardInterviewFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function getCompanyDashboardEvaluationAction(data = {}, cb = null) {
    return (dispatch) => {
        dispatch(getCompanyDashboardEvaluationRequestAction())
        getCompanyDashboardEvaluationGraph(data)
            .then((response) => {
                const data = response.data.EvaluationGraph
                console.log(data, "DASH EvaluationGraph")
                dispatch(getCompanyDashboardEvaluationCompletedAction(data))
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(getCompanyDashboardEvaluationFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function getCompanyAction(cb) {
    return (dispatch) => {
        getCompanyService()
            .then((response) => {
                const data = response.data.data.Company
                dispatch(getCompanyCompletedAction(data))
                dispatch(getCompanyCountriesAction())
                dispatch(getCompanyIndustriesAction())
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(getCompanyFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function updateCompanyAction(data, history, cb = null) {
    return (dispatch) => {
        dispatch(updateCompanyProfileLoading())
        updateCompanyService(data)
            .then((response) => {
                // console.log(response)
                data = response.data.data.Company
                dispatch(updateCompanyCompletedAction(data))
                // dispatch(getCompanyCountriesAction())
                // dispatch(getCompanyIndustriesAction())
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(updateCompanyFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function editCompanyMemberStatusAction(data, history, cb = null) {
    return (dispatch) => {
        console.log(data)
        editCompanyMemberStatusService(data)
            .then((response) => {
                dispatch(editCompanyMemberStatusCompletedAction())
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(editCompanyMemberStatusFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function editCompanyMemberRoleAction(data, history, cb = null) {
    return (dispatch) => {
        editCompanyMemberRoleService(data)
            .then((response) => {
                dispatch(editCompanyMemberRoleCompletedAction())
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(editCompanyMemberRoleFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function addCompanyMediaAction(data, history, cb = null) {
    return (dispatch) => {
        console.log(data)
        addCompanyMediaService(data)
            .then((response) => {
                dispatch(addCompanyMediaCompletedAction())
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(addCompanyMediaFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function addCompanyPositionAction(data, history, cb = null) {
    return (dispatch) => {
        console.log(data)
        addCompanyPositionService(data)
            .then((response) => {
                data = response.data.data.Position
                dispatch(addCompanyPositionCompletedAction(data))
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(addCompanyPositionFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function companyPositionDuplicationAction(data, history, cb = null) {
    return (dispatch) => {
        console.log(data)
        companyPositionDuplicationService(data)
            .then((response) => {
                // data = response.data.data.Position
                dispatch(companyPositionDuplicationCompletedAction())
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(companyPositionDuplicationFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function addCompanyPositionReminderAction(data, history, cb = null) {
    return (dispatch) => {
        console.log(data)
        addCompanyPositionReminderService(data)
            .then((response) => {
                // data = response.data.data.Position
                dispatch(addCompanyPositionReminderCompletedAction())
                if (cb) cb(true)
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(addCompanyPositionReminderFailedAction(errorMessage))
                if (cb) cb(false)
            })
    }
}

export function removeCompanyPositionReminderAction(data, history, cb = null) {
    return (dispatch) => {
        console.log(data)
        removeCompanyPositionReminderService(data)
            .then((response) => {
                // data = response.data.data.Position
                dispatch(removeCompanyPositionReminderCompletedAction())
                if (cb) cb(true)
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(removeCompanyPositionReminderFailedAction(errorMessage))
                if (cb) cb(false)
            })
    }
}

/** Company Positions Team Members*/

export function addCompanyPositionTeamMemberAction(data, history, cb = null) {
    return (dispatch) => {
        console.log(data)
        addCompanyPositionTeamMemberService(data)
            .then((response) => {
                // data = response.data.data.Position
                dispatch(addCompanyPositionTeamMemberCompletedAction())
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(addCompanyPositionTeamMemberFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function removeCompanyPositionTeamMemberAction(data, history, cb = null) {
    return (dispatch) => {
        console.log(data)
        removeCompanyPositionTeamMemberService(data)
            .then((response) => {
                // data = response.data.data.Position
                dispatch(removeCompanyPositionTeamMemberCompletedAction())
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(removeCompanyPositionTeamMemberFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

/** Company Position Questions */
export function addCompanyPositionQuestionAction(data, history, cb = null) {
    return (dispatch) => {
        console.log(data)
        addCompanyPositionQuestionService(data)
            .then((response) => {
                data = response.data.data.Question
                console.log(response)
                dispatch(addCompanyPositionQuestionCompletedAction(data))
                if (cb) cb()
            })
            .catch((error) => {
                console.log(error)
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(addCompanyPositionQuestionFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function getCompanyPositionQuestionsAction(data, history, cb = null) {
    return (dispatch) => {
        console.log(data)
        getCompanyPositionQuestionsService(data)
            .then((response) => {
                data = response.data.data.Questions
                console.log(response)
                dispatch(getCompanyPositionQuestionsCompletedAction(data))
                if (cb) cb()
            })
            .catch((error) => {
                console.log(error)
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(getCompanyPositionQuestionsFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function updateCompanyPositionQuestionAction(data, history, cb = null) {
    return (dispatch) => {
        console.log(data)
        updateCompanyPositionQuestionService(data)
            .then((response) => {
                data = response.data.data.Question
                dispatch(updateCompanyPositionQuestionCompletedAction(data))
                if (cb) cb()
            })
            .catch((error) => {
                console.log(error)
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(updateCompanyPositionQuestionFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function updateCompanyPositionQuestionSeqAction(data, history, cb = null) {
    return (dispatch) => {
        console.log(data)
        updateCompanyPositionQuestionSeqService(data)
            .then((response) => {
                // data = response.data.data.Question
                dispatch(updateCompanyPositionQuestionSeqCompletedAction(data))
                if (cb) cb()
            })
            .catch((error) => {
                console.log(error)
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(updateCompanyPositionQuestionSeqFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function deleteCompanyPositionQuestionAction(data, history, cb = null) {
    return (dispatch) => {
        console.log(data)
        deleteCompanyPositionQuestionService(data)
            .then((response) => {
                // data = response.data.data.Question
                dispatch(deleteCompanyPositionQuestionCompletedAction())
                if (cb) cb()
            })
            .catch((error) => {
                console.log(error)
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(deleteCompanyPositionQuestionFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function getCompanyPositionAction(data, history, cb = null) {
    return (dispatch) => {
        console.log(data)
        getCompanyPositionService(data)
            .then((response) => {
                data = response.data.data.Position
                console.log(response)
                dispatch(getCompanyPositionCompletedAction(data))
                if (cb) cb()
            })
            .catch((error) => {
                console.log(error)
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(getCompanyPositionFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function updateCompanyPositionAction(data, history, cb = null) {
    return (dispatch) => {
        console.log(data)
        updateCompanyPositionService(data)
            .then((response) => {
                data = response.data.data.Position
                console.log(response)
                dispatch(updateCompanyPositionCompletedAction(data))
                if (cb) cb()
            })
            .catch((error) => {
                console.log(error)
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(updateCompanyPositionFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function deleteCompanyPositionAction(data, history, cb = null) {
    return (dispatch) => {
        console.log(data)
        deleteCompanyPositionService(data)
            .then((response) => {
                // data = response.data.data.Position
                // console.log(response)
                dispatch(deleteCompanyPositionCompletedAction())
                if (cb) cb()
            })
            .catch((error) => {
                console.log(error)
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(deleteCompanyPositionFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function getCompanyPositionsAction(data, history, cb = null) {
    return (dispatch) => {
        console.log(data)
        getCompanyPositionsService(data)
            .then((response) => {
                data = response.data.data.Positions
                data.data = data.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : b.createdAt < a.createdAt ? -1 : 0))
                console.log(response)
                dispatch(getCompanyPositionsCompletedAction(data))
                if (cb) cb()
            })
            .catch((error) => {
                console.log(error)
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(getCompanyPositionsFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function getCompanyMediaAction(data, history, cb = null) {
    return (dispatch) => {
        getCompanyMediaService(data)
            .then((response) => {
                data = response.data.data.Media
                dispatch(getCompanyMediaCompletedAction(data))
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(getCompanyMediaFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function deleteCompanyMediaAction(data, history, cb = null) {
    return (dispatch) => {
        console.log(data)
        deleteCompanyMediaService(data)
            .then((response) => {
                dispatch(deleteCompanyMediaCompletedAction())
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(deleteCompanyMediaFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function addCompanyMemberAction(data, history, cb = null) {
    return (dispatch) => {
        addCompanyMemberService(data)
            .then((response) => {
                // const data = response.data.data.Company
                // dispatch(getCompanyCompletedAction(data))
                dispatch(addCompanyMemberCompletedAction())
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(addCompanyMemberFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function getCompanyCountriesAction() {
    return (dispatch) => {
        getCompanyCountriesService()
            .then((response) => {
                const data = response.data.data
                dispatch(getCompanyCountriesCompletedAction(data))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(getCompanyCountriesFailedAction(errorMessage))
            })
    }
}

export function getCompanyIndustriesAction() {
    return (dispatch) => {
        getCompanyIndustriesService()
            .then((response) => {
                const data = response.data.data
                dispatch(getCompanyIndustriesCompletedAction(data))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(getCompanyIndustriesFailedAction(errorMessage))
            })
    }
}

/** Candidates */
// TODO:: Candidate Apis
export function getCompanyCandidatesAction(data, history, cb) {
    return (dispatch) => {
        getCompanyCandidatesService(data)
            .then((response) => {
                const data = response.data.data.Interviews
                console.log("getCompanyCandidatesAction", data)
                dispatch(getCompanyCandidatesCompletedAction(data))
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(getCompanyCandidatesFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function getCompanyCandidateFilterDataAction(data, history, cb) {
    return (dispatch) => {
        getCompanyCandidateFilterDataService(data)
            .then((response) => {
                const data = response.data.data.FilterData
                dispatch(getCompanyCandidateFilterDataCompletedAction(data))
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(getCompanyCandidateFilterDataFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function getCompanyCandidatesEvaluationsAction(data, history, cb) {
    return (dispatch) => {
        getCompanyCandidatesEvaluationsService(data)
            .then((response) => {
                const data = response.data.data.Interviews
                dispatch(getCompanyCandidatesEvaluationsCompletedAction(data))
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(getCompanyCandidatesEvaluationsFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function getCompanyCandidateEvaluationDetailAction(data, history, cb) {
    return (dispatch) => {
        getCompanyCandidateEvaluationDetailService(data)
            .then((response) => {
                const data = response.data.data.Interview
                dispatch(getCompanyCandidateEvaluationDetailCompletedAction(data))
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(getCompanyCandidateEvaluationDetailFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function markCompanyCandidateEvaluationAction(data, history, cb) {
    return (dispatch) => {
        markCompanyCandidateEvaluationService(data)
            .then((response) => {
                // const data = response.data.data.Interview
                dispatch(markCompanyCandidateEvaluationCompletedAction())
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(markCompanyCandidateEvaluationFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function addCompanyCandidateNoteAction(data, history, cb) {
    return (dispatch) => {
        addCompanyCandidateNoteService(data)
            .then((response) => {
                // const data = response.data.data.Interview
                dispatch(addCompanyCandidateNoteCompletedAction())
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(addCompanyCandidateNoteFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function deleteCompanyCandidateNoteAction(data, history, cb) {
    return (dispatch) => {
        deleteCompanyCandidateNoteService(data)
            .then((response) => {
                // const data = response.data.data.Interview
                dispatch(deleteCompanyCandidateNoteCompletedAction())
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(deleteCompanyCandidateNoteFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function deleteCompanyCandidateAction(data, history, cb) {
    return (dispatch) => {
        deleteCompanyCandidateService(data)
            .then((response) => {
                // const data = response.data.data.Interviews
                dispatch(deleteCompanyCandidateCompletedAction())
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(deleteCompanyCandidateFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function resetCompanyCandidateAction(data, history, cb) {
    return (dispatch) => {
        resetCompanyCandidateService(data)
            .then((response) => {
                // const data = response.data.data.Interviews
                dispatch(resetCompanyCandidateCompletedAction())
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(resetCompanyCandidateFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function addCompanyCandidateTeamMemberAction(data, history, cb) {
    return (dispatch) => {
        addCompanyCandidateTeamMemberService(data)
            .then((response) => {
                // const data = response.data.data.Interviews
                if (response?.status === 202) {
                    dispatch(removedCompanyCandidateTeamMemberCompletedAction())
                    if (cb) cb(false)
                } else {
                    dispatch(addCompanyCandidateTeamMemberCompletedAction())
                    if (cb) cb(true)
                }
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(addCompanyCandidateTeamMemberFailedAction(errorMessage))
                if (cb) cb(false)
            })
    }
}

export function removeCompanyCandidateTeamMemberAction(data, history, cb) {
    return (dispatch) => {
        removeCompanyCandidateTeamMemberService(data)
            .then((response) => {
                // const data = response.data.data.Interviews
                dispatch(removeCompanyCandidateTeamMemberCompletedAction())
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(removeCompanyCandidateTeamMemberFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function removeCompanyCandidateTagAction(data, history, cb) {
    return (dispatch) => {
        removeCompanyInterviewTagService(data)
            .then((response) => {
                // const data = response.data.data.Interviews
                dispatch(removeCompanyCandidateTagCompletedAction())
                if (cb) cb(true)
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(removeCompanyCandidateTagFailedAction(errorMessage))
                if (cb) cb(false)
            })
    }
}

export function sendCompanyCandidateReminderAction(data, history, cb) {
    return (dispatch) => {
        sendCompanyCandidateReminderService(data)
            .then((response) => {
                // const data = response.data.data.Interviews
                dispatch(sendCompanyCandidateReminderCompletedAction())
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(sendCompanyCandidateReminderFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

export function companyInviteCandidateAction(data, history, cb) {
    return (dispatch) => {
        companyInviteCandidateService(data)
            .then((response) => {
                // const data = response.data.data.Interviews
                dispatch(companyInviteCandidateCompletedAction())
                if (cb) cb(true)
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(companyInviteCandidateFailedAction(errorMessage))
                if (cb) cb(false)
            })
    }
}

// TODO:: END Candidate Apis
/** Subscription */

export function getCompanySubscriptionAction() {
    return (dispatch) => {
        getCompanySubscriptionService()
            .then((response) => {
                const data = response.data.data.Subscription
                dispatch(getCompanySubscriptionCompletedAction(data))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(getCompanySubscriptionFailedAction(errorMessage))
            })
    }
}

export function getCompanySubscriptionPlansAction(cb = null) {
    return (dispatch) => {
        getCompanySubscriptionPlansService()
            .then((response) => {
                const data = response.data.data.Subscriptions
                dispatch(getCompanySubscriptionPlansCompletedAction(data))
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(getCompanySubscriptionPlansFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

/** Tags */
// TODO:: Company tags Apis
export function addCompanyTagAction(data, history, cb) {
    return (dispatch) => {
        addCompanyTagService(data)
            .then((response) => {
                // const data = response.data.data.Tag
                dispatch(addCompanyTagCompletedAction())
                if (cb) cb()
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(addCompanyTagFailedAction(errorMessage))
                if (cb) cb()
            })
    }
}

// TODO:: Delete Company Tag Integrated
export function deleteCompanyTagAction(data, history, cb) {
    return (dispatch) => {
        deleteCompanyTagService(data)
            .then((response) => {
                // const data = response.data.data.Tag
                dispatch(deleteCompanyTagCompletedAction())
                if (cb) cb(true)
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(deleteCompanyTagFailedAction(errorMessage))
                if (cb) cb(false)
            })
    }
}

export function getCompanyTagsAction(data, history, cb) {
    return (dispatch) => {
        getCompanyTagsService(data)
            .then((response) => {
                const data = response.data.data.Tags
                console.log(data)
                dispatch(getCompanyTagsCompletedAction(data))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(getCompanyTagsFailedAction(errorMessage))
            })
    }
}

export function getCompanyPaymentsAction(data, history, cb) {
    return (dispatch) => {
        getCompanyPaymentsService(data)
            .then((response) => {
                const data = response.data.data.Payments
                dispatch(getCompanyPaymentsCompletedAction(data))
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(getCompanyPaymentsFailedAction(errorMessage))
            })
    }
}

export function addCompanyInterviewTagAction(data, history, cb) {
    return (dispatch) => {
        addCompanyInterviewTagService(data)
            .then((response) => {
                // const data = response.data.data.Interviews
                dispatch(addCompanyInterviewTagCompletedAction())

                if (cb) cb(true)
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(addCompanyInterviewTagFailedAction(errorMessage))

                if (cb) cb(false)
            })
    }
}

export function markInterviewHireAction(data, history, cb) {
    return (dispatch) => {
        markInterviewHireService(data)
            .then((response) => {
                // const data = response.data.data.Interviews
                dispatch(markInterviewHireCompletedAction())

                if (cb) cb(true)
            })
            .catch((error) => {
                let errorMessage
                if (error.response) {
                    errorMessage = formatError(error.response.data)
                } else {
                    errorMessage = error.message
                }
                dispatch(markInterviewHireFailedAction(errorMessage))

                if (cb) cb(false)
            })
    }
}

// TODO:: End Company tags Apis

/** Reducer Actions */
export function registerCompanyCompletedAction(payload) {
    return {
        type: REGISTER_COMPANY_CONFIRMATION,
        payload,
    }
}

export function registerCompanyFailedAction(payload) {
    return {
        type: REGISTER_COMPANY_FAILED,
        payload,
    }
}

export function getCompanyCompletedAction(payload) {
    return {
        type: GET_COMPANY_CONFIRMATION,
        payload,
    }
}

export function getCompanyFailedAction(payload) {
    return {
        type: GET_COMPANY_FAILED,
        payload,
    }
}

export function updateCompanyFailedAction(payload) {
    return {
        type: UPDATE_COMPANY_FAILED,
        payload,
    }
}

export function updateCompanyCompletedAction(payload) {
    return {
        type: UPDATE_COMPANY_CONFIRMATION,
        payload,
    }
}

export function getCompanyCountriesCompletedAction(payload) {
    return {
        type: GET_COMPANY_COUNTRIES_COMPLETED,
        payload,
    }
}

export function getCompanyIndustriesCompletedAction(payload) {
    return {
        type: GET_COMPANY_INDUSTRIES_COMPLETED,
        payload,
    }
}

export function getCompanyCountriesFailedAction(payload) {
    return {
        type: GET_COMPANY_COUNTRIES_FAILED,
        payload,
    }
}

export function getCompanyIndustriesFailedAction(payload) {
    return {
        type: GET_COMPANY_INDUSTRIES_FAILED,
        payload,
    }
}

export function addCompanyMemberFailedAction(payload) {
    return {
        type: ADD_COMPANY_MEMBER_FAILED,
        payload,
    }
}

export function addCompanyMemberCompletedAction(payload) {
    return {
        type: ADD_COMPANY_MEMBER_COMPLETED,
        payload,
    }
}

export function editCompanyMemberStatusCompletedAction(payload) {
    return {
        type: EDIT_COMPANY_MEMBER_STATUS_COMPLETED,
        payload,
    }
}

export function editCompanyMemberStatusFailedAction(payload) {
    return {
        type: EDIT_COMPANY_MEMBER_STATUS_FAILED,
        payload,
    }
}

export function editCompanyMemberRoleCompletedAction(payload) {
    return {
        type: EDIT_COMPANY_MEMBER_ROLE_COMPLETED,
        payload,
    }
}

export function editCompanyMemberRoleFailedAction(payload) {
    return {
        type: EDIT_COMPANY_MEMBER_ROLE_FAILED,
        payload,
    }
}

export function addCompanyMediaFailedAction(payload) {
    return {
        type: ADD_COMPANY_MEDIA_FAILED,
        payload,
    }
}

export function addCompanyMediaCompletedAction(payload) {
    return {
        type: ADD_COMPANY_MEDIA_COMPLETED,
        payload,
    }
}

export function getCompanyMediaCompletedAction(payload) {
    return {
        type: GET_COMPANY_MEDIA_COMPLETED,
        payload,
    }
}

export function getCompanyMediaFailedAction(payload) {
    return {
        type: GET_COMPANY_MEDIA_FAILED,
        payload,
    }
}

export function deleteCompanyMediaFailedAction(payload) {
    return {
        type: DELETE_COMPANY_MEDIA_FAILED,
        payload,
    }
}

export function deleteCompanyMediaCompletedAction(payload) {
    return {
        type: DELETE_COMPANY_MEDIA_COMPLETED,
        payload,
    }
}

export function addCompanyPositionCompletedAction(payload) {
    return {
        type: ADD_COMPANY_POSITION_COMPLETED,
        payload,
    }
}

export function addCompanyPositionFailedAction(payload) {
    return {
        type: ADD_COMPANY_POSITION_FAILED,
        payload,
    }
}

export function addCompanyPositionQuestionCompletedAction(payload) {
    return {
        type: ADD_COMPANY_POSITION_QUESTION_COMPLETED,
        payload,
    }
}

export function addCompanyPositionQuestionFailedAction(payload) {
    return {
        type: ADD_COMPANY_POSITION_QUESTION_FAILED,
        payload,
    }
}

export function getCompanyPositionFailedAction(payload) {
    return {
        type: GET_COMPANY_POSITION_FAILED,
        payload,
    }
}

export function getCompanyPositionCompletedAction(payload) {
    return {
        type: GET_COMPANY_POSITION_COMPLETED,
        payload,
    }
}

export function getCompanyPositionsFailedAction(payload) {
    return {
        type: GET_COMPANY_POSITIONS_FAILED,
        payload,
    }
}

export function getCompanyPositionsCompletedAction(payload) {
    return {
        type: GET_COMPANY_POSITIONS_COMPLETED,
        payload,
    }
}

export function deleteCompanyPositionCompletedAction(payload) {
    return {
        type: DELETE_COMPANY_POSITION_COMPLETED,
        payload,
    }
}

export function deleteCompanyPositionFailedAction(payload) {
    return {
        type: DELETE_COMPANY_POSITION_FAILED,
        payload,
    }
}

export function updateCompanyPositionCompletedAction(payload) {
    return {
        type: UPDATE_COMPANY_POSITION_COMPLETED,
        payload,
    }
}

export function updateCompanyPositionFailedAction(payload) {
    return {
        type: UPDATE_COMPANY_POSITION_FAILED,
        payload,
    }
}

export function getCompanySubscriptionFailedAction(payload) {
    return {
        type: GET_COMPANY_SUBSCRIPTION_FAILED,
        payload,
    }
}

export function getCompanySubscriptionCompletedAction(payload) {
    return {
        type: GET_COMPANY_SUBSCRIPTION_COMPLETED,
        payload,
    }
}

export function getCompanyCandidatesCompletedAction(payload) {
    return {
        type: GET_COMPANY_CANDIDATES_COMPLETED,
        payload,
    }
}

export function getCompanyCandidatesFailedAction(payload) {
    return {
        type: GET_COMPANY_CANDIDATES_FAILED,
        payload,
    }
}

export function updateCompanyPositionQuestionFailedAction(payload) {
    return {
        type: UPDATE_COMPANY_POSITION_QUESTION_FAILED,
        payload,
    }
}

export function updateCompanyPositionQuestionCompletedAction(payload) {
    return {
        type: UPDATE_COMPANY_POSITION_QUESTION_COMPLETED,
        payload,
    }
}

export function updateCompanyPositionQuestionSeqFailedAction(payload) {
    return {
        type: UPDATE_COMPANY_POSITION_QUESTION_SEQ_FAILED,
        payload,
    }
}

export function updateCompanyPositionQuestionSeqCompletedAction(payload) {
    return {
        type: UPDATE_COMPANY_POSITION_QUESTION_SEQ_COMPLETED,
        payload,
    }
}

export function deleteCompanyPositionQuestionFailedAction(payload) {
    return {
        type: DELETE_COMPANY_POSITION_QUESTION_FAILED,
        payload,
    }
}

export function deleteCompanyPositionQuestionCompletedAction(payload) {
    return {
        type: DELETE_COMPANY_POSITION_QUESTION_COMPLETED,
        payload,
    }
}

export function getCompanyPositionQuestionsFailedAction(payload) {
    return {
        type: GET_COMPANY_POSITION_QUESTIONS_FAILED,
        payload,
    }
}

export function getCompanyPositionQuestionsCompletedAction(payload) {
    return {
        type: GET_COMPANY_POSITION_QUESTIONS_COMPLETED,
        payload,
    }
}

export function addCompanyPositionTeamMemberCompletedAction(payload) {
    return {
        type: ADD_COMPANY_POSITION_TEAM_MEMBER_COMPLETED,
        payload,
    }
}

export function addCompanyPositionTeamMemberFailedAction(payload) {
    return {
        type: ADD_COMPANY_POSITION_TEAM_MEMBER_FAILED,
        payload,
    }
}

export function removeCompanyPositionTeamMemberCompletedAction(payload) {
    return {
        type: REMOVE_COMPANY_POSITION_TEAM_MEMBER_COMPLETED,
        payload,
    }
}

export function removeCompanyPositionTeamMemberFailedAction(payload) {
    return {
        type: REMOVE_COMPANY_POSITION_TEAM_MEMBER_FAILED,
        payload,
    }
}

export function addCompanyPositionReminderFailedAction(payload) {
    return {
        type: ADD_COMPANY_POSITION_REMINDER_FAILED,
        payload,
    }
}

export function addCompanyPositionReminderCompletedAction(payload) {
    return {
        type: ADD_COMPANY_POSITION_REMINDER_COMPLETED,
        payload,
    }
}

export function removeCompanyPositionReminderFailedAction(payload) {
    return {
        type: REMOVE_COMPANY_POSITION_REMINDER_FAILED,
        payload,
    }
}

export function removeCompanyPositionReminderCompletedAction(payload) {
    return {
        type: REMOVE_COMPANY_POSITION_REMINDER_COMPLETED,
        payload,
    }
}

export function companyInviteCandidateCompletedAction(payload) {
    return {
        type: COMPANY_INVITE_CANDIDATE_COMPLETED,
        payload,
    }
}

export function companyInviteCandidateFailedAction(payload) {
    return {
        type: COMPANY_INVITE_CANDIDATE_FAILED,
        payload,
    }
}

export function addCompanyTagCompletedAction(payload) {
    return {
        type: ADD_COMPANY_TAG_COMPLETED,
        payload,
    }
}

export function addCompanyTagFailedAction(payload) {
    return {
        type: ADD_COMPANY_TAG_FAILED,
        payload,
    }
}

export function deleteCompanyTagCompletedAction(payload) {
    return {
        type: DELETE_COMPANY_TAG_COMPLETED,
        payload,
    }
}

export function deleteCompanyTagFailedAction(payload) {
    return {
        type: DELETE_COMPANY_TAG_FAILED,
        payload,
    }
}

export function getCompanyTagsCompletedAction(payload) {
    return {
        type: GET_COMPANY_TAGS_COMPLETED,
        payload,
    }
}

export function getCompanyTagsFailedAction(payload) {
    return {
        type: GET_COMPANY_TAGS_FAILED,
        payload,
    }
}

export function addCompanyInterviewTagCompletedAction(payload) {
    return {
        type: ADD_COMPANY_INTERVIEW_TAG_COMPLETED,
        payload,
    }
}

export function addCompanyInterviewTagFailedAction(payload) {
    return {
        type: ADD_COMPANY_INTERVIEW_TAG_FAILED,
        payload,
    }
}

export function deleteCompanyCandidateCompletedAction(payload) {
    return {
        type: DELETE_COMPANY_INTERVIEW_COMPLETED,
        payload,
    }
}

export function deleteCompanyCandidateFailedAction(payload) {
    return {
        type: DELETE_COMPANY_INTERVIEW_FAILED,
        payload,
    }
}

export function resetCompanyCandidateCompletedAction(payload) {
    return {
        type: RESET_COMPANY_INTERVIEW_COMPLETED,
        payload,
    }
}

export function resetCompanyCandidateFailedAction(payload) {
    return {
        type: RESET_COMPANY_INTERVIEW_FAILED,
        payload,
    }
}

export function sendCompanyCandidateReminderCompletedAction(payload) {
    return {
        type: SENT_COMPANY_INTERVIEW_REMINDER_COMPLETED,
        payload,
    }
}

export function sendCompanyCandidateReminderFailedAction(payload) {
    return {
        type: SENT_COMPANY_INTERVIEW_REMINDER_FAILED,
        payload,
    }
}

export function addCompanyCandidateTeamMemberCompletedAction(payload) {
    return {
        type: ADD_COMPANY_INTERVIEW_TEAM_MEMBER_COMPLETED,
        payload,
    }
}

export function addCompanyCandidateTeamMemberFailedAction(payload) {
    return {
        type: ADD_COMPANY_INTERVIEW_TEAM_MEMBER_FAILED,
        payload,
    }
}

export function removedCompanyCandidateTeamMemberCompletedAction(payload) {
    return {
        type: REMOVED_COMPANY_INTERVIEW_TEAM_MEMBER_COMPLETED,
        payload,
    }
}

export function removeCompanyCandidateTeamMemberCompletedAction(payload) {
    return {
        type: REMOVE_COMPANY_INTERVIEW_TEAM_MEMBER_COMPLETED,
        payload,
    }
}

export function removeCompanyCandidateTeamMemberFailedAction(payload) {
    return {
        type: REMOVE_COMPANY_INTERVIEW_TEAM_MEMBER_COMPLETED,
        payload,
    }
}

export function removeCompanyCandidateTagCompletedAction(payload) {
    return {
        type: DELETE_COMPANY_INTERVIEW_TAG_COMPLETED,
        payload,
    }
}

export function removeCompanyCandidateTagFailedAction(payload) {
    return {
        type: DELETE_COMPANY_INTERVIEW_TAG_FAILED,
        payload,
    }
}

export function getCompanyCandidatesEvaluationsCompletedAction(payload) {
    return {
        type: GET_COMPANY_CANDIDATES_EVALUATIONS_COMPLETED,
        payload,
    }
}

export function getCompanyCandidatesEvaluationsFailedAction(payload) {
    return {
        type: GET_COMPANY_CANDIDATES_EVALUATIONS_FAILED,
        payload,
    }
}

export function markCompanyCandidateEvaluationCompletedAction(payload) {
    return {
        type: MARK_COMPANY_CANDIDATE_EVALUATION_COMPLETED,
        payload,
    }
}

export function markCompanyCandidateEvaluationFailedAction(payload) {
    return {
        type: MARK_COMPANY_CANDIDATE_EVALUATION_FAILED,
        payload,
    }
}

export function getCompanyCandidateEvaluationDetailCompletedAction(payload) {
    return {
        type: GET_COMPANY_CANDIDATE_EVALUATION_DETAIL_COMPLETED,
        payload,
    }
}

export function getCompanyCandidateEvaluationDetailFailedAction(payload) {
    return {
        type: GET_COMPANY_CANDIDATE_EVALUATION_DETAIL_FAILED,
        payload,
    }
}

export function getCompanyCandidateFilterDataCompletedAction(payload) {
    return {
        type: GET_COMPANY_CANDIDATE_FILTER_DATA_COMPLETED,
        payload,
    }
}

export function getCompanyCandidateFilterDataFailedAction(payload) {
    return {
        type: GET_COMPANY_CANDIDATE_FILTER_DATA_FAILED,
        payload,
    }
}

export function addCompanyCandidateNoteCompletedAction(payload) {
    return {
        type: ADD_COMPANY_CANDIDATE_NOTE_COMPLETED,
        payload,
    }
}

export function addCompanyCandidateNoteFailedAction(payload) {
    return {
        type: ADD_COMPANY_CANDIDATE_NOTE_FAILED,
        payload,
    }
}

export function deleteCompanyCandidateNoteCompletedAction(payload) {
    return {
        type: DELETE_COMPANY_CANDIDATE_NOTE_COMPLETED,
        payload,
    }
}

export function deleteCompanyCandidateNoteFailedAction(payload) {
    return {
        type: DELETE_COMPANY_CANDIDATE_NOTE_FAILED,
        payload,
    }
}

export function getCompanyDashboardCompletedAction(payload) {
    return {
        type: GET_COMPANY_DASHBOARD_DATA_COMPLETED,
        payload,
    }
}

export function getCompanyDashboardFailedAction(payload) {
    return {
        type: GET_COMPANY_DASHBOARD_DATA_FAILED,
        payload,
    }
}

export function getCompanyDashboardRequestAction() {
    return {
        type: GET_COMPANY_DASHBOARD_DATA_REQUEST,
    }
}

export function getCompanyDashboardPositionsCompletedAction(payload) {
    return {
        type: GET_COMPANY_DASHBOARD_POSITIONS_DATA_COMPLETED,
        payload,
    }
}

export function getCompanyDashboardPositionsFailedAction(payload) {
    return {
        type: GET_COMPANY_DASHBOARD_POSITIONS_DATA_FAILED,
        payload,
    }
}

export function getCompanyDashboardPositionsRequestAction() {
    return {
        type: GET_COMPANY_DASHBOARD_POSITIONS_DATA_REQUEST,
    }
}

export function getCompanyDashboardInterviewCompletedAction(payload) {
    return {
        type: GET_COMPANY_DASHBOARD_INTERVIEWGRAPH_COMPLETED,
        payload,
    }
}

export function getCompanyDashboardInterviewFailedAction(payload) {
    return {
        type: GET_COMPANY_DASHBOARD_INTERVIEWGRAPH_FAILED,
        payload,
    }
}

export function getCompanyDashboardInterviewRequestAction() {
    return {
        type: GET_COMPANY_DASHBOARD_INTERVIEWGRAPH_REQUEST,
    }
}

export function getCompanyDashboardEvaluationCompletedAction(payload) {
    return {
        type: GET_COMPANY_DASHBOARD_EVALUATIONGRAPH_COMPLETED,
        payload,
    }
}

export function getCompanyDashboardEvaluationFailedAction(payload) {
    return {
        type: GET_COMPANY_DASHBOARD_EVALUATIONGRAPH_FAILED,
        payload,
    }
}

export function getCompanyDashboardEvaluationRequestAction() {
    return {
        type: GET_COMPANY_DASHBOARD_EVALUATIONGRAPH_REQUEST,
    }
}

export function updateCompanyProfileLoading() {
    return {
        type: UPDATE_COMPANY_POSITION_REQUEST,
    }
}

export function companyPositionDuplicationCompletedAction(payload) {
    return {
        type: COMPANY_POSITION_DUPLICATION_COMPLETED,
        payload,
    }
}

export function companyPositionDuplicationFailedAction(payload) {
    return {
        type: COMPANY_POSITION_DUPLICATION_FAILED,
        payload,
    }
}

export function getCompanyPaymentsCompletedAction(payload) {
    return {
        type: GET_COMPANY_PAYMENTS_DATA_COMPLETED,
        payload,
    }
}

export function getCompanyPaymentsFailedAction(payload) {
    return {
        type: GET_COMPANY_PAYMENTS_DATA_FAILED,
        payload,
    }
}

export function getCompanySubscriptionPlansCompletedAction(payload) {
    return {
        type: GET_COMPANY_SUBSCRIPTION_PLANS_DATA_COMPLETED,
        payload,
    }
}

export function getCompanySubscriptionPlansFailedAction(payload) {
    return {
        type: GET_COMPANY_SUBSCRIPTION_PLANS_DATA_FAILED,
        payload,
    }
}

export function markInterviewHireCompletedAction() {
    return {
        type: MARK_COMPANY_CANDIDATE_EVALUATION_COMPLETED,
    }
}

export function markInterviewHireFailedAction(payload) {
    return {
        type: GET_COMPANY_SUBSCRIPTION_PLANS_DATA_FAILED,
        payload,
    }
}

export function resetResponseMessageAction() {
    return {
        type: RESET_PORTAL_MESSAGE,
    }
}