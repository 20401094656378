import React from "react"
import ReactApexChart from "react-apexcharts"

const EvaluationDonutPGraph = (props) => {
    let name = ["No Data"]
    let avg = [0]

    if (props?.data?.length > 0) {
        name = []
        avg = []
        for (let i = 0; i < props?.data?.length; i++) {
            const x = props?.data[i]?.my_response.charAt(0).toUpperCase() + props?.data[i]?.my_response.slice(1);
            name.push(x)
            avg.push(props?.data[i]?.count)

        }

    }

    const state = {
        series: avg,
        options: {
            chart: {
                // width: 300,
                type: "donut"
            },
            labels: name,

            fill: {
                opacity: 1
                // colors: ["#001C35", "#00BA52", "#CE1036"],
            },
            stroke: {
                width: 0,
                colors: undefined
            },
            yaxis: {
                show: false
            },
            legend: {
                position: "top",
                markers: {
                    // fillColors: ["#001C35", "#00BA52", "#CE1036"],
                }
            },
            plotOptions: {
                polarArea: {
                    rings: {
                        strokeWidth: 0
                    }
                }
            },
            dataLabels: {
                enabled: false,
                enabledOnSeries: undefined,
                style: {
                    fontSize: "14px"
                }
            },
            tooltips: {
                color: "#fff",
                backgroundColor: "#775DD0"
            },
            // theme: {
            //   monochrome: {
            //     enabled: true,
            //     shadeTo: "light",
            //     shadeIntensity: 0.6,
            //   },
            // },
            colors: [
                "#00ba52",
                "#009477",
                "#006b7e",
                "#004164",
                "#001c35"
            ],
        }
    }

    return (
        <div id="chart" className="apex-chart d-flex align-self-center">
            <ReactApexChart
                options={state.options}
                series={state?.series}
                type="donut"
                height={250}
                // width={400}
            />
        </div>
    )
}

export default EvaluationDonutPGraph
