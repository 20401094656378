import PropTypes from "prop-types"
import React, {useEffect, useState, useRef} from "react"
import {Container, Row, Col, Spinner, Label} from "reactstrap"
import { Table, OverlayTrigger, Tooltip } from "react-bootstrap"
import {
    getCompanyCandidateFilterDataAction,
    getCompanyCandidatesEvaluationsAction, markInterviewHireAction,
} from "../../../store/actions/CompanyActions"
import {
    getCompanyEvaluations,
    getCompanyCandidateFilterDataSelector,
    getMeiaAddedMessage,
} from "../../../store/selectors/CompanySelector"
import Alert from "./../Common/Alert"

import PageTitle from "../../layouts/PageTitle"
import "./Evaluation.css"
import createHistory from 'history/createBrowserHistory'

// import './evaluations.scss'
import EvaluationsFilters from "./EvaluationsFilters"
import EvaluationTabs from "./EvaluationTabs"
import EvaluationList from "./EvaluationList"
import PaginationComponent from "./../Common/PaginationComponent"
import {withRouter} from "react-router-dom"
import {connect} from "react-redux"

import "../Common/rangeInput.css"

const Evaluations = (props) => {
    const {getCompanyEvaluations, getEvaluations, getCompanyFilterData, getFilterData, getMessage} = props
    const [loader, setloader] = useState(false)
    const [alert, showAlert] = useState(false)
    const [activeTab, setactiveTab] = useState( props?.history?.location?.state?.tab || "todo")
    const isMounted = useRef(false)
    const [sort, setSort] = useState(["createdAt", "ASC"])
    const [query, setQuery] = useState(props?.history?.location?.state ? {status: "completed"} : {})
    const [trackSort, setTrackSort] = useState(0)
    // {
    //   "sort_array": [["id", "DESC"], ["createdAt", "ASC"]] ,
    // }
    useEffect(() => {
        onFilterSubmit(true)
        getCompanyFilterData()
    }, [])

    useEffect(() => {
        onFilterSubmit(true)
        // hot fix for dashbord card navigations
        if(props?.history?.location?.state?.tab !== activeTab){
            const history = createHistory();
            if (props?.history.location.state && props?.history.location.state.tab) {
                let state = { ...props?.history.location.state };
                delete state.tab;
                history.replace({ ...props?.history.location, state });
            }
        }
    }, [activeTab])

    useEffect(() => {
        console.log({query});
        if (Object.keys(query).length > 0) {
            onFilterSubmit(true)
            isMounted.current = true
        } else if (isMounted.current) {
            onFilterSubmit(true)
            isMounted.current = false
        }
        onFilterSubmit()
    }, [query, sort])

    useEffect(() => {
        if (getMessage?.showLoading) {
            onFilterSubmit()
            // getCompanyTags({},props);
            getCompanyFilterData()
        }
    }, [getMessage?.showLoading])
    // console.log("SORT", sort)
    const onFilterSubmit = (page = 1, bol = false) => {
        const data = {
            filter_options: {
                ...query,
                sort_array: [sort],
            },
            pagination: {
                page: page,
                size: 10,
            },
            type: activeTab,
        }
        if (!getEvaluations) {
            setloader(true)
        }
        getCompanyEvaluations(data, props, () => setloader(false))
    }

    const handlePageChange = (page) => {
        setQuery({...query, page: page})
    }

    const handleTabChange = (tab) => {
        setactiveTab(tab)
    }

    const handleSortChange = (sort, by) => {
        setQuery({...query, sort: sort, sortBy: by})
    }

    const renderEvaluations = (evaluations) => {
        // console.log("EVALUATIONS", evaluations)
        if (evaluations && evaluations.length > 0) {
            return evaluations?.map((evaluation) => {
                return (
                    <EvaluationList key={evaluation?.id} teams={[]} evaluation={evaluation}
                                    showAlert={() => showAlert(true)}/>
                )
            })
        } else {
            return (
                <tr>
                    <td style={{textAlignLast: "center"}} colSpan={9}>
                        No Data Display
                    </td>
                </tr>
            )
        }
    }

    const renderTooltip = (props, text) => {
        return (
          <Tooltip style={{ width: "300px" }} {...props}>
            {text}
          </Tooltip>
        )
    }

    return (
        <React.Fragment>
            <div className="evaluation-page">
                {/* <PageTitle activeMenu="List" motherMenu="Evaluation"/> */}
                <Alert
                    alert={alert}
                    type={getMessage?.updated || getMessage?.delete ? "success" : getMessage?.error && "error"}
                    variant={getMessage?.updated || getMessage?.delete ? "success" : getMessage?.error && "danger"}
                    msg={getMessage?.updated || getMessage?.delete || getMessage?.error}
                    dismissAlert={() => showAlert(false)}
                />
                <Row className="">
                    <Col lg={12}>
                        <EvaluationTabs 
                            loading={loader} 
                            tab={activeTab} 
                            setTab={handleTabChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <EvaluationsFilters 
                            filterData={getFilterData} 
                            query={query} setQuery={setQuery}
                            setFilterData={props?.history?.location}
                        />
                    </Col>
                </Row>
                <div className="card">
                    <div className="card-body">
                        <Table className="custom-table text-dark">
                            <thead>
                            <tr>
                                <th style={{width: "50px"}}></th>
                                <th style={{width: "50px"}}></th>
                                {/*  Favourite</th>*/}
                                {/* /!*<th style={{ width: "50px" }}></th> *!/*/}
                                <th
                                    style={{overflow: "hidden", cursor: "pointer",}}
                                    onClick={() => {
                                        setSort((prev) => {
                                            return prev[0] === "first_name" && prev[1] === "ASC"
                                                ? ["first_name", "DESC"]
                                                : ["first_name", "ASC"]
                                        })
                                        setTrackSort(1)
                                    }}
                                >
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={props => renderTooltip(props, `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`)}>
                                    
                                        <Label style={{marginBottom:0}}> Name </Label>
                                    </OverlayTrigger>
                                    {trackSort === 1 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        <i className="bx bxs-sort-alt"/>
                                    )}
                                </th>
                                <th
                                    style={{overflow: "hidden", cursor: "pointer" }}
                                    onClick={() => {
                                        setSort((prev) => {
                                            return prev[0] === "status" && prev[1] === "ASC" ? ["status", "DESC"] : ["status", "ASC"]
                                        })
                                        setTrackSort(2)
                                    }}
                                >
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={props => renderTooltip(props, `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`)}>
                                    
                                        <Label style={{marginBottom:0}}> Status </Label>
                                    </OverlayTrigger>
                                    {trackSort === 2 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        <i className="bx bxs-sort-alt"/>
                                    )}
                                </th>
                                <th
                                    style={{overflow: "hidden", cursor: "pointer"}}
                                    onClick={() => {
                                        setSort((prev) => {
                                            return prev[0] === "completion_date" && prev[1] === "ASC"
                                                ? ["completion_date", "DESC"]
                                                : ["completion_date", "ASC"]
                                        })
                                        setTrackSort(5)
                                    }}
                                >
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={props => renderTooltip(props, `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`)}>

                                        <Label style={{marginBottom:0}}> Completion Date </Label>
                                    </OverlayTrigger>
                                    {trackSort === 5 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        <i className="bx bxs-sort-alt"/>
                                    )}
                                </th>
                                <th
                                    style={{overflow: "hidden", cursor: "pointer" }}
                                    onClick={() => {
                                        setSort((prev) => {
                                            return prev[0] === "progress" && prev[1] === "ASC"
                                                ? ["progress", "DESC"]
                                                : ["progress", "ASC"]
                                        })
                                        setTrackSort(4)
                                    }}
                                >
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={props => renderTooltip(props, `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`)}>
                                    
                                        <Label style={{marginBottom:0}}> Interview Progress </Label>
                                    </OverlayTrigger>
                                    {trackSort === 4 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        <i className="bx bxs-sort-alt"/>
                                    )}
                                </th>

                                <th
                                    style={{overflow: "hidden", cursor: "pointer"}}
                                    onClick={() => {
                                        setSort((prev) => {
                                            return prev[0] === "createdAt" && prev[1] === "ASC"
                                                ? ["createdAt", "DESC"]
                                                : ["createdAt", "ASC"]
                                        })
                                        setTrackSort(3)
                                    }}
                                >
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={props => renderTooltip(props, `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`)}>

                                        <Label style={{marginBottom:0}}> Date Added </Label>
                                    </OverlayTrigger>
                                    {trackSort === 3 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        <i className="bx bxs-sort-alt"/>
                                    )}
                                </th>
                                <th
                                    style={{overflow: "hidden", cursor: "pointer"}}
                                    onClick={() => {
                                        setSort((prev) => {
                                            return prev[0] === "ratings" && prev[1] === "ASC" ? ["ratings", "DESC"] : ["ratings", "ASC"]
                                        })
                                        setTrackSort(6)
                                    }}
                                >
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={props => renderTooltip(props, `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`)}>
                                    
                                        <Label style={{marginBottom:0}}> Rating </Label>
                                    </OverlayTrigger>
                                    {trackSort === 6 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        <i className="bx bxs-sort-alt"/>
                                    )}
                                </th>
                                <th
                                    // style={{overflow: "hidden", cursor: "pointer"}}
                                    // onClick={() => {
                                    //     setSort((prev) => {
                                    //         return prev[0] === "Tag" && prev[1] === "ASC" ? ["Tag", "DESC"] : ["Tag", "ASC"]
                                    //     })
                                    //     setTrackSort(7)
                                    // }}
                                >
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={props => renderTooltip(props, `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`)}>
                                    
                                        <Label style={{marginBottom:0}}> Tags </Label>
                                    </OverlayTrigger>
                                    {/* {trackSort === 7 ? (
                                        sort[1] === "DESC" ? (
                                            <i className="fa fa-arrow-down"/>
                                        ) : (
                                            <i className="fa fa-arrow-up"/>
                                        )
                                    ) : (
                                        ""
                                    )} */}
                                </th>
                                <th>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={props => renderTooltip(props, `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`)}>
                                    
                                        <Label style={{marginBottom:0}}> Actions </Label>
                                    </OverlayTrigger>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {loader ? (
                                <tr>
                                    <td style={{textAlignLast: "center"}} colSpan={9}>
                                        <Spinner size="sm" className="m-auto text-center" color="success"/>
                                    </td>
                                </tr>
                            ) : (
                                renderEvaluations(getEvaluations?.data)
                            )}
                            </tbody>
                        </Table>
                    </div>
                </div>
                <Row className="justify-content-center">
                    {/* <PaginationComponent pages={1113} page={0} setPage={(page) => submitFilter(page)} count={getPositions?.totalItems} /> */}
                    {!loader && (
                        <PaginationComponent
                            pages={getEvaluations?.totalPages}
                            page={getEvaluations?.currentPage}
                            setPage={(page) => onFilterSubmit(page)}
                            count={getEvaluations?.totalItems}
                        />
                    )}
                </Row>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        getEvaluations: getCompanyEvaluations(state),
        getMessage: getMeiaAddedMessage(state),
        getFilterData: getCompanyCandidateFilterDataSelector(state),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCompanyEvaluations: (data, props, cb) =>
            dispatch(getCompanyCandidatesEvaluationsAction(data, props.history, cb)),
        getCompanyFilterData: () => dispatch(getCompanyCandidateFilterDataAction()),
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Evaluations))
