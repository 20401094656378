import React, {Fragment, useEffect, useRef, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import Loader from "../Loader";
import "./style.css";
import {Col, Label, Row} from "reactstrap";
import pic1 from "./../../../images/default.svg";
import fallback from "./../../../images/fallback.jpeg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VideoThumbnail from "react-video-thumbnail";

// Images for Position, Invited, Complete and Qualified
//Images
// import Card1 from "./../../../images/card/card1.jpg"
// import Card2 from "./../../../images/card/card2.jpg"
// import Card3 from "./../../../images/card/card3.jpg"
// import Card4 from "./../../../images/card/card4.jpg"
import {
    // getCompany,
    // getCompanyCountries,
    getCompanyDashboardSelector,
    // getCompanyIndustries,
    // getCompanyMembers,
    // getCompanyMessage,
    getCompanyDashboardLoadingSelector,
    getCompanySubscription,
    // getCompanyDashboardPositionsSelector,
    // getCompanyDashboardEvaluationSelector,
    // getCompanyDashboardEvaluationLoadingSelector,
    // getCompanyDashboardInterviewSelector,
    // getCompanyDashboardInterviewLoadingSelector,
    //getDashboardPositionSelector,
} from "../../../store/selectors/CompanySelector";
import {
    // getCompanyAction,
    getCompanyDashboardAction,
    // updateCompanyAction,
    //getCompanyDashboardPositionsAction,
    // getCompanyDashboardEvaluationAction,
    // getCompanyDashboardInterviewAction,
} from "../../../store/actions/CompanyActions";
import {connect} from "react-redux";
import PositionCandidateGraph from "../Common/graphs/PositionCandidateGraph";
import EvaluationDonutPGraph from "../Common/graphs/EvaluationDonutPGraph";
import ActiveBarGraph from "../Common/graphs/ActiveBarGraph";
import AvgInterviewTimeGauge from "../Common/graphs/AvgInterviewTimeGauge";

import completed from "../../../images/svg/Complete.svg"
import hired from "../../../images/svg/Hired.svg"
import invited from "../../../images/svg/Invited.svg"
import positions from "../../../images/svg/Positions.svg"
import qualified from "../../../images/svg/Qualified.svg"
import stars from "../../../images/svg/Stars.svg"

import "../Report/style.css";
import {getCompanyReportPositionAction} from "../../../store/actions/ReportActions";
import {
    getCompanyReportPositionLoadingSelector,
    getCompanyReportPositionSelector,
} from "../../../store/selectors/ReportSelector";

const EvaluationDonut = loadable(() =>
    pMinDelay(import("./Charts/EvaluationDonut"), 1000)
);

const MonthlyUsageGauge = loadable(() =>
    pMinDelay(import("./Charts/MonthlyUsageGauge"), 1000)
);

// const AvgInterviewTimeGauge = loadable(() =>
//     pMinDelay(import("../Common/graphs/AvgInterviewTimeGauge"), 1000)
// );

const InterviewsBarChart = loadable(() =>
    pMinDelay(import("./Charts/InterviewsBarChart"), 1000)
);
const Home = (props) => {
    const {
        dashboard,
        history,
        getSubscription,
        // dashboardPositions,
        // dashboardEvaluationGraph,
        // dashboardEvaluationGraphLoading,
        // dashboardInterviewGraph,
        // dashboardInterviewGraphLoading,
        // getCompanyDashboardPositionsAction,
        // getDashboardPositions,
    } = props;
    const settings = {
        dots: false,
        swipeToSlide: true,
        infinite: true,
        arrows: false,
        speed: 900,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow:
                        dashboard?.members?.length <= 5 ? dashboard?.members?.length : 5,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow:
                        dashboard?.members?.length <= 4 ? dashboard?.members?.length : 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 450,
                settings: {
                    slidesToShow:
                        dashboard?.members?.length <= 3 ? dashboard?.members?.length : 3,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const [detail, setDetail] = useState(0);
    const positionsCharts = [];
    let defaultDetail = {};
    let subscription = null;
    if (dashboard?.subscription?.limit) {
        subscription = JSON.parse(dashboard?.subscription?.limit);
    }

    //let thumbnail = ""

    useEffect(() => {
        props.loadCompanyDashboardAction();
        props.loadCompanyReportPositionAction();
        // props.loadCompanyDashboardPositionsAction()
        // props.loadCompanyDashboardEvaluationAction()
        //props.loadCompanyDashboardInterviewAction()
        // submitFilter(1, true)
        // console.log(detail, "DETAILS")
        // setFilterTitle("")
        return () => {
            props = null;
        };
    }, []);
    // useEffect(() => {
    //     if (dashboard?.positions?.length) {
    //         props.loadCompanyReportPositionAction({
    //             position_id: dashboard?.positions[0]?.id
    //         })
    //     }
    //
    // }, [dashboard?.positions])

    // const submitFilter = (page = 1, bol = false) => {
    //   const data = {
    //     pagination: {
    //       page: page,
    //       size: 5,
    //     },
    //   }
    //   if (filterTitle) {
    //     data.filter_options = {
    //       search_string: filterTitle,
    //       sort_array: [["createdAt", "ASC"]],
    //     }
    //   }
    //   console.log("Position List Data called")
    //   props.getCompanyDashboardPositionsAction(data, props)
    // }

    // const [filterTitle, setFilterTitle] = useState("")
    // const isMounted = useRef(false)
    // useEffect(() => {
    //   if (filterTitle) {
    //     submitFilter()
    //     isMounted.current = true
    //   } else {
    //     if (isMounted.current) {
    //       submitFilter()
    //       isMounted.current = false
    //     }
    //   }
    // }, [filterTitle])

    const renderEvaluations = (evaluations) => {
        if (evaluations && evaluations.length > 0) {
            return evaluations?.map((evaluation) => {
                return (
                    <tr className="align-items-center">
                        <td>
                            <img
                                className="rounded-circle header-profile-user"
                                src={
                                    evaluation?.Candidate?.image ||
                                    "https://digitalhire-storage.s3.amazonaws.com/users/61b44bf2c57ff9f612cee27d/1641314978752.jpg"
                                }
                                alt="Header Avatar"
                            />
                        </td>
                        <td>
                            <div className="text-truncate font-size-14">
                                <Link
                                    className="text-dark"
                                    href={evaluation?.Candidate?.image}
                                    to="#"
                                >
                                    {evaluation?.Candidate?.first_name}{" "}
                                    {evaluation?.Candidate?.last_name}
                                </Link>
                            </div>
                        </td>
                        <td>{evaluation?.Position?.title}</td>
                        <td>{evaluation?.completion_date}</td>
                        <td>
                            <span className="badge badge-soft-success">Completed</span>
                        </td>
                        <td>
                            <Link
                                className="btn btn-outline-primary btn-sm"
                                to={`/evaluation/${evaluation?.id}`}
                            >
                                Evaluate
                            </Link>
                        </td>
                    </tr>
                );
            });
        } else {
            return (
                <tr>
                    <td style={{textAlignLast: "center"}} colSpan={9}>
                        No Data Display
                    </td>
                </tr>
            );
        }
    };

    return (
        <Fragment>
            <div>
                <div className="row dashboard">
                    {/* Stat Cards */}
                    <div className="col-12 stat">
                        <div className="row">
                            <div className="col stat">
                                <div className="card-bx-cancel stacked card bg-c-blue">
                                    <div className="card-info-cancel card-body">
                                        <div
                                            className="d-flex justify-content-between align-items-center"
                                            onClick={() => history.push(`/position`)}
                                        >
                                            <div>
                                                <p className="mb-1 text-white fs-14">Positions</p>
                                                <h2 className="num-text text-white font-w600">
                                                    {dashboard?.position_count}
                                                </h2>
                                            </div>
                                            <div className="stat-icon bg-white">
                                                <img src={positions} style={{height: "25px"}}/>
                                                {/*<i className="bx bxs-envelope color-c-blue bx-md"></i>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col stat">
                                <div className="card-bx-cancel stacked card bg-o-peel">
                                    <div className="card-info-cancel card-body">
                                        <div
                                            className="d-flex justify-content-between align-items-center"
                                            onClick={() =>
                                                history.push(`/candidate`, {filter: "invited"})
                                            }
                                        >
                                            <div>
                                                <p className="mb-1 text-white fs-14">Invited</p>
                                                <h2 className="num-text text-white font-w600">
                                                    {dashboard?.interview_invited_count}
                                                </h2>
                                            </div>
                                            <div className="stat-icon bg-white">
                                                <img src={invited} style={{height: "30px"}}/>
                                                {/*<i className="bx bxs-door-open color-o-peel bx-md"></i>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col stat">
                                <div className="card-bx-cancel stacked card bg-b-bell">
                                    <div className="card-info-cancel card-body">
                                        <div
                                            className="d-flex justify-content-between align-items-center"
                                            onClick={() =>
                                                history.push(`/evaluation`, {filter: "completed"})
                                            }
                                        >
                                            <div>
                                                <p className="mb-1 text-white fs-14">Completed</p>
                                                <h2 className="num-text text-white font-w600">
                                                    {dashboard?.interview_completd_count}
                                                </h2>
                                            </div>
                                            <div className="stat-icon bg-white">
                                                <img src={completed} style={{height: "27px"}}/>
                                                {/*<i className="bx bx-play-circle color-b-bell bx-md"></i>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col stat">
                                <div className="card-bx-cancel stacked card bg-c-green">
                                    <div className="card-info-cancel card-body">
                                        <div
                                            className="d-flex justify-content-between align-items-center"
                                            onClick={() =>
                                                history.push(`/evaluation`, {tab: "qualified"})
                                            }
                                        >
                                            <div>
                                                <p className="mb-1 text-white fs-14">Qualified</p>
                                                <h2 className="num-text text-white font-w600">
                                                    {dashboard?.interview_qualified_count}
                                                </h2>
                                            </div>
                                            <div className="stat-icon bg-white">
                                                <img src={stars} style={{height: "27px"}}/>
                                                {/*<i className="bx bx-award color-c-green bx-md"></i>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col stat">
                                <div className="card-bx-cancel stacked card bg-c-green2">
                                    <div className="card-info-cancel card-body">
                                        <div
                                            className="d-flex justify-content-between align-items-center"
                                            onClick={() =>
                                                history.push(`/evaluation`, {tab: "hired"})
                                            }
                                        >
                                            <div>
                                                <p className="mb-1 text-white fs-14">Hired</p>
                                                <h2 className="num-text text-white font-w600">
                                                    {dashboard?.interview_hired_count}
                                                </h2>
                                            </div>
                                            <div className="stat-icon bg-white">
                                                <img src={qualified} style={{height: "30px"}}/>
                                                {/*<i className="bx bx-award color-b-bell bx-md"></i>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="card ">
                                    <div className="card-header bg-secondary-c">
                                        <h5 className="card-title text-white-c">Interview Details</h5>
                                    </div>
                                    <div className="card-body d-flex align-items-center justify-content-center">
                                        {props?.dashboardLoading ? (
                                            <div>
                                                <Loader spinnerClass="text-green"/>
                                            </div>
                                        ) : (
                                            !!dashboard && (
                                                <div>
                                                    <EvaluationDonut
                                                        evaluated_data={dashboard?.interview_detail_graph_date?.months[0]}
                                                        type={"interviews"}
                                                    />
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/*  /!* Monthly Usage *!/*/}
                            {/*  <div className="col-lg-4 justify-content-center">*/}
                            {/*      <div className="card">*/}
                            {/*          <div className="card-header bg-c-blue-c">*/}
                            {/*              <h5 className="card-title">Monthly Usage</h5>*/}
                            {/*          </div>*/}
                            {/*          <div className="row d-none">*/}
                            {/*              <div className="col-4 border-right">*/}
                            {/*                  <div className="pt-3 pb-3 pl-0 pr-0 text-center">*/}
                            {/*                      <h4 className="m-1">*/}
                            {/*<span className="counter">*/}
                            {/*  {dashboard?.position_graph_date?.open}*/}
                            {/*</span>*/}
                            {/*                      </h4>*/}
                            {/*                      <p className="m-0">Open</p>*/}
                            {/*                  </div>*/}
                            {/*              </div>*/}
                            {/*              <div className="col-4 border-right">*/}
                            {/*                  <div className="pt-3 pb-3 pl-0 pr-0 text-center">*/}
                            {/*                      <h4 className="m-1">*/}
                            {/*<span className="counter">*/}
                            {/*  {dashboard?.position_graph_date?.close}*/}
                            {/*</span>*/}
                            {/*                      </h4>*/}
                            {/*                      <p className="m-0">Closed</p>*/}
                            {/*                  </div>*/}
                            {/*              </div>*/}
                            {/*              <div className="col-4">*/}
                            {/*                  <div className="pt-3 pb-3 pl-0 pr-0 text-center">*/}
                            {/*                      <h4 className="m-1">*/}
                            {/*<span className="counter">*/}
                            {/*  {dashboard?.position_graph_date?.draft}*/}
                            {/*</span>*/}
                            {/*                      </h4>*/}
                            {/*                      <p className="m-0">Draft</p>*/}
                            {/*                  </div>*/}
                            {/*              </div>*/}
                            {/*          </div>*/}
                            {/*          <div className="card-body d-flex align-items-center justify-content-center">*/}
                            {/*              {props?.dashboardLoading ? (*/}
                            {/*                  <Loader spinnerClass="text-green"/>*/}
                            {/*              ) : (*/}
                            {/*                  <div>*/}
                            {/*                      {!!dashboard && (*/}
                            {/*                          <MonthlyUsageGauge subscription={getSubscription}/>*/}
                            {/*                      )}*/}
                            {/*                  </div>*/}
                            {/*              )}*/}
                            {/*          </div>*/}
                            {/*      </div>*/}
                            {/*  </div>*/}
                            {/* Pending Evaluations Table */}
                            <div className="col-8">
                                <div className="card pending-evaluations">
                                    <div className="card-header">
                                        <h5 className="card-title"> Pending Evaluations </h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="cards-list">
                                            {dashboard?.pending_evaluation?.rows.length > 0 ? (
                                                dashboard?.pending_evaluation?.rows.map(
                                                    (item, index) => {
                                                        return (
                                                            <div
                                                                className="card-profile"
                                                                key={index}
                                                                style={
                                                                    item?.video_resume
                                                                        ? {}
                                                                        : {background: "#f6f6f6"}
                                                                }
                                                            >
                                                                <div
                                                                    className="card_image"
                                                                    onClick={() =>
                                                                        history.push(`/evaluation/${item?.id}`)
                                                                    }
                                                                >
                                                                    {item?.video_resume ||
                                                                    item?.Candidate?.CandidateProfile
                                                                        ?.video_resume ? (
                                                                        <VideoThumbnail
                                                                            videoUrl={
                                                                                item?.video_resume ||
                                                                                item?.Candidate?.CandidateProfile
                                                                                    ?.video_resume
                                                                            }
                                                                            thumbnailHandler={(thumb) =>
                                                                                console.log(thumb)
                                                                            }
                                                                            // width={140}
                                                                            // height={299}
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            src={
                                                                                item?.video_resume
                                                                                    ? item?.video_resume
                                                                                    : item?.Candidate?.CandidateProfile
                                                                                        ?.video_resume
                                                                                        ? item?.Candidate?.CandidateProfile
                                                                                            ?.video_resume
                                                                                        : item?.Candidate?.CandidateProfile
                                                                                            ?.video_resume_thumbnail ? item?.Candidate?.CandidateProfile
                                                                                            ?.video_resume_thumbnail : fallback
                                                                            }
                                                                            style={
                                                                                item?.video_resume ||
                                                                                item?.Candidate?.CandidateProfile
                                                                                    ?.video_resume || item?.Candidate?.CandidateProfile
                                                                                    ?.video_resume_thumbnail
                                                                                    ? {}
                                                                                    : {
                                                                                        height: "auto",
                                                                                        objectFit: "contain",
                                                                                    }
                                                                            }
                                                                        />
                                                                    )}
                                                                </div>
                                                                <div className="card_title title-white-profile">
                                                                    <div>
                                                                        <img
                                                                            src={
                                                                                item?.Candidate?.image
                                                                                    ? item?.Candidate?.image
                                                                                    : pic1
                                                                            }
                                                                            width="35"
                                                                            height="35"
                                                                            alt=""
                                                                            className="rounded-circle"
                                                                        />
                                                                    </div>
                                                                    <div className="mt-1">
                                                                        <p>
                                                                            <span
                                                                                className="profile-badge">{`${item?.Candidate?.first_name} ${item?.Candidate?.last_name}`}</span>{" "}
                                                                            <br/>
                                                                            <span className="profile-badge">
                                        {item?.Position?.title}
                                      </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )
                                            ) : (
                                                <Label className="subtittle">
                                                    <p>No Data Available</p>
                                                </Label>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Positions */}
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title">Positions</h5>
                                    </div>
                                    <div className="card-body">
                                        {props?.dashboardLoading ? (
                                            <div className="d-flex justify-content-center">
                                                <Loader spinnerClass="text-green"/>
                                            </div>
                                        ) : (
                                            !!dashboard && (
                                                <Row>
                                                    <Col xl={3} className="position-col">
                                                        {dashboard?.positions?.length > 0 &&
                                                            dashboard?.positions?.map((p, i) => {
                                                                if (i === 0)
                                                                    defaultDetail = {
                                                                        pNumber: i,
                                                                        chartData:
                                                                        p?.position_interview_detail_graph_data,
                                                                    };

                                                                positionsCharts.push({
                                                                    pNumber: i,
                                                                    chartData:
                                                                    p?.position_interview_detail_graph_data,
                                                                });
                                                                return (
                                                                    <div
                                                                        key={p.id}
                                                                        className={`position ${
                                                                            detail === i && `position-active`
                                                                        }`}
                                                                        onClick={() => {
                                                                            if (i) {
                                                                                setDetail(i);
                                                                                props.loadCompanyReportPositionAction({
                                                                                    position_id: p.id,
                                                                                });
                                                                            }

                                                                        }}
                                                                    >
                                                                        {p.title}
                                                                    </div>
                                                                );
                                                            })}

                                                        {dashboard?.position_count > 5 && (
                                                            <div className="position">
                                                                <Link className="position" to="/report">
                                                                    See More
                                                                </Link>
                                                            </div>
                                                        )}
                                                    </Col>
                                                    <Col xl={9}>
                                                        <div>
                                                            <Row>
                                                                <Col xl={4}>
                                                                    <div
                                                                        className="card candidate-filters border border-primary">
                                                                        <div className="card-header">
                                                                            <h5 className="card-title">Candidates</h5>
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <PositionCandidateGraph
                                                                                data={
                                                                                    props?.reportPositionData
                                                                                        ?.position_interview_detail_graph_data
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col xl={4}>
                                                                    <div
                                                                        className="card candidate-filters border border-primary">
                                                                        <div className="card-header">
                                                                            <h5 className="card-title">
                                                                                Avg. Interview Time
                                                                            </h5>
                                                                        </div>
                                                                        <div className="card-body">
                                                                            {/* {
                                                                                props?.reportPositionData
                                                                                    ?.position_interview_detail_graph_data
                                                                                    ?.avg_res ? props?.reportPositionData
                                                                                    ?.position_interview_detail_graph_data
                                                                                    ?.avg_res : 0
                                                                            }
                                                                            min */}
                                                                            <AvgInterviewTimeGauge
                                                                                data={props?.reportPositionData?.position_interview_detail_graph_data}/>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col xl={4}>
                                                                    <div
                                                                        className="card candidate-filters border border-primary">
                                                                        <div className="card-header">
                                                                            <h5 className="card-title">
                                                                                Evaluations
                                                                            </h5>
                                                                        </div>
                                                                        <div className="card-body">
                                                                            <EvaluationDonutPGraph
                                                                                data={
                                                                                    props?.reportPositionData
                                                                                        ?.position_evaluation_detail_graph_data
                                                                                }
                                                                                report={true}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* Traffic Chart */}
                            <div className="col-sm-6">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title">Traffic</h5>
                                    </div>
                                    <div className="card-body d-flex align-items-center justify-content-center">
                                        <div>
                                            <ActiveBarGraph traffic_data={dashboard?.traffic_data}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Evaluations */}
                            <div className="col-lg-6">
                                <div className="card ">
                                    <div className="card-header bg-secondary-c">
                                        <h5 className="card-title text-white-c">Evaluations</h5>
                                    </div>
                                    <div className="card-body d-flex align-items-center justify-content-center">
                                        {props?.dashboardLoading ? (
                                            <div>
                                                <Loader spinnerClass="text-green"/>
                                            </div>
                                        ) : (
                                            !!dashboard && (
                                                <div>
                                                    <EvaluationDonut
                                                        evaluated_data={dashboard?.evaluation_graph_date}
                                                    />
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* Interviews Details */}
                            {/*// <div className="col-lg-4">*/}
                            {/*  <div className="card ">*/}
                            {/*    <div className="card-header bg-secondary-c">*/}
                            {/*      <h5 className="card-title text-white-c">*/}
                            {/*        Interviews Details*/}
                            {/*//       </h5>*/}
                            {/*//     </div>*/}
                            {/*//     <div className="card-body">*/}
                            {/*//       {props?.dashboardLoading ? (*/}
                            {/*        <div className="d-flex justify-content-center">*/}
                            {/*          <Loader spinnerClass="text-green" />*/}
                            {/*        </div>*/}
                            {/*      ) : (*/}
                            {/*        !!dashboard && (*/}
                            {/*          <InterviewsBarChart*/}
                            {/*            data={dashboard?.interview_detail_graph_date}*/}
                            {/*          />*/}
                            {/*        )*/}
                            {/*      )}*/}
                            {/*    </div>*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="row"></div>
                    </div>
                    <div className="col-12 d-none">
                        <div className="row">
                            <div className="col-md-6 ">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title">Interviews</h5>
                                    </div>
                                    <div className="card-body">
                                        <div className="d-flex justify-content-center">
                                            {props?.dashboardLoading && (
                                                <Loader spinnerClass="text-green"/>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ============= discarded */}
            <div className="card d-none">
                <div className=" d-block card-header">
                    <div className="card-title h5">Subscription</div>
                    <p className="mb-0 subtitle card-text d-md-flex justify-content-between">
                        <span>{dashboard?.subscription?.name}</span>{" "}
                        <span>Ends On: {dashboard?.subscription?.to_date}</span>
                    </p>
                </div>
                <div className="card-body pt-0">
                    <div className="d-none">
                        <div className="mb-3 progress">
                            <div
                                role="progressbar"
                                className="progress-bar bg-primary"
                                aria-valuenow="30"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{width: "30%"}}
                            ></div>
                        </div>
                        <div className="mt-3 progress">
                            <div
                                role="progressbar"
                                className="progress-bar bg-warning"
                                aria-valuenow="80"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{width: "80%"}}
                            ></div>
                        </div>
                        <div className="mt-3 progress">
                            <div
                                role="progressbar"
                                className="progress-bar bg-inverse"
                                aria-valuenow="40"
                                aria-valuemin="0"
                                aria-valuemax="100"
                                style={{width: "40%"}}
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card d-none">
                <div className="card-body">
                    <div className="d-flex justify-content-center">
                        {props?.dashboardLoading && <Loader spinnerClass="text-green"/>}
                    </div>
                    <h5 className="card-title d-inline-block">
                        Interview Statistics - Last 10 Days
                    </h5>
                </div>
            </div>
            <div className="col-4 d-none">
                {/* Company Profile Card */}
                <div className="card ">
                    <div className="card-body">
                        <div className="align-items-center row">
                            <div className="col-sm-4">
                                <div className="circle undefined">
                                    <img
                                        src="https://digitalhire-storage.s3.amazonaws.com/companies/617bd6fccc42d0e548326802/media/logo_1640623544151.png"
                                        alt=""
                                        className="img-thumbnail rounded-circle"
                                    />
                                </div>
                            </div>
                            <div className="col-sm-8">
                                <div>
                                    <h3>
                                        <b>bolddusk</b>
                                    </h3>
                                    <h5 className="text-subtitle">Islambad, PK</h5>
                                    <div>
                                        <Link
                                            className="mr-2 secondary-link"
                                            href="/company/profile"
                                            to={"#"}
                                        >
                                            View Company Profile
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Subscription Plan Detail */}
            </div>
            <div className="col-8">
                {/* Top Stats */}
                <div className="row d-none">
                    <div className="col-md-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="stat d-flex justify-content-between align-items-center">
                                    <div className="stat-detail ">
                                        <p className="text-secondary">Positions</p>
                                        <h4 className="mb-0">4</h4>
                                    </div>
                                    <div className="stat-icon bg-cyan">
                                        <i className="bx bx-copy-alt "></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="stat d-flex justify-content-between align-items-center">
                                    <div className="stat-detail ">
                                        <p className="text-secondary">Invited</p>
                                        <h4 className="mb-0">4</h4>
                                    </div>
                                    <div className="stat-icon bg-red">
                                        <i className="bx bx-archive-in"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="stat d-flex justify-content-between align-items-center">
                                    <div className="stat-detail ">
                                        <p className="text-secondary">Completed</p>
                                        <h4 className="mb-0">4</h4>
                                    </div>
                                    <div className="stat-icon bg-primary">
                                        <i className="bx bx-purchase-tag-alt"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="stat d-flex justify-content-between align-items-center">
                                    <div className="stat-detail ">
                                        <p className="text-secondary">Qualified</p>
                                        <h4 className="mb-0">4</h4>
                                    </div>
                                    <div className="stat-icon bg-orange">
                                        <i className="bx bxs-like"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card pending-evaluations d-none">
                    <div className="card-body">
                        <div className="d-flex justify-content-center">
                            {props?.dashboardLoading && <Loader spinnerClass="text-green"/>}
                        </div>
                        <h4 className="card-title">Pending Evaluations</h4>
                        <table className="reviews-list-table table-centered table-borderless table">
                            <thead>
                            <tr>
                                <th scope="col" className="width70"></th>
                                <th scope="col">Name</th>
                                <th scope="col">Position</th>
                                <th scope="col">Completion Date</th>
                                <th scope="col">Status</th>
                                <th scope="col">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr className="align-items-center">
                                <td>
                                    <img
                                        className="rounded-circle header-profile-user"
                                        src="https://digitalhire-storage.s3.amazonaws.com/users/61b44bf2c57ff9f612cee27d/1641314978752.jpg"
                                        alt="Header Avatar"
                                    />
                                </td>
                                <td>
                                    <div className="text-truncate font-size-14">
                                        <Link
                                            className="text-dark"
                                            href="/company/evaluations/61c9edc58c2ac373441ccb61"
                                            to={"#"}
                                        >
                                            Abdul234 Hanan
                                        </Link>
                                    </div>
                                </td>
                                <td>Digital Markting</td>
                                <td>Dec 27, 2021</td>
                                <td>
                                    <span className="badge badge-soft-success">Completed</span>
                                </td>
                                <td>
                                    <Link
                                        className="btn btn-outline-primary btn-sm"
                                        href="/company/evaluations/61c9edc58c2ac373441ccb61"
                                        to={"#"}
                                    >
                                        Evaluate
                                    </Link>
                                </td>
                            </tr>
                            <tr className="align-items-center">
                                <td>
                                    <img
                                        className="rounded-circle header-profile-user"
                                        src="https://digitalhire-storage.s3.amazonaws.com/users/61b44bf2c57ff9f612cee27d/1641314978752.jpg"
                                        alt="Header Avatar"
                                    />
                                </td>
                                <td>
                                    <div className="text-truncate font-size-14">
                                        <Link
                                            className="text-dark"
                                            href="/company/evaluations/61c2bb4c443cdf01de6212e2"
                                            to={"#"}
                                        >
                                            Abdul234 Hanan
                                        </Link>
                                    </div>
                                </td>
                                <td>Temp 14-dec 11-31</td>
                                <td>Dec 22, 2021</td>
                                <td>
                                    <span className="badge badge-soft-success">Completed</span>
                                </td>
                                <td>
                                    <Link
                                        className="btn btn-outline-primary btn-sm"
                                        href="/company/evaluations/61c2bb4c443cdf01de6212e2"
                                        to={"#"}
                                    >
                                        Evaluate
                                    </Link>
                                </td>
                            </tr>
                            <tr className="align-items-center">
                                <td>
                                    <img
                                        className="rounded-circle header-profile-user"
                                        src="https://digitalhire-storage.s3.amazonaws.com/users/61b44bf2c57ff9f612cee27d/1641314978752.jpg"
                                        alt="Header Avatar"
                                    />
                                </td>
                                <td>
                                    <div className="text-truncate font-size-14">
                                        <Link
                                            className="text-dark"
                                            href="/company/evaluations/61bdf8ab4c17eefce51f56a0"
                                            to={"#"}
                                        >
                                            Abdul234 Hanan
                                        </Link>
                                    </div>
                                </td>
                                <td>First Position</td>
                                <td>Dec 18, 2021</td>
                                <td>
                                    <span className="badge badge-soft-success">Completed</span>
                                </td>
                                <td>
                                    <Link
                                        className="btn btn-outline-primary btn-sm"
                                        href="/company/evaluations/61bdf8ab4c17eefce51f56a0"
                                        to={"#"}
                                    >
                                        Evaluate
                                    </Link>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* Interviews Last 10 Days */}
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        dashboard: getCompanyDashboardSelector(state),
        dashboardLoading: getCompanyDashboardLoadingSelector(state),
        getSubscription: getCompanySubscription(state),
        reportPositionLoading: getCompanyReportPositionLoadingSelector(state),
        reportPositionData: getCompanyReportPositionSelector(state),
        // dashboardPositions: getCompanyDashboardPositionsSelector(state),
        // dashboardEvaluationGraph: getCompanyDashboardEvaluationSelector(state),
        // dashboardInterviewGraph: getCompanyDashboardInterviewSelector(state),
        // dashboardEvaluationGraphLoading: getCompanyDashboardEvaluationLoadingSelector(state),
        // dashboardInterviewGraphLoading: getCompanyDashboardInterviewLoadingSelector(state),
        // getDashboardPositions: getDashboardPositionSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadCompanyDashboardAction: () => dispatch(getCompanyDashboardAction()),
        loadCompanyReportPositionAction: (data) =>
            dispatch(getCompanyReportPositionAction(data)),
        // loadCompanyDashboardPositionsAction: () => dispatch(getCompanyDashboardPositionsAction()),
        // loadCompanyDashboardEvaluationAction: () => dispatch(getCompanyDashboardEvaluationAction()),
        // loadCompanyDashboardInterviewAction: () => dispatch(getCompanyDashboardInterviewAction()),
        // getCompanyDashboardPositionsAction: (data, props, cb) =>
        //   dispatch(getCompanyDashboardPositionsAction(data, props.history, cb)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
