export const REGISTER_COMPANY_CONFIRMATION = "[company register action] company registered"
export const REGISTER_COMPANY_FAILED = "[company register failed action] company register failed"
export const GET_COMPANY_CONFIRMATION = "[get company action] get company completed"
export const GET_COMPANY_FAILED = "[get company action] get company failed"
export const UPDATE_COMPANY_CONFIRMATION = "[update company action] update company completed"
export const UPDATE_COMPANY_FAILED = "[update company action] update company failed"
export const UPDATE_COMPANY_POSITION_REQUEST = "[update company position request] update company position request"

export const GET_COMPANY_COUNTRIES_COMPLETED = "[get company countries action] get company countries completed"
export const GET_COMPANY_INDUSTRIES_COMPLETED = "[get company industries action] get company industries completed"
export const GET_COMPANY_COUNTRIES_FAILED = "[get company countries action] get company countries failed"
export const GET_COMPANY_INDUSTRIES_FAILED = "[get company industries action] get company industries failed"

export const ADD_COMPANY_MEMBER_COMPLETED = "[add company member action] add company member completed"
export const ADD_COMPANY_MEMBER_FAILED = "[add company member action] add company member failed"

export const EDIT_COMPANY_MEMBER_STATUS_COMPLETED =
    "[edit company member status action] edit company member status completed"
export const EDIT_COMPANY_MEMBER_STATUS_FAILED = "[edit company member status action] edit company member status failed"

export const EDIT_COMPANY_MEMBER_ROLE_COMPLETED = "[edit company member role action] edit company member role completed"
export const EDIT_COMPANY_MEMBER_ROLE_FAILED = "[edit company member role action] edit company member role failed"

export const ADD_COMPANY_MEDIA_COMPLETED = "[add company media action] add company media completed"
export const ADD_COMPANY_MEDIA_FAILED = "[add company media action] add company media failed"

export const GET_COMPANY_MEDIA_COMPLETED = "[get company media action] get company media completed"
export const GET_COMPANY_MEDIA_FAILED = "[get company media action] get company media failed"

export const DELETE_COMPANY_MEDIA_COMPLETED = "[delete company media action] delete company media completed"
export const DELETE_COMPANY_MEDIA_FAILED = "[delete company media action] delete company media failed"

/** Company Positions */

export const ADD_COMPANY_POSITION_COMPLETED = "[add company position action] add company position completed"
export const ADD_COMPANY_POSITION_FAILED = "[add company position action] add company position failed"

export const COMPANY_POSITION_DUPLICATION_COMPLETED =
    "[company position duplication action] company position duplication completed"
export const COMPANY_POSITION_DUPLICATION_FAILED =
    "[company position duplication action] company position duplication failed"

export const GET_COMPANY_POSITIONS_COMPLETED = "[get company positions action] get company positions completed"
export const GET_COMPANY_POSITIONS_FAILED = "[get company positions action] get company positions failed"

export const DELETE_COMPANY_POSITION_COMPLETED = "[delete company position action] delete company position completed"
export const DELETE_COMPANY_POSITION_FAILED = "[delete company position action] delete company position failed"

export const UPDATE_COMPANY_POSITION_COMPLETED = "[update company position action] update company position completed"
export const UPDATE_COMPANY_POSITION_FAILED = "[update company position action] update company position failed"

export const GET_COMPANY_POSITION_COMPLETED = "[get company position action] get company position completed"
export const GET_COMPANY_POSITION_FAILED = "[get company position action] get company position failed"

export const ADD_COMPANY_POSITION_REMINDER_COMPLETED =
    "[add company position reminder action] add company position reminder completed"
export const ADD_COMPANY_POSITION_REMINDER_FAILED =
    "[add company position reminder action] add company position reminder failed"

export const REMOVE_COMPANY_POSITION_REMINDER_COMPLETED =
    "[remove company position reminder action] remove company position reminder completed"
export const REMOVE_COMPANY_POSITION_REMINDER_FAILED =
    "[remove company position reminder action] remove company position reminder failed"

/** Company Positions Team Members*/

export const ADD_COMPANY_POSITION_TEAM_MEMBER_COMPLETED =
    "[add company position team member action] add company position team member completed"
export const ADD_COMPANY_POSITION_TEAM_MEMBER_FAILED =
    "[add company position team member action] add company position team member failed"

export const REMOVE_COMPANY_POSITION_TEAM_MEMBER_COMPLETED =
    "[remove company position team member action] remove company position team member completed"
export const REMOVE_COMPANY_POSITION_TEAM_MEMBER_FAILED =
    "[remove company position team member action] remove company position team member failed"

/** Company Position Questions */
export const GET_COMPANY_POSITION_QUESTIONS_COMPLETED =
    "[get company position questions action] get company position questions completed"
export const GET_COMPANY_POSITION_QUESTIONS_FAILED =
    "[get company position questions action] get company position questions failed"

export const ADD_COMPANY_POSITION_QUESTION_COMPLETED =
    "[add company position question action] add company position question completed"
export const ADD_COMPANY_POSITION_QUESTION_FAILED =
    "[add company position question action] add company position question failed"

export const UPDATE_COMPANY_POSITION_QUESTION_COMPLETED =
    "[update company position question action] update company position question completed"
export const UPDATE_COMPANY_POSITION_QUESTION_FAILED =
    "[update company position question action] update company position question failed"

export const UPDATE_COMPANY_POSITION_QUESTION_SEQ_COMPLETED =
    "[update company position question seq action] update company position question seq completed"
export const UPDATE_COMPANY_POSITION_QUESTION_SEQ_FAILED =
    "[update company position question seq action] update company position question seq failed"

export const DELETE_COMPANY_POSITION_QUESTION_COMPLETED =
    "[delete company position question action] delete company position question completed"
export const DELETE_COMPANY_POSITION_QUESTION_FAILED =
    "[delete company position question action] delete company position question failed"

/** Company Candidate */
export const GET_COMPANY_CANDIDATES_COMPLETED = "[get company candidates action] get company candidates completed"
export const GET_COMPANY_CANDIDATES_FAILED = "[get company candidates action] get company candidates failed"

export const GET_COMPANY_CANDIDATES_EVALUATIONS_COMPLETED =
    "[get company candidates evaluations action] get company candidates evaluations completed"
export const GET_COMPANY_CANDIDATES_EVALUATIONS_FAILED =
    "[get company candidates evaluations action] get company candidates evaluations failed"

export const MARK_COMPANY_CANDIDATE_EVALUATION_COMPLETED =
    "[mark company candidate evaluation action] mark company candidate evaluation completed"
export const MARK_COMPANY_CANDIDATE_EVALUATION_FAILED =
    "[mark company candidate evaluation action] mark company candidate evaluation failed"

export const ADD_COMPANY_CANDIDATE_NOTE_COMPLETED =
    "[add company candidate note action] add company candidate note completed"
export const ADD_COMPANY_CANDIDATE_NOTE_FAILED = "[add company candidate note action] add company candidate note failed"

export const DELETE_COMPANY_CANDIDATE_NOTE_COMPLETED =
    "[delete company candidate note action] delete company candidate note completed"
export const DELETE_COMPANY_CANDIDATE_NOTE_FAILED =
    "[delete company candidate note action] delete company candidate note failed"

export const GET_COMPANY_CANDIDATE_EVALUATION_DETAIL_COMPLETED =
    "[get company candidate evaluation detail action] get company candidate evaluation detail completed"
export const GET_COMPANY_CANDIDATE_EVALUATION_DETAIL_FAILED =
    "[get company candidate evaluation detail action] get company candidate evaluation detail failed"

export const COMPANY_INVITE_CANDIDATE_COMPLETED = "[company invite candidate action] company invite candidate completed"
export const COMPANY_INVITE_CANDIDATE_FAILED = "[company invite candidate action] company invite candidate failed"

/** Subscription */
export const GET_COMPANY_SUBSCRIPTION_COMPLETED = "[get company subscription action] get company subscription completed"
export const GET_COMPANY_SUBSCRIPTION_FAILED = "[get company subscription action] get company subscription failed"

/** Company Candidate/Interview Tag  */

export const ADD_COMPANY_INTERVIEW_TAG_COMPLETED =
    "[add company interview tag action] add company interview tag completed"
export const ADD_COMPANY_INTERVIEW_TAG_FAILED = "[add company interview tag action] add company interview tag failed"

export const DELETE_COMPANY_INTERVIEW_TAG_COMPLETED =
    "[delete company interview tag action] delete company interview tag completed"
export const DELETE_COMPANY_INTERVIEW_TAG_FAILED =
    "[delete company interview tag action] delete company interview tag failed"

export const ADD_COMPANY_TAG_COMPLETED = "[add company tag action] add company tag completed"
export const ADD_COMPANY_TAG_FAILED = "[add company tag action] add company tag failed"

export const DELETE_COMPANY_TAG_COMPLETED = "[delete company tag action] delete company tag completed"
export const DELETE_COMPANY_TAG_FAILED = "[delete company tag action] delete company tag failed"

export const GET_COMPANY_TAGS_COMPLETED = "[get company tags action] get company tags completed"
export const GET_COMPANY_TAGS_FAILED = "[get company tags action] get company tags failed"

export const DELETE_COMPANY_INTERVIEW_COMPLETED = "[delete company interview action] delete company interview completed"
export const DELETE_COMPANY_INTERVIEW_FAILED = "[delete company interview action] delete company interview failed"

export const RESET_COMPANY_INTERVIEW_COMPLETED = "[reset company interview action] reset company interview completed"
export const RESET_COMPANY_INTERVIEW_FAILED = "[reset company interview action] reset company interview failed"

export const SENT_COMPANY_INTERVIEW_REMINDER_COMPLETED =
    "[send company interview reminder action] send company interview reminder completed"
export const SENT_COMPANY_INTERVIEW_REMINDER_FAILED =
    "[send company interview reminder action] send company interview reminder failed"

export const ADD_COMPANY_INTERVIEW_TEAM_MEMBER_COMPLETED =
    "[add company interview team member action] add company interview team member completed"
export const ADD_COMPANY_INTERVIEW_TEAM_MEMBER_FAILED =
    "[add company interview team member action] add company interview team member failed"

export const REMOVED_COMPANY_INTERVIEW_TEAM_MEMBER_COMPLETED =
    "[remove company interview team member action] remove company interview team member completed"
export const REMOVE_COMPANY_INTERVIEW_TEAM_MEMBER_COMPLETED =
    "[remove company interview team member action] remove company interview team member completed"
export const REMOVE_COMPANY_INTERVIEW_TEAM_MEMBER_FAILED =
    "[remove company interview team member action] remove company interview team member failed"

export const GET_COMPANY_CANDIDATE_FILTER_DATA_COMPLETED =
    "[get company candidate filter data action] get company candidate filter data completed"
export const GET_COMPANY_CANDIDATE_FILTER_DATA_FAILED =
    "[get company candidate filter data action] get company candidate filter data failed"

export const GET_COMPANY_DASHBOARD_DATA_COMPLETED =
    "[get company dashboard data action] get company dashboard data completed"
export const GET_COMPANY_DASHBOARD_DATA_FAILED = "[get company dashboard data action] get company dashboard data failed"
export const GET_COMPANY_DASHBOARD_DATA_REQUEST =
    "[get company dashboard data request] get company dashboard data request"

export const GET_COMPANY_DASHBOARD_POSITIONS_DATA_COMPLETED =
    "[get company dashboard position data action] get company dashboard position data completed"
export const GET_COMPANY_DASHBOARD_POSITIONS_DATA_FAILED =
    "[get company dashboard position data action] get company dashboard position data failed"
export const GET_COMPANY_DASHBOARD_POSITIONS_DATA_REQUEST =
    "[get company dashboard position data request] get company dashboard position data request"

export const GET_COMPANY_DASHBOARD_INTERVIEWGRAPH_COMPLETED =
    "[get company dashboard INTERVIEWGRAPH action] get company dashboard INTERVIEWGRAPH completed"
export const GET_COMPANY_DASHBOARD_INTERVIEWGRAPH_FAILED =
    "[get company dashboard INTERVIEWGRAPH action] get company dashboard INTERVIEWGRAPH failed"
export const GET_COMPANY_DASHBOARD_INTERVIEWGRAPH_REQUEST =
    "[get company dashboard INTERVIEWGRAPH request] get company dashboard INTERVIEWGRAPH request"

export const GET_COMPANY_DASHBOARD_EVALUATIONGRAPH_COMPLETED =
    "[get company dashboard EVALUATIONGRAPH action] get company dashboard EVALUATIONGRAPH completed"
export const GET_COMPANY_DASHBOARD_EVALUATIONGRAPH_FAILED =
    "[get company dashboard EVALUATIONGRAPH action] get company dashboard EVALUATIONGRAPH failed"
export const GET_COMPANY_DASHBOARD_EVALUATIONGRAPH_REQUEST =
    "[get company dashboard EVALUATIONGRAPH request] get company dashboard EVALUATIONGRAPH request"

export const GET_COMPANY_PAYMENTS_DATA_COMPLETED =
    "[get company payments data request] get company payments data completed"
export const GET_COMPANY_PAYMENTS_DATA_FAILED = "[get company payments data request] get company payments data failed"

export const GET_COMPANY_SUBSCRIPTION_PLANS_DATA_COMPLETED =
    "[get company subscription plans data request] get company subscription plans data completed"
export const GET_COMPANY_SUBSCRIPTION_PLANS_DATA_FAILED =
    "[get company subscription plans data request] get company subscription plans data failed"

export const MARK_INTERVIEW_HIRE_COMPLETED =
    "[mark interview action] mark interview hire completed"
export const MARK_INTERVIEW_HIRE_FAILED = "[mark interview action] mark interview hire failed"


export const RESET_PORTAL_MESSAGE = "[Reset portal message action] portal message reset complete"