import React, {useCallback, useRef, useState, useEffect, useReducer} from "react";
import {Col, InputGroup, Row, Spinner} from "reactstrap";
import {Card, Tab, Nav} from "react-bootstrap";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import createHistory from 'history/createBrowserHistory'
import {RangeDatePicker} from "@y0c/react-datepicker";

const CandidatesFilters = ({
                               setQuery,
                               query,
                               onFilterSubmit,
                               fiterOptions,
                               setFilterData,
                           }) => {
    const [dateCompleted, setDateCompleted] = useState(null);
    const [dateCompletedEnd, setDateCompletedEnd] = useState(null);
    const [dateAdded, setDateAdded] = useState(null);
    const [dateAddedEnd, setDateAddedEnd] = useState(null);
    const [filter, setFilter] = useState("")

    const formRef = useRef(null);
    const filterRef = useRef(null);

    useEffect(() => {
        setFilter(setFilterData?.state?.filter)
    }, [setFilterData])

    const renderPositionsOptions = (positions) => {
        const result = [
            <option key={"0000"} value={""}>
                Select Position
            </option>,
        ];
        if (positions && positions.length > 0) {
            positions.map((pos) => {
                result.push(
                    <option key={pos?.id} value={pos?.id}>
                        {pos?.title}
                    </option>
                );
            });
        }
        return result;
    };

    const renderStatusOptions = (statuses) => {
        const result = [
            <option key={"0000"} value={""}>
                Select Status Filter
            </option>,
        ];
        if (statuses && statuses.length > 0) {
            statuses.map((status) => {
                result.push(
                    <option key={status?.key} value={status?.key}>
                        {status?.value}
                    </option>
                );
            });
        }
        return result;
    };

    const renderMembersOptions = (members) => {
        const result = [
            <option key={"0000"} value={""}>
                Select Member
            </option>,
        ];
        if (members && members.length > 0) {
            members.map((mem) => {
                result.push(
                    <option key={mem?.id} value={mem?.id}>
                        {mem?.AddedMemberUser?.first_name} {mem?.AddedMemberUser?.last_name}
                    </option>
                );
            });
        }
        return result;
    };

    const renderTagsOptions = (tags) => {
        const result = [
            <option key={"0000"} value={""}>
                Select Tag
            </option>,
        ];
        if (tags && tags.length > 0) {
            // console.log("Tags:", tags);
            tags.map((tag) => {
                // console.log("Tag:", tag);
                result.push(
                    <option key={tag?.id} value={tag?.id}>
                        {tag?.name} ({tag?.tag_interView_count})
                    </option>
                );
            });
        }
        return result;
    };

    const renderResponseOptions = (response) => {
        const result = [
            <option key={"0000"} value={""}>
                Select Response
            </option>,
        ];
        if (response && response.length > 0) {
            response.map((res) => {
                result.push(
                    <option key={res} value={res}>
                        {res}
                    </option>
                );
            });
        }
        return result;
    };

    const remove = useCallback((key) => {
        setQuery(({[key]: value, ...query}) => query);
    }, []);


    const handleDateCompleted = (start, end) => {
        if (start && end) {
            setDateCompleted(start);
            setDateCompletedEnd(end)
            setQuery({
                ...query,
                // complete_date: moment(date).format("MMM DD, YYYY"),
                complete_date_start: moment(start).format("MMM DD, YYYY"),
                complete_date_end: moment(end).format("MMM DD, YYYY"),
            });
        } else {
            remove('complete_date')
            setDateCompleted(start);
        }
    };

    const handleDateAdded = (start, end) => {
        if (start && end) {
            setDateAdded(start);
            setDateAddedEnd(end)
            setQuery({
                ...query,
                // added_date: moment(date).format("MMM DD, YYYY"),
                added_date_start: moment(start).format("MMM DD, YYYY"),
                added_date_end: moment(end).format("MMM DD, YYYY"),
            });
        } else {
            remove('added_date')
            setDateAdded(start);
        }
    };

    const nameChange = (value) => {
        // console.log("Value length: ", value.target.value.length)
        // if (value.target.value.length >= 3)
        setQuery({
            ...query,
            name: value.target.value,
        });
    };

    const clearFilters = () => {
        setFilter('');
        setDateCompleted(null);
        setDateAdded(null);
        setDateCompletedEnd(null);
        setDateAddedEnd(null);
        setQuery({});
        formRef.current.reset();

        // hot fix for dashbord card navigations
        const history = createHistory();
        if (setFilterData?.state?.filter && setFilterData?.state?.filter) {
            let state = {...setFilterData?.state?.filter};
            delete state?.filter;
            history.replace({...setFilterData, state});
        }
    };

    return (
        <>
            <div className="card candidate-filters border border-primary">
                <div className="card-header">
                    <h5 className="card-title">Filters</h5>
                </div>
                <form
                    ref={formRef}
                    className=" card-body pb-0  "
                >
                    <Row>
                        <Col lg={10}>
                            <Row>
                                <Col lg={3}>
                                    <div className="form-group">
                                        {/* <label className="text-label">Name</label> */}
                                        <input
                                            name="name"
                                            placeholder="Name"
                                            className="form-control input-lg"
                                            onKeyUp={(e) => nameChange(e)}
                                            type="text"
                                        />
                                    </div>
                                    <div className="docs-datepicker form-group">
                                        {/*<label className="text-label">Date Completed</label>*/}
                                        {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                                        {/*    <DatePicker*/}
                                        {/*        autoOk*/}
                                        {/*        clearable*/}
                                        {/*        format="yyyy-MM-dd"*/}
                                        {/*        className="form-control"*/}
                                        {/*        placeholder="Date Completed"*/}
                                        {/*        value={dateCompleted}*/}
                                        {/*        onChange={(e) => handleDateCompleted(e)}*/}
                                        {/*    />*/}
                                        {/*</MuiPickersUtilsProvider>*/}
                                        <RangeDatePicker

                                            onChange={handleDateCompleted}
                                            startText="Completed"
                                            endText=""
                                            startPlaceholder="Completed"
                                            endPlaceholder=""
                                            value={null}
                                            // initialStartDate={dateCompleted}
                                            // initialEndDate={dateCompletedEnd}
                                            // prevIcon={true}
                                            // nextIcon={false}
                                            // icon={false}
                                            // show={true}
                                            // clear={true}
                                        />
                                    </div>
                                </Col>
                                <Col lg={3}>
                                    <div className="form-group">
                                        {/* <label className="text-label">Filter by Position</label> */}
                                        <select
                                            name="position"
                                            className="form-control input-lg"
                                            type="select"
                                            onChange={(e) =>
                                                setQuery({...query, position: e.target.value})
                                            }
                                        >
                                            {renderPositionsOptions(fiterOptions?.positions)}
                                        </select>
                                    </div>
                                    <div className="docs-datepicker form-group">
                                        {/* <label className="text-label">Date Added</label> */}
                                        {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                                        {/*    <DatePicker*/}
                                        {/*        autoOk*/}
                                        {/*        clearable*/}
                                        {/*        format="yyyy-MM-dd"*/}
                                        {/*        className="form-control"*/}
                                        {/*        placeholder="Date Added"*/}
                                        {/*        value={dateAdded}*/}
                                        {/*        onChange={(e) => handleDateAdded(e)}*/}
                                        {/*    />*/}
                                        {/*</MuiPickersUtilsProvider>*/}
                                        <RangeDatePicker

                                            onChange={handleDateAdded}
                                            startText="Date Added"
                                            // endText="End"

                                            // initialStartDate={dateAdded}
                                            // initialEndDate={dateAddedEnd}
                                            startPlaceholder="Date Added"
                                            // endPlaceholder="End"
                                            value={dateAdded}
                                        />
                                        <div className="docs-datepicker-container"/>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <Row>
                                        <Col lg={6}>
                                            <div className="form-group form-group-lg">
                                                {/* <label className="text-label">Filter by Status</label> */}
                                                <select
                                                    ref={filterRef}
                                                    name="interviewStatus"
                                                    className="form-control input-lg"
                                                    type="select"
                                                    value={filter}
                                                    onChange={(e) => {
                                                        setFilter(e.target.value)
                                                        setQuery({...query, status: e.target.value})
                                                    }
                                                    }
                                                >
                                                    {renderStatusOptions(fiterOptions?.status)}
                                                </select>
                                            </div>
                                            <div className="form-group form-group-lg">
                                                {/* <label className="text-label">Filter by Tags</label> */}
                                                <select
                                                    name="tags"
                                                    className="form-control input-lg"
                                                    onChange={(e) =>
                                                        setQuery({...query, ["tag"]: e.target.value})
                                                    }
                                                >
                                                    {renderTagsOptions(fiterOptions?.tags)}
                                                </select>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <Row>
                                                <Col lg={6}></Col>
                                                <Col lg={6}></Col>
                                            </Row>
                                            <div className="form-group response-filter form-group-lg">
                                                {/* <label className="text-label">Response</label> */}
                                                <select
                                                    name="result"
                                                    className="form-control input-lg"
                                                    onChange={(e) =>
                                                        setQuery({...query, response: e.target.value})
                                                    }
                                                >
                                                    {renderResponseOptions(fiterOptions?.response)}
                                                </select>
                                            </div>
                                            <div className="form-group form-group-lg">
                                                {/* <label className="text-secondary font-15px"><small>Filter by Team Member</small> {loading ? <Spinner size="sm" className="m-auto text-center" color="secondary" /> : ""}</label> */}
                                                {/* <label className="text-label">Filter by Team Member</label> */}
                                                <select
                                                    name="teamMember"
                                                    className="form-control input-lg"
                                                    onChange={(e) =>
                                                        setQuery({...query, team_member: e.target.value})
                                                    }
                                                >
                                                    {renderMembersOptions(fiterOptions?.team_members)}
                                                </select>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={2}>
                            <Row className="mb-3">
                                <Col lg={12}>
                                    <div className="form-group form-group-lg">
                                        {/* <label className="text-label">Ratings</label> */}
                                        <select
                                            name="ratings"
                                            className="form-control input-lg"
                                            onChange={(e) =>
                                                setQuery({...query, rating: e.target.value})
                                            }
                                        >
                                            <option value="">Rating</option>
                                            <option value={1}>1</option>
                                            <option value={2}>2</option>
                                            <option value={3}>3</option>
                                            <option value={4}>4</option>
                                            <option value={5}>5</option>
                                        </select>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="form-group form-group-lg">
                                        {/* <label className="text-label">Clear Filters</label> */}
                                        <button
                                            onClick={() => clearFilters()}
                                            type="button"
                                            className="btn btn-secondary btn-block"
                                        >
                                            Clear Filters
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                            {/* <Row>
            <Col>
              <label className="text-label">Filter Result</label>
              <button disabled={loading} type="submit" className="btn btn-success btn-block">
                {loading ? <Spinner size="sm" className="m-auto text-center" color="light" /> : "Filter Results" }
              </button>
            </Col>
          </Row> */}
                        </Col>
                    </Row>
                </form>
            </div>
        </>
    );
};

export default CandidatesFilters;
