import React, {useState} from "react"
import {Badge, DropdownMenu, DropdownToggle, DropdownItem, Progress, UncontrolledDropdown} from "reactstrap"
import {Modal, Row, Col, OverlayTrigger, Tooltip} from "react-bootstrap"
import AddNewTag from "../Candidate/AddNewTag"
import avatar1 from "./../../../images/default.svg"
// import pic1 from './../../../images/default.svg';
import goldBadge from "./../../../images/gold-badge.svg"
import silverBadge from "./../../../images/silver-badge.svg"

import moment from "moment"
import DeletePrompt from "../Common/DeletePrompt"
import {renderResponse, renderStatus} from "../Common/RenderText"
import {
    deleteCompanyCandidateAction,
    resetCompanyCandidateAction,
    removeCompanyCandidateTagAction, markCompanyCandidateEvaluationAction,
} from "../../../store/actions/CompanyActions"
import {withRouter, Link} from "react-router-dom"
import {connect} from "react-redux"
import TeamMember from "../Candidate/TeamMember";
import TagsInput from "../Candidate/TagsInput";

const EvaluationList = (props) => {
    const {
        teams,
        evaluation,
        deleteCandidates,
        resetCandidates,
        removeCompanyCandidateTag,
        showAlert,
        markCompanyCandidateEvaluation
    } = props
    const [progressModal, setProgressModal] = useState({
        show: false,
        func: undefined,
    })
    const [deleteModal, setDeleteModal] = useState({
        show: false,
        func: undefined,
    })


    const renderTooltip = (props, text) => {
        return (
            <Tooltip style={{width: "300px"}} {...props}>
                {text}
            </Tooltip>
        )
    }
    const [teamMemberModal, setTeamMemberModal] = useState(null)
    const [tagModal, setTagModal] = useState(undefined)

    const renderRating = (rating) => {
        if (rating === 0 || !rating) return "secondary"
        if (rating === 1 || rating === 2) return "danger"
        if (rating === 3) return "warning"
        if (rating === 4 || rating === 5) return "success"
    }

    const toggleTag = (tag, interviewID, cb) => {
        removeCompanyCandidateTag(
            {
                tag_id: tag,
                interview_id: interviewID,
            },
            [],
            (bol) => {
                cb && cb(bol)
                bol && showAlert()
            }
        )
    }
    const renderInterviewTags = (tags, interviewID, cb) => {
        let result = ["Add tags"]
        if (tags && tags.length > 0) {
            result = []
            tags.map((tag, i) => {
                result.push(
                    <Badge
                        key={`${tag?.id}-${i}`}
                        style={{color: tag?.color, backgroundColor: `${tag?.color}20`}}
                        className="mr-1"
                    >
                        {tag?.name}
                        <span
                            onClick={() => toggleTag(tag?.id, interviewID, cb)}
                            className="badge font-size-10"
                            style={{cursor: "pointer"}}
                        >
              X
            </span>
                    </Badge>
                )
            })
        }
        return result
    }

    return (
        <tr key={evaluation?.id} className="align-items-center">
            <td>
                {/* <div style={{width: "40px", overflow: "hidden"}}> */}
                <img
                    className={`rounded-circle header-profile-user`}
                    src={evaluation?.Candidate?.image || avatar1}
                    alt="Dp"
                    style={{width: "2.5rem", height: "2.5rem"}}
                />
                {/* </div> */}
            </td>
            <td>
                {evaluation?.favorite ? (
                    <img
                        className="rounded-circle"
                        src={goldBadge}
                        alt="Gold badge"
                        style={{width: "2rem", height: "2rem", cursor: "pointer"}}
                        onClick={() => {
                            const data = {
                                interview_id: evaluation.id,
                                interview_data: {
                                    favorite: false
                                }
                            }
                            markCompanyCandidateEvaluation(data)
                        }}
                    />
                ) : (
                    <img
                        className="rounded-circle"
                        src={silverBadge}
                        alt="Silver Badge"
                        style={{width: "2rem", height: "2rem", cursor: "pointer"}}
                        onClick={() => {
                            const data = {
                                interview_id: evaluation.id,
                                interview_data: {
                                    favorite: true
                                }
                            }
                            markCompanyCandidateEvaluation(data)
                        }}
                    />
                )}
            </td>
            <td>
                <div style={{width: "8rem", overflow: "hidden"}} className="text-truncate font-size-14">
                    <Link
                        to={evaluation?.progress === 0 ? "#" : `/evaluation/${evaluation?.id}`}
                        style={{cursor: `${evaluation?.progress === 0 ? "default" : "pointer"}`}}
                        className="text-dark"
                    >
                        {evaluation?.Candidate?.first_name} {evaluation?.Candidate?.last_name}
                    </Link>
                    <span className="d-block text-muted text-truncate">
                        <OverlayTrigger
                            placement="bottom"
                            overlay={props => renderTooltip(props, `${evaluation?.Position?.title}`)}>

                                        <small title={evaluation?.Position?.title}
                                               style={{cursor: "pointer"}}>{evaluation?.Position?.title}</small>
                                    </OverlayTrigger>

                    </span>
                </div>
            </td>
            <td>{evaluation?.is_reviewed === true ? "Evaluated" : renderStatus(evaluation?.status)}</td>
            <td>{evaluation?.completion_date ? moment(evaluation?.completion_date).format("MMM DD, YYYY") : "N/A"}</td>
            {/*<td>{moment(evaluation?.createdAt).format("MMM DD, YYYY")}</td>*/}
            <td className="" style={{borderWidth: 1}}>
                <span>
                    {evaluation?.progress}/{evaluation?.question_count}
                </span>
                <Progress className="mb-1" color="primary"
                          value={(evaluation?.progress / evaluation?.question_count) * 100}/>
            </td>
            <td>{evaluation?.start_date ? moment(evaluation?.start_date).format("MMM DD, YYYY") : "N/A"}</td>
            <td>
                <div className="mb-1">
                    <Badge color={evaluation?.ratings ? renderRating(evaluation?.ratings) : "light"}
                           className="font-size-12">
                        <i className="mdi mdi-star mr-1"/>
                        {evaluation?.ratings ? Number(evaluation?.ratings).toFixed(1) : 0}
                    </Badge>
                </div>
                <span
                    className="d-block">{evaluation?.my_response ? renderResponse(evaluation?.my_response) : "Pending"}
                </span>
            </td>
            <td className="align-items-center">
                {
                    tagModal && tagModal.id === evaluation.id ? <TagsInput
                        tags={tagModal?.Tag}
                        interview={tagModal}
                        members={tagModal?.CompanyMembers}
                        renderInterviewTags={renderInterviewTags}
                        onHide={() => {
                            setTagModal(null)
                        }}
                    /> : <>
                        {renderInterviewTags(evaluation?.Tag, evaluation.id, null)}
                        {evaluation?.Tag.length < 4 ? <span style={{position: "relative", left: "3px", top: "3px"}}>
                            <i
                                style={{cursor: "pointer"}}
                                onClick={() => setTagModal(evaluation)}
                                className="fa fa-plus-square font-size-18 text-primary"
                            />
                            </span> : null}
                    </>
                }
            </td>
            <td>
                <UncontrolledDropdown>
                    <DropdownToggle href="#" className="card-drop" tag="i">
                        <i className="mdi mdi-dots-horizontal font-size-18"/>
                    </DropdownToggle>
                    <DropdownMenu right>
                        {evaluation?.is_completed ? (
                            ""
                        ) : (
                            <DropdownItem tag={Link} to={`/evaluation/${evaluation.id}`}>
                                <Link className="text-primary" to={`/evaluation/${evaluation.id}`}>
                                    Evaluate
                                </Link>
                            </DropdownItem>
                        )}

                        <DropdownItem
                            className="text-dark"
                            onClick={() => {
                                console.log("Evaluation Data", evaluation)
                                setTeamMemberModal(evaluation)
                            }}
                        >
                            Team Member
                        </DropdownItem>
                        {/* <DropdownItem to={"#"}>View Profile</DropdownItem> */}
                        {/* <DropdownItem to={"#"}>View Profile</DropdownItem> */}
                        <DropdownItem
                            onClick={() => {
                                setProgressModal({
                                    show: true,
                                    func: () => {
                                        resetCandidates({interview_id: evaluation?.id}, props, () => {
                                            showAlert()
                                        })
                                        setProgressModal(undefined)
                                    },
                                })
                            }}
                            className="text-dark"
                        >
                            Reset Progress
                        </DropdownItem>
                        <DropdownItem
                            className="text-danger"
                            onClick={() => {
                                setDeleteModal({
                                    show: true,
                                    func: () => {
                                        deleteCandidates({interview_id: evaluation?.id}, props, () => {
                                            showAlert()
                                        })
                                        setDeleteModal(undefined)
                                    },
                                })
                            }}
                        >
                            Delete
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                {/*<Modal*/}
                {/*    centered={true}*/}
                {/*    show={tagModal ? true : false}*/}
                {/*    onHide={() => {*/}
                {/*        setTagModal(undefined)*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <AddNewTag*/}
                {/*        tags={tagModal?.Tag}*/}
                {/*        interview={tagModal}*/}
                {/*        members={tagModal?.CompanyMembers}*/}
                {/*        renderInterviewTags={renderInterviewTags}*/}
                {/*        onHide={() => {*/}
                {/*            setTagModal(undefined)*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</Modal>*/}
                <Modal
                    centered={true}
                    show={progressModal?.show}
                    onHide={() => {
                        setProgressModal(undefined)
                    }}
                >
                    <DeletePrompt
                        title={"Are you sure you want to reset this Interview?"}
                        btnText={"Reset"}
                        func={() => progressModal?.func()}
                        toggle={() => {
                            setProgressModal(undefined)
                        }}
                    />
                </Modal>
                <Modal
                    centered={true}
                    size="md"
                    show={teamMemberModal ? true : false}
                    onHide={() => {
                        setTeamMemberModal(null)
                    }}
                >
                    <TeamMember
                        tags={teamMemberModal?.Tag}
                        interview={teamMemberModal}
                        members={teamMemberModal?.CompanyMembers}
                        renderInterviewTags={renderInterviewTags}
                        onHide={() => {
                            setTeamMemberModal(null)
                        }}
                    />
                </Modal>
                <Modal
                    centered={true}
                    show={deleteModal?.show}
                    onHide={() => {
                        setDeleteModal(undefined)
                    }}
                >
                    <DeletePrompt
                        title={"Are you sure you want to delete this Interview?"}
                        func={() => deleteModal?.func()}
                        toggle={() => {
                            setDeleteModal(undefined)
                        }}
                    />
                </Modal>
            </td>
        </tr>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteCandidates: (data, props, cb) => dispatch(deleteCompanyCandidateAction(data, props.history, cb)),
        resetCandidates: (data, props, cb) => dispatch(resetCompanyCandidateAction(data, props.history, cb)),
        removeCompanyCandidateTag: (data, props, cb) => dispatch(removeCompanyCandidateTagAction(data, props.history, cb)),
        markCompanyCandidateEvaluation: (data, props, cb) =>
            dispatch(markCompanyCandidateEvaluationAction(data, null, cb)),
    }
}
export default withRouter(connect(null, mapDispatchToProps)(EvaluationList))
