import React, {useEffect, useState} from "react"
import {Card, CardBody, Col, Media, Row, Spinner} from "reactstrap"
import {Modal} from "react-bootstrap"
import avatar1 from "./../../../images/default.svg"
import moment from "moment"
import {useDispatch, useSelector} from "react-redux"
import DeletePrompt from "../Common/DeletePrompt"
import {addCompanyCandidateNoteAction} from "../../../store/actions/CompanyActions"

const EvaluationComments = ({evaluation, deleteNote, loading, showAlert}) => {
    const dispatch = useDispatch()

    const [deleteModal, setDeleteModal] = useState(undefined)
    const [editMode, setEditMode] = useState(undefined)
    const [note, setNote] = useState(undefined)
    const [loader, setloader] = useState(false)

    const handleNoteChange = (note) => {
        setNote(note)
    }

    const handleCommentSubmit = () => {
        const data = {
            interview_id: evaluation.id,
            note: note,
        }
        setloader(true)
        dispatch(addCompanyCandidateNoteAction(data, [], () => {
            setloader(false);
        }))
        setNote("")
    }

    const renderComments = (comments) => {
        // console.log(comments)
        if (comments?.Note && comments?.Note.length > 0) {
            return comments?.Note?.map((com, key) => {
                return (
                    <>
                        <li key={`evaluation-comment-list-${key}`} className="d-none">
                            <div>
                                <Row className="align-items-start ">
                                    <Col lg={2}>
                                        <img width={40} src={avatar1} className="rounded-circle avatar" alt=""/>
                                    </Col>
                                    <Col lg={10}>
                                        <div className="overflow-hidden commentBody" body="true">
                                            <div>
                                                <h5 className="text-truncate p-0 m-0 font-size-14 d-inline-block">Abdul
                                                    Hanan</h5>
                                                <span
                                                    className="font-size-11 float-right">{moment().format("MMM DD, YYYY")}</span>
                                            </div>
                                            <p className="mb-0">
                                                {editMode && com?.id === editMode?.id ? (
                                                    <textarea
                                                        onChange={(e) => handleNoteChange(e.target.value)}
                                                        value={editMode?.note}
                                                        className="form-control d-block"
                                                    ></textarea>
                                                ) : (
                                                    com?.note
                                                )}
                                            </p>
                                            {
                                                // com?.createdBy?.id === user?.id ? <div className="float-right mt-1">
                                                // {
                                                // editMode && com?.id === editMode?.id ?
                                                // <button onClick={() => handleCommentSubmit()} className="btn btn-primary">Save</button> :
                                                // <button onClick={() => setEditMode(com)} className="btn btn-link green">Edit</button>
                                            }
                                            <button
                                                onClick={() =>
                                                    setDeleteModal({
                                                        show: true,
                                                        func: () => {
                                                            deleteNote({
                                                                interview_id: comments.id,
                                                                note_id: com.id,
                                                            })
                                                            setDeleteModal(undefined)
                                                        },
                                                    })
                                                }
                                                className="btn btn-link text-danger"
                                            >
                                                Delete
                                            </button>
                                            {/* </div>  */}
                                            {/* : "" */}
                                            {/* } */}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </li>

                        <li>
                            <div className="timeline-panel">
                                <div className="media mr-2">
                                    <img alt="" width="50" src={com?.AddedBy?.image || avatar1}/>
                                </div>
                                <div className="media-body">
                                    <h5 className="mb-1">
                                        {com?.AddedBy?.first_name} {com?.AddedBy?.last_name}{" "}
                                        <small className="text-muted">
                                            {
                                                moment(com?.createdAt).fromNow()
                                                // format("MMM DD, YYYY")
                                            }
                                        </small>
                                    </h5>
                                    <small className="d-block text-dark">
                                        {editMode && com?.id === editMode?.id ? (
                                            <textarea
                                                onChange={(e) => handleNoteChange(e.target.value)}
                                                value={editMode?.note}
                                                className="form-control d-block"
                                            ></textarea>
                                        ) : (
                                            com?.note
                                        )}
                                    </small>
                                </div>
                                <button
                                    onClick={() =>
                                        // setEditMode(com)
                                        setDeleteModal({
                                            show: true,
                                            func: () => {
                                                deleteNote({
                                                    interview_id: comments.id,
                                                    note_id: com.id,
                                                })
                                                setDeleteModal(undefined)
                                            },
                                        })
                                    }
                                    className="btn btn-link text-danger"
                                >
                                    Delete
                                </button>
                            </div>

                        </li>
                    </>
                )
            })
        }
    }

    return (
        <>
            <div className="widget-media">
                <div className="card">
                    <div className="card-header d-block p-3">
                        <h5 className="card-title d-block mb-2">
                            <i className="bx bx-comment-dots align-bottom mr-1"></i>
                            {evaluation?.Note?.length || 0} Notes
                        </h5>
                        <div className="row">
                            <div className="col-md-10">
                                <input
                                    className="form-control mr-2"
                                    placeholder="Write a note here..."
                                    onChange={(e) => handleNoteChange(e.target.value)}
                                    value={note}
                                    required
                                />
                            </div>
                            <div className="col-md-2">
                                <a className="btn btn-outline-primary btn-block" onClick={() => handleCommentSubmit()}>
                                    {loader ? (
                                        <div className="text-center">
                                            <Spinner size="sm" className="m-auto text-center" color="success"/>
                                        </div>
                                    ) : (
                                        "Add Note"
                                    )}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <ul className="timeline">
                            <div className="custom-scroll" style={{maxHeight: "400px"}}>
                                {loading ? (
                                    <div className="text-center">
                                        <Spinner size="sm" className="m-auto text-center" color="success"/>
                                    </div>
                                ) : (
                                    // renderTodoList(evaluation?.todo)
                                    renderComments(evaluation)
                                )}
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
            <>
                <Row className="justify-content-end mb-2 d-none">
                    <Col md={6} className="text-right">
            <span>
              <i className="bx bx-comment-dots bx-xs mr-1"></i>
                {evaluation?.Note?.length || 0} Comments
            </span>
                    </Col>
                </Row>
                <div>
                    <ul className="list-unstyled commentsList">
                        <div
                            style={{
                                maxHeight: "300px",
                                overflowY: "scroll",
                                overflowX: "hidden",
                            }}
                            className="custom-scroll"
                        >
                            {/* {renderComments(evaluation)} */}
                        </div>
                    </ul>
                </div>
                <Modal
                    centered={true}
                    show={deleteModal?.show}
                    onHide={() => {
                        setDeleteModal(undefined)
                    }}
                >
                    <DeletePrompt
                        title={"Are you sure ?"}
                        func={() => deleteModal?.func()}
                        toggle={() => {
                            setDeleteModal(undefined)
                        }}
                    />
                </Modal>
            </>
        </>
    )
}

export default EvaluationComments
