import React, { useEffect, useRef, useState } from "react"
import { Row, Col, Card, CardBody, Spinner } from "reactstrap"
import { Link } from "react-router-dom"
import moment from 'moment'
import { useDispatch } from "react-redux"
import { Player, ControlBar } from 'video-react';
import "video-react/dist/video-react.css";
import Logo from "./../../../images/logo-light.svg"
import './style.css'
import { AspectRatio } from 'react-aspect-ratio';

const Questions = ({ evaluation, loading, setProfileModal }) => {
  const [question, setQuestion] = useState(undefined)
  const [answer, setAnswer] = useState(undefined)
  const dispatch = useDispatch()
  const playerRef = useRef()
  const [playerState, setPlayerState] = useState({
    playbackRate: 1
  })
// console.log(evaluation);
  useEffect(() => {
    if (!evaluation) {
      setQuestion(undefined)
      setAnswer(undefined)
    } else {
      if (!question) {
        VideoResume()
        // setQuestionAndAnswer(evaluation?.Position?.Question[0], evaluation?.Position?.Question[0]?.Answer[0])
      }
    }
  }, [evaluation])

  const setQuestionAndAnswer = (ques, answer) => {
    if (playerRef && playerRef.current && ques.type === "video") {
      playerRef.current.load()
      playerRef.current.playbackRate = playerState.playbackRate
    }
    setAnswer(answer)
    setQuestion(ques)
  }

  const changePlaybackRateRate = (speed) => {
    setPlayerState({
      ...playerState,
      playbackRate: speed
    })
    playerRef.current.playbackRate = speed;
  }

  useEffect(() => {
    if (question?.type === 'video') {
      const key = answer?.content?.split('digitalhire-development.s3.amazonaws.com/')[1]
      if (key) {
        // dispatch(getAnswerVideo(question?._id, key))
      }
    }
  }, [question, answer])

  const renderPagination = (questions) => {
    if (questions && questions.length > 0) {
      // console.log(questions);
      return questions?.map((ques, key) => {
        return (
          <button onClick={() => setQuestionAndAnswer(ques, ques?.Answer[0])} key={`Ques-tab-${key}`} className={`custom-btns pagination-number ${question?.seq === ques?.seq ? 'bg-primary text-light' : ""}`}>{key+1}</button>
        )
      })
    }
  }

  const getQuestionsTime = (questions) => {
    let time = 0
    questions.forEach(ques => {
      time += ques?.questionObj?.timeAllowed
    })
    return time
  }

  const nextQuestion = () => {
    const next = evaluation?.Position?.Question?.find(ques => ques.seq === question?.seq + 1 )
    if (next) setQuestionAndAnswer(next, next?.Answer[0])
  }

  const prevQuestion = () => {
    if ((question?.seq - 1) === 0){
      VideoResume();
      return
    }
    const prev = evaluation?.Position?.Question?.find(ques => ques.seq === question?.seq - 1)
    if (prev) setQuestionAndAnswer(prev, prev?.Answer[0])
  }
  const VideoResume = () => {
    const vrVideo =evaluation?.video_resume;
    setQuestionAndAnswer({type: vrVideo ? 'video' : "text", seq:0}, {content: vrVideo ? vrVideo : "Video Resume does not exist"})
  }

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <CardBody className="pt-0 text-center">
          <div className="header-pagination mt-4">
            <button onClick={() => prevQuestion()} disabled={question?.seq === 0} className="btn btn-link green mr-4">Previous</button>
            <button type="button" onClick={() => VideoResume()} className={`custom-btns pagination-number ${question?.seq === 0 ? 'bg-primary ' : ''}`}>VR</button>
            {renderPagination(evaluation?.Position?.Question)}
            <button onClick={() => nextQuestion()} disabled={evaluation?.Position?.Question.length === question?.seq} className="btn btn-link green ml-4">Next</button>
          </div>
          <Row className="justify-content-center mt-4">
            <Col className="text-center">
              { question?.seq === 0 ?
                <span className="d-block text-muted">Video Resume </span>
                :
                <span className="d-block text-muted">{question?.seq} of {evaluation?.Position?.Question.length}</span>
              }
              <span className="text-subtitle">{question?.title}</span>
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            {
              question?.type === 'video' ?
                loading ? <Col><Spinner size="sm" className="m-auto text-center" color="success" /></Col> : answer?.content ?
                  <Col className="text-center">
                    <div style={{ textAlign: "center", border: '1px solid #000' }}>
                      <AspectRatio ratio={0.75}
                        style={{
                          margin: "auto",
                          maxHeight: "350px",
                          backgroundSize: 'cover',
                        }}>
                        <Player
                          ref={playerRef}
                          fluid={false} width={'auto'} height={350}
                          onEnded={()=> nextQuestion() }
                          autoPlay
                        >
                          <source src={answer?.content} />
                          <div
                            className="vid-overlay"
                          >
                            <img
                              src={Logo}
                              width="80"
                            />
                          </div>
                          <ControlBar autoHide={false} />
                        </Player>
                      </AspectRatio>
                    </div>
                  </Col> : "" : <Col className="text-center">
                  <h6>{answer?.content}</h6>
                </Col>
            }
          </Row>
          <Row className="mt-4">
            <Col lg={5} className="text-left">
              {/*<i className="mdi mdi-calendar-blank green mr-2"></i>*/}
              {/*<span className="text-subtitle mr-2">Date & Time :</span>*/}
              {/*<span className="text-subtitle">{moment().format('MMM DD, YYYY hh:mm')}</span>*/}
            </Col>
            {
              question?.type === 'video' ? <Col lg={7} className="text-right">
                <span className="text-subtitle mr-2">Playback Speed</span>
                <button className={`custom-btns speed-btn ${playerState?.playbackRate === 1 ? 'bg-primary text-white' : ''}`} onClick={() => changePlaybackRateRate(1)}>1x</button>
                <button className={`custom-btns speed-btn ${playerState?.playbackRate === 1.25 ? 'bg-primary text-white' : ''}`} onClick={() => changePlaybackRateRate(1.25)}>1.25x</button>
                <button className={`custom-btns speed-btn ${playerState?.playbackRate === 1.5 ? 'bg-primary text-white' : ''}`} onClick={() => changePlaybackRateRate(1.5)}>1.5x</button>
                <button className={`custom-btns speed-btn ${playerState?.playbackRate === 2 ? 'bg-primary text-white' : ''}`} onClick={() => changePlaybackRateRate(2)}>2x</button>
              </Col> : ""
            }
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default Questions